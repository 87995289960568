import React, { Fragment, useEffect } from 'react';
import { FocusEvent, useRef, useState } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { LocationChangeType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import {
    CitiesType,
    LocationOptionsType,
    SavedLocationsType,
    SearchLocationResultType
} from 'utils/types/Locations';

import CheckboxTextInput from './CheckboxTextInput';
import TextInput from './TextInput';

const Container = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
`;

const AutoCompleteSuggestions = styled.div`
    position: absolute;
    z-index: 1;
    top: 95px;
    display: flex;
    flex-direction: column;
    background: var(--color-white);
    left: 0;
    width: 100%;
    min-width: fit-content;
    max-height: 330px;
    overflow-y: auto;
    padding: 10px;
    gap: 6px;
    border-radius: 10px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid var(--color-primary);

    &.hidden {
        display: none;
    }
`;

const Suggestion = styled.div`
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 36px;
    color: var(--primary-color);
    background: var(--color-alt-second);
    border-radius: 10px;
    text-align: center;
    border: 1px solid transparent;
    transition: border 0.1s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    &.subSuggestion {
        margin-left: 20px;
        width: initial;
    }

    &.subSubSuggestion {
        margin-left: 40px;
        width: initial;
    }

    input {
        --checkbox-background-color: transparent;

        cursor: pointer;
    }

    input[type="checkbox"]:checked {
        --checkbox-color: var(--color-white);
    }

    &.active {
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        color: var(--color-alt-second);

        div {
            color: var(--color-white);
        }

        img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
`;

const SelectAllDivider = styled.div`
    padding-bottom: 10px;
    padding-top: 10px;
    
    &::before {
        content: '';
        display: block;
        width: 100%;
        border-bottom: 1px solid rgba(3, 13, 69, 0.1);
    }
`;

const ErrorWrapper = styled.p`
    --font-size-body: var(--font-size-body-4);
    
    color: var(--color-error);
    padding: 0 10px;
    grid-column: 1 / -1;
`;

interface LocalizationAutocompleteInputProps {
    placeholder: string,
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLDivElement>) => void,
    options: LocationOptionsType[],
    searchResultsOptions: SearchLocationResultType[],
    onAction?: (voivodeship: string) => void,
    singleValue?: boolean,
    errorMessage?: string,
    inputValidation?: (value: string) => boolean,
    triggerValidation?: boolean,
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void,
    savedLocations: SavedLocationsType,
    handleChangeSingleValueField?: (name: string, value: string) => void,
    cityName?: string,
    selectAllCheckbox?: boolean,
    savedLocationsFieldName: string;
    cityFieldName: string;
}

const LocalizationAutocompleteInput = ({
    placeholder,
    onBlur,
    options,
    searchResultsOptions,
    onAction,
    singleValue,
    errorMessage,
    inputValidation,
    triggerValidation,
    handleChange,
    savedLocations,
    handleChangeSingleValueField,
    cityName,
    selectAllCheckbox,
    savedLocationsFieldName,
    cityFieldName
}: LocalizationAutocompleteInputProps) => {
    const [isSelectAll, setIsSelectAll] = useState(false);
    const minInputLength = 3;
    const [activeSuggestions, setActiveSuggestions] = useState<number[]>([]);
    const [activeCities, setActiveCities] = useState<string[]>([]);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const suggestionsRef = useRef<HTMLDivElement>(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [inputValue, setInputValue] = useState<string>('');
    const { user } = useUser();
    // const { handleChange, handleChangeNewAdvertField, userFiltersState, newAdvertFields } = useContext(UserFiltersContext);
    const [isErrorInput, setIsErrorInput] = useState(false);

    const handleClick = () => {
        if (showSuggestions) return;

        setShowSuggestions(true);
    };

    const handleSelectSuggestion = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, item: LocationOptionsType) => {
        e.preventDefault();
        e.stopPropagation();

        handleChangeLocation(item);

        if (singleValue) {
            setShowSuggestions(false);
        }
    };

    const handleChangeLocation = (item: LocationOptionsType) => {
        const savedLocationsValue = singleValue ? [] as unknown as SavedLocationsType : savedLocations;
        setInputValue('');

        if (savedLocationsValue[item.fullName]) {
            delete savedLocationsValue[item.fullName];
        } else {
            savedLocationsValue[item.fullName] = item;
            setShowSuggestions(false);
        }

        handleChange(savedLocationsFieldName, savedLocationsValue);
        handleChange(cityFieldName, Object.values(savedLocationsValue as LocationChangeType));

        if (singleValue && handleChangeSingleValueField) {
            handleChangeSingleValueField('cityName', Object.keys(savedLocationsValue)[0]);
        }

        if (inputValidation) {
            setIsErrorInput(inputValidation(Object.keys(savedLocationsValue)[0]));
        } else {
            setIsErrorInput(false);
        }
    };

    const handleOnAction = (voivodeship: string) => {
        const id = options.find((x: LocationOptionsType) => x.label === voivodeship)!.voivodeshipNumber;

        if (activeSuggestions.includes(id)) {
            setActiveSuggestions(activeSuggestions.filter((x) => x !== id));
        } else {
            setActiveSuggestions([...activeSuggestions, id]);
        }

        onAction?.(voivodeship);
    };

    const handleClickCity = (city: string) => {
        if (activeCities.includes(city)) {
            setActiveCities(activeCities.filter((x) => x !== city));
        } else {
            setActiveCities([...activeCities, city]);
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLDivElement>) => {
        if (onBlur) onBlur(e);

        if (inputValidation && !showSuggestions) {
            setIsErrorInput(inputValidation(cityName || ''));
        } else {
            setIsErrorInput(false);
        }
    };

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (inputValue.length >= minInputLength) {
            timer = setTimeout(async () => {
                const searchResults = await filtersApiRequests.getLocationsFromSearch(inputValue);

                handleChange('searchLocationResult', searchResults);
            }, 1000);
        }

        if (inputValue.length === 0) {
            handleChange('searchLocationResult', []);
        }

        return () => clearTimeout(timer);
    }, [inputValue]);

    useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        if (inputValue.length >= minInputLength) {
            timer = setTimeout(async () => {
                const searchResults = await filtersApiRequests.getLocationsFromSearch(inputValue);

                handleChange('searchOfficeLocationResult', searchResults);
            }, 1000);
        }

        if (inputValue.length === 0) {
            handleChange('searchOfficeLocationResult', []);
        }

        return () => clearTimeout(timer);
    }, [inputValue]);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (suggestionsRef.current && !suggestionsRef.current.contains(e.target as Node) && inputRef.current && !inputRef.current.contains(e.target as Node)) {
                setShowSuggestions(false);
                setActiveSuggestions([]);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [suggestionsRef, inputRef]);

    useEffect(() => {
        if (triggerValidation && inputValidation) {
            setIsErrorInput(inputValidation(cityName || ''));
        }
    }, [triggerValidation]);

    useEffect(() => {
        if (isSelectAll) {
            options.forEach((x) => {
                if (!savedLocations[x.fullName]) {
                    handleChangeLocation(x);
                }
            });
        } else {
            handleChange('savedLocations', {});
            handleChange('city', []);
        }
    }, [isSelectAll]);

    return (
        <>
            <Container>
                <TextInput
                    ref={inputRef}
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                    }}
                    onClick={handleClick}
                    onBlur={handleBlur}
                    isError={isErrorInput}
                />
                <AutoCompleteSuggestions className={clsx({
                    hidden: !(showSuggestions && options.length > 0)
                })} ref={suggestionsRef}>
                    {selectAllCheckbox
                        ? <>
                            <Suggestion
                                darkMode={user.isDarkMode}
                                onClick={() => setIsSelectAll(!isSelectAll)}
                                className={clsx({ active: isSelectAll })}
                            >
                                <CheckboxTextInput label={'Wszystkie województwa'} value={isSelectAll} />
                            </Suggestion>
                            <SelectAllDivider />
                        </>
                        : null}
                    {/* @ts-expect-error TODO: INVEST-254 fix in next phase*/}
                    {Object.values(savedLocations).map((x: LocationOptionsType) => {
                        return (
                            <Suggestion
                                darkMode={user.isDarkMode}
                                key={`${x.label} - ${x.regionNumber}`}
                                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectSuggestion(e, x)}
                            >
                                <CheckboxTextInput label={x.label} value={!!savedLocations[x.fullName]}/>
                            </Suggestion>
                        );
                    })}
                    {searchResultsOptions?.length > 0
                        ? searchResultsOptions.map((x) => <Suggestion
                            darkMode={user.isDarkMode}
                            key={`${x.label} - ${x.regionNumber}`}
                            // @ts-expect-error TODO: INVEST-254 fix in next phase
                            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectSuggestion(e, x)}
                            className={clsx({ active: savedLocations[x.fullName] })}
                        >
                            <CheckboxTextInput label={x.label} value={!!savedLocations[x.fullName]}/>
                        </Suggestion>)
                        : options.map((x) => <Fragment key={x.voivodeshipNumber}>
                            <Suggestion
                                darkMode={user.isDarkMode}
                                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectSuggestion(e, x)}
                                className={clsx({ active: savedLocations[x.fullName] })}
                            >
                                <CheckboxTextInput label={x.label} value={!!savedLocations[x.fullName]}
                                    onAction={handleOnAction}/>
                            </Suggestion>
                            {x.cities && x.cities.length > 0 && activeSuggestions.includes(x.voivodeshipNumber)
                                ? x.cities.map((city: CitiesType, index) => <Fragment key={`${x.label} - ${city.label} - ${index}`}>
                                    <Suggestion
                                        darkMode={user.isDarkMode}
                                        // @ts-expect-error TODO: INVEST-254 fix in next phase
                                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectSuggestion(e, city)}
                                        className={clsx('subSuggestion', { active: savedLocations[city.fullName] })}
                                    >
                                        <CheckboxTextInput label={city.label}
                                            value={!!savedLocations[city.fullName]}
                                            onAction={city.districts.length > 0 ? handleClickCity : undefined}/>
                                    </Suggestion>
                                    {city.districts && city.districts.length > 0 && activeCities.includes(city.label)
                                        ? city.districts.map((district, index) => <Suggestion
                                            darkMode={user.isDarkMode}
                                            key={`${city.label} - ${district.label} - ${index}`}
                                            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectSuggestion(e, district)}
                                            className={clsx('subSubSuggestion', { active: savedLocations[district.fullName] })}
                                        >
                                            <CheckboxTextInput label={district.label}
                                                value={!!savedLocations[district.fullName]}/>
                                        </Suggestion>)
                                        : null}
                                </Fragment>)
                                : null}
                        </Fragment>)}
                </AutoCompleteSuggestions>
            </Container>
            {isErrorInput ? <ErrorWrapper>{errorMessage}</ErrorWrapper> : null}
        </>
    );
};

export default LocalizationAutocompleteInput;
