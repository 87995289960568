import { PropertyType } from './enums/propertyType';
import { SaleModule } from './types/AnalysisModels';
import { AuctionSearchingRequestSortTypeEnum } from './types/AuctionModels';
import { BailiffNoticeSearchingRequestSortTypeEnum } from './types/BailiffModels';
import { RentModuleType } from './types/Rent';
import { SaleModuleType } from './types/Sale';
import { SortType as SaleSortType } from './types/SaleModels';
import { SortType } from './types/SortType';
import { TenderSearchingRequestSortTypeEnum } from './types/TenderModels';
import {
    BooleanEnum,
    CapitalBaseDtoFormOfInvestmentEnum,
    CapitalBaseDtoFormOfProfitEnum,
    CapitalBaseDtoInvestmentSecurityEnum,
    DealBaseDtoLegalStatusEnum, FilterDealDtoFeaturesEnum,
    InvestorBaseDtoInvestmentSecurityEnum,
    InvestorBaseDtoLegalStatusEnum,
    InvestorBoxDtoBuildingTypeEnum, InvestorBoxDtoPropertyConditionEnum
} from './types/UserModels';
import { V2OfferType } from './types/UsersAdvert';

const toSameValueOptions = (values: string[]): { value: string, label: string }[] => {
    return values.map((x) => ({
        value: x,
        label: x
    }));
};

export const scheduleOptions = [
    { label: 'Natychmiast', value: '0' },
    { label: 'co 5 minut', value: '5' },
    { label: 'co 10 minut', value: '10' },
    { label: 'co 15 minut', value: '15' },
    { label: 'co 30 minut', value: '30' },
    { label: 'co 1 godzinę', value: '60' },
    { label: 'co 2 godziny', value: '120' },
    { label: 'co 3 godziny', value: '180' },
    { label: 'co 5 godzin', value: '300' },
    { label: 'co 12 godzin', value: '720' },
    { label: 'co 24 godziny', value: '1440' }
];

export const typeOfMarketOptions = [
    { label: 'Pierwotny', value: 'Pierwotny' },
    { label: 'Wtórny', value: 'Wtórny' }
];

export const offerFromOptions = [
    { label: 'Agencja', value: 'Agencja' },
    { label: 'Osoba prywatna', value: 'Osoba prywatna' }
];

export const roomOptions = [
    { label: '1 pokój', value: '1' },
    { label: '2 pokoje', value: '2' },
    { label: '3 pokoje', value: '3' },
    { label: '4 pokoje', value: '4' },
    { label: '5 pokoi', value: '5' },
    { label: '6 pokoi', value: '6' },
    { label: '7 pokoi', value: '7' },
    { label: '8 pokoi', value: '8' },
    { label: '9 pokoi', value: '9' },
    { label: '10 pokoi', value: '10' }
];

export const investmentTimeOptions = [
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' }
];

export const floorOptions = [
    { label: 'parter', value: '0' },
    { label: '1 piętro', value: '1' },
    { label: '2 piętro', value: '2' },
    { label: '3 piętro', value: '3' },
    { label: '4 piętro', value: '4' },
    { label: '5 piętro', value: '5' },
    { label: '6 piętro', value: '6' },
    { label: '7 piętro', value: '7' },
    { label: '8 piętro', value: '8' },
    { label: '9 piętro', value: '9' },
    { label: '10 piętro', value: '10' }
];

export const userFloorOptions = [
    { label: 'Parter', value: '0' },
    { label: '1 piętro', value: '1' },
    { label: '2 piętro', value: '2' },
    { label: '3 piętro', value: '3' },
    { label: '4 piętro', value: '4' },
    { label: '5 piętro', value: '5' },
    { label: '6 piętro', value: '6' },
    { label: '7 piętro', value: '7' },
    { label: '8 piętro', value: '8' },
    { label: '9 piętro', value: '9' },
    { label: '10 piętro', value: '10' }
];

export const userFloorsOptions = [
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
    { label: '6', value: '6' },
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' }
];

export const areaOptions = [
    { label: '25 m²', value: '25' },
    { label: '35 m²', value: '35' },
    { label: '40 m²', value: '40' },
    { label: '50 m²', value: '50' },
    { label: '60 m²', value: '60' },
    { label: '70 m²', value: '70' },
    { label: '80 m²', value: '80' }
];

export const plotAreaOptions = [
    { label: '100 m2', value: '100' },
    { label: '200 m2', value: '200' },
    { label: '300 m2', value: '300' },
    { label: '400 m2', value: '400' },
    { label: '500 m2', value: '500' },
    { label: '600 m2', value: '600' },
    { label: '800 m2', value: '800' },
    { label: '1000 m2', value: '1000' },
    { label: '1500 m2', value: '1500' },
    { label: '2000 m2', value: '2000' },
    { label: '3000 m2', value: '3000' },
    { label: '4000 m2', value: '4000' },
    { label: '5000 m2', value: '5000' }
];

export const pricePerSquareMeterOptions = [
    { label: '10 zł', value: '10' },
    { label: '20 zł', value: '20' },
    { label: '30 zł', value: '30' },
    { label: '40 zł', value: '40' },
    { label: '50 zł', value: '50' },
    { label: '70 zł', value: '70' },
    { label: '100 zł', value: '100' },
    { label: '130 zł', value: '130' },
    { label: '150 zł', value: '150' }
];

export const pricePerSquareMeterSaleOptions = [
    { label: '3 000 zł', value: '3000' },
    { label: '4 000 zł', value: '4000' },
    { label: '5 000 zł', value: '5000' },
    { label: '6 000 zł', value: '6000' },
    { label: '7 000 zł', value: '7000' },
    { label: '8 000 zł', value: '8000' },
    { label: '9 000 zł', value: '9000' },
    { label: '10 000 zł', value: '10000' },
    { label: '11 000 zł', value: '11000' },
    { label: '12 000 zł', value: '12000' },
    { label: '13 000 zł', value: '13000' },
    { label: '14 000 zł', value: '14000' },
    { label: '15 000 zł', value: '15000' },
    { label: '18 000 zł', value: '18000' },
    { label: '20 000 zł', value: '20000' }
];

export const propertyTypeOptions = toSameValueOptions([
    'Mieszkania',
    'Domy',
    'Działki'
]);

export const priceOptions = [
    { label: '50 000 zł', value: '50 000' },
    { label: '100 000 zł', value: '100 000' },
    { label: '150 000 zł', value: '150 000' },
    { label: '200 000 zł', value: '200 000' },
    { label: '250 000 zł', value: '250 000' },
    { label: '300 000 zł', value: '300 000' },
    { label: '350 000 zł', value: '350 000' },
    { label: '400 000 zł', value: '400 000' },
    { label: '500 000 zł', value: '500 000' },
    { label: '600 000 zł', value: '600 000' },
    { label: '700 000 zł', value: '700 000' },
    { label: '800 000 zł', value: '800 000' }
];

export const rentPriceOptions = [
    { label: '500 zł', value: '500' },
    { label: '1 000 zł', value: '1 000' },
    { label: '2 000 zł', value: '2 000' },
    { label: '3 000 zł', value: '3 000' },
    { label: '5 000 zł', value: '5 000' },
    { label: '7 000 zł', value: '7 000' },
    { label: '10 000 zł', value: '10 000' },
    { label: '15 000 zł', value: '15 000' },
    { label: '20 000 zł', value: '20 000' },
    { label: '25 000 zł', value: '25 000' },
    { label: '30 000 zł', value: '30 000' }
];

export const investPriceOptions = [
    { label: '30 000 zł', value: '30 000' },
    { label: '50 000 zł', value: '50 000' },
    { label: '75 000 zł', value: '75 000' },
    { label: '100 000 zł', value: '100 000' },
    { label: '125 000 zł', value: '125 000' },
    { label: '150 000 zł', value: '150 000' },
    { label: '200 000 zł', value: '200 000' },
    { label: '300 000 zł', value: '300 000' },
    { label: '500 000 zł', value: '500 000' },
    { label: '750 000 zł', value: '750 000' },
    { label: '1 000 000 zł', value: '1 000 000' },
    { label: '1 500 000 zł', value: '1 500 000' },
    { label: '2 000 000 zł', value: '2 000 000' }
];

export const percentageOptions = [
    { label: '5%', value: '5' },
    { label: '10%', value: '10' },
    { label: '15%', value: '15' },
    { label: '20%', value: '20' },
    { label: '30%', value: '30' },
    { label: '40%', value: '40' },
    { label: '50%', value: '50' },
    { label: '60%', value: '60' },
    { label: '70%', value: '70' },
    { label: '80%', value: '80' },
    { label: '90%', value: '90' },
    { label: '100%', value: '100' }
];

export const buildYearOptions = toSameValueOptions([
    '1920',
    '1950',
    '1980',
    '1990',
    '2000',
    '2004',
    '2006',
    '2008',
    '2009',
    '2010',
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025'
]);

export const typeOptions = toSameValueOptions([
    'Blok',
    'Kamienica',
    'Apartamentowiec',
    'Pozostałe'
]);

export const housesBuildingTypeOptions = toSameValueOptions([
    'Wolnostojący',
    'Bliźniak',
    'Szeregowiec',
    'Pozostałe'
]);

export const flatsBuildingTypeOptions = toSameValueOptions([
    'Blok',
    'Kamienica',
    'Apartamentowiec',
    'Pozostałe'
]);

export const plotsBuildingTypeOptions = toSameValueOptions([
    'Budowlana',
    'Rolna',
    'Leśna',
    'Pozostałe'
]);

export const otherBuildingTypeOptions = toSameValueOptions([
    'Garaże',
    'Hale',
    'Pozostałe'
]);

export const raportOptions = toSameValueOptions(['wg przedziałów powierzchni', 'wg czasu']);

export const dateOptionsLabels = [
    { value: 1, label: 'ostatni dzień' },
    { value: 2, label: 'ostatnie 2 dni' },
    { value: 3, label: 'ostatnie 3 dni' },
    { value: 4, label: 'ostatnie 4 dni' },
    { value: 5, label: 'ostatnie 5 dni' },
    { value: 6, label: 'ostatnie 6 dni' },
    { value: 7, label: 'ostatnie 7 dni' },
    { value: 14, label: 'ostatnie 14 dni' },
    { value: 30, label: 'ostatnie 30 dni' },
    { value: 60, label: 'ostatnie 60 dni' },
    { value: 180, label: 'ostatnie 180 dni' },
    { value: 360, label: 'ostatnie 360 dni' },
    { value: -1, label: 'Wszystkie' }
];

const cityOptions = toSameValueOptions([
    'Aleksandrów Kujawski',
    'Aleksandrów Łódzki',
    'Alwernia',
    'Andrychów',
    'Annopol',
    'Augustów',
    'Babimost',
    'Baborów',
    'Baranów Sandomierski',
    'Barcin',
    'Barczewo',
    'Bardo',
    'Barlinek',
    'Bartoszyce',
    'Barwice',
    'Bełchatów',
    'Bełżyce',
    'Będzin',
    'Biała',
    'Biała Piska',
    'Biała Podlaska',
    'Biała Rawska',
    'Białobrzegi',
    'Białogard',
    'Biały Bór',
    'Białystok',
    'Biecz',
    'Bielawa',
    'Bielsk Podlaski',
    'Bielsko-Biała',
    'Bieruń',
    'Bierutów',
    'Bieżuń',
    'Biłgoraj',
    'Biskupiec',
    'Bisztynek',
    'Blachownia',
    'Błaszki',
    'Błażowa',
    'Błonie',
    'Bobolice',
    'Bobowa',
    'Bochnia',
    'Bodzentyn',
    'Bogatynia',
    'Boguchwała',
    'Boguszów-Gorce',
    'Bojanowo',
    'Bolesławiec',
    'Bolków',
    'Borek Wielkopolski',
    'Borne Sulinowo',
    'Braniewo',
    'Brańsk',
    'Brodnica',
    'Brok',
    'Brusy',
    'Brwinów',
    'Brzeg',
    'Brzeg Dolny',
    'Brzesko',
    'Brzeszcze',
    'Brześć Kujawski',
    'Brzeziny',
    'Brzostek',
    'Brzozów',
    'Buk',
    'Bukowno',
    'Busko-Zdrój',
    'Bychawa',
    'Byczyna',
    'Bydgoszcz',
    'Bystrzyca Kłodzka',
    'Bytom',
    'Bytom Odrzański',
    'Bytów',
    'Cedynia',
    'Chełm',
    'Chełmek',
    'Chełmno',
    'Chełmża',
    'Chęciny',
    'Chmielnik',
    'Chocianów',
    'Chociwel',
    'Chocz',
    'Chodecz',
    'Chodzież',
    'Chojna',
    'Chojnice',
    'Chojnów',
    'Choroszcz',
    'Chorzele',
    'Chorzów',
    'Choszczno',
    'Chrzanów',
    'Ciechanowiec',
    'Ciechanów',
    'Ciechocinek',
    'Cieszanów',
    'Cieszyn',
    'Ciężkowice',
    'Cybinka',
    'Czaplinek',
    'Czarna Białostocka',
    'Czarna Woda',
    'Czarne',
    'Czarnków',
    'Czchów',
    'Czechowice-Dziedzice',
    'Czeladź',
    'Czempiń',
    'Czerniejewo',
    'Czersk',
    'Czerwieńsk',
    'Czerwionka-Leszczyny',
    'Częstochowa',
    'Człopa',
    'Człuchów',
    'Czyżew',
    'Ćmielów',
    'Daleszyce',
    'Darłowo',
    'Dąbie',
    'Dąbrowa Białostocka',
    'Dąbrowa Górnicza',
    'Dąbrowa Tarnowska',
    'Debrzno',
    'Dębica',
    'Dęblin',
    'Dębno',
    'Dobczyce',
    'Dobiegniew',
    'Dobra',
    'Dobre Miasto',
    'Dobrodzień',
    'Dobrzany',
    'Dobrzyca',
    'Dobrzyń nad Wisłą',
    'Dolsk',
    'Drawno',
    'Drawsko Pomorskie',
    'Drezdenko',
    'Drobin',
    'Drohiczyn',
    'Drzewica',
    'Dukla',
    'Duszniki-Zdrój',
    'Dynów',
    'Działdowo',
    'Działoszyce',
    'Działoszyn',
    'Dzierzgoń',
    'Dzierżoniów',
    'Dziwnów',
    'Elbląg',
    'Ełk',
    'Frampol',
    'Frombork',
    'Garwolin',
    'Gąbin',
    'Gdańsk',
    'Gdynia',
    'Giżycko',
    'Glinojeck',
    'Gliwice',
    'Głogów',
    'Głogów Małopolski',
    'Głogówek',
    'Głowno',
    'Głubczyce',
    'Głuchołazy',
    'Głuszyca',
    'Gniew',
    'Gniewkowo',
    'Gniezno',
    'Gogolin',
    'Golczewo',
    'Goleniów',
    'Golina',
    'Golub-Dobrzyń',
    'Gołańcz',
    'Gołdap',
    'Goniądz',
    'Gorlice',
    'Gorzów Śląski',
    'Gorzów Wielkopolski',
    'Gostynin',
    'Gostyń',
    'Gościno',
    'Gozdnica',
    'Góra',
    'Góra Kalwaria',
    'Górowo Iławeckie',
    'Górzno',
    'Grabów nad Prosną',
    'Grajewo',
    'Grodków',
    'Grodzisk Mazowiecki',
    'Grodzisk Wielkopolski',
    'Grójec',
    'Grudziądz',
    'Grybów',
    'Gryfice',
    'Gryfino',
    'Gryfów Śląski',
    'Gubin',
    'Hajnówka',
    'Halinów',
    'Hel',
    'Hrubieszów',
    'Iława',
    'Iłowa',
    'Iłża',
    'Imielin',
    'Inowrocław',
    'Ińsko',
    'Iwonicz-Zdrój',
    'Izbica Kujawska',
    'Jabłonowo Pomorskie',
    'Janikowo',
    'Janowiec Wielkopolski',
    'Janów Lubelski',
    'Jaraczewo',
    'Jarocin',
    'Jarosław',
    'Jasień',
    'Jasło',
    'Jastarnia',
    'Jastrowie',
    'Jastrzębie-Zdrój',
    'Jawor',
    'Jaworzno',
    'Jaworzyna Śląska',
    'Jedlicze',
    'Jedlina-Zdrój',
    'Jedwabne',
    'Jelcz-Laskowice',
    'Jelenia Góra',
    'Jeziorany',
    'Jędrzejów',
    'Jordanów',
    'Józefów',
    'Józefów nad Wisłą',
    'Jutrosin',
    'Kalety',
    'Kalisz',
    'Kalisz Pomorski',
    'Kalwaria Zebrzydowska',
    'Kałuszyn',
    'Kamienna Góra',
    'Kamień Krajeński',
    'Kamień Pomorski',
    'Kamieńsk',
    'Kańczuga',
    'Karczew',
    'Kargowa',
    'Karlino',
    'Karpacz',
    'Kartuzy',
    'Katowice',
    'Kazimierz Dolny',
    'Kazimierza Wielka',
    'Kąty Wrocławskie',
    'Kcynia',
    'Kędzierzyn-Koźle',
    'Kępice',
    'Kępno',
    'Kętrzyn',
    'Kęty',
    'Kielce',
    'Kietrz',
    'Kisielice',
    'Kleczew',
    'Kleszczele',
    'Kluczbork',
    'Kłecko',
    'Kłobuck',
    'Kłodawa',
    'Kłodzko',
    'Knurów',
    'Knyszyn',
    'Kobylin',
    'Kobyłka',
    'Kock',
    'Kolbuszowa',
    'Kolno',
    'Kolonowskie',
    'Koluszki',
    'Kołaczyce',
    'Koło',
    'Kołobrzeg',
    'Koniecpol',
    'Konin',
    'Konstancin-Jeziorna',
    'Konstantynów Łódzki',
    'Końskie',
    'Koprzywnica',
    'Korfantów',
    'Koronowo',
    'Korsze',
    'Kosów Lacki',
    'Kostrzyn',
    'Kostrzyn nad Odrą',
    'Koszalin',
    'Koszyce',
    'Kościan',
    'Kościerzyna',
    'Kowal',
    'Kowalewo Pomorskie',
    'Kowary',
    'Koziegłowy',
    'Kozienice',
    'Koźmin Wielkopolski',
    'Kożuchów',
    'Kórnik',
    'Krajenka',
    'Kraków',
    'Krapkowice',
    'Krasnobród',
    'Krasnystaw',
    'Kraśnik',
    'Krobia',
    'Krosno',
    'Krosno Odrzańskie',
    'Krośniewice',
    'Krotoszyn',
    'Kruszwica',
    'Krynica Morska',
    'Krynica-Zdrój',
    'Krynki',
    'Krzanowice',
    'Krzepice',
    'Krzeszowice',
    'Krzywiń',
    'Krzyż Wielkopolski',
    'Książ Wielkopolski',
    'Kudowa-Zdrój',
    'Kunów',
    'Kutno',
    'Kuźnia Raciborska',
    'Kwidzyn',
    'Lądek-Zdrój',
    'Legionowo',
    'Legnica',
    'Lesko',
    'Leszno',
    'Leśna',
    'Leśnica',
    'Lewin Brzeski',
    'Leżajsk',
    'Lębork',
    'Lędziny',
    'Libiąż',
    'Lidzbark',
    'Lidzbark Warmiński',
    'Limanowa',
    'Lipiany',
    'Lipno',
    'Lipsk',
    'Lipsko',
    'Lubaczów',
    'Lubań',
    'Lubartów',
    'Lubawa',
    'Lubawka',
    'Lubień Kujawski',
    'Lubin',
    'Lublin',
    'Lubliniec',
    'Lubniewice',
    'Lubomierz',
    'Luboń',
    'Lubowidz',
    'Lubraniec',
    'Lubsko',
    'Lubycza Królewska',
    'Lwówek',
    'Lwówek Śląski',
    'Łabiszyn',
    'Łagów',
    'Łańcut',
    'Łapy',
    'Łasin',
    'Łask',
    'Łaskarzew',
    'Łaszczów',
    'Łaziska Górne',
    'Łazy',
    'Łeba',
    'Łęczna',
    'Łęczyca',
    'Łęknica',
    'Łobez',
    'Łobżenica',
    'Łochów',
    'Łomianki',
    'Łomża',
    'Łosice',
    'Łowicz',
    'Łódź',
    'Łuków',
    'Maków Mazowiecki',
    'Maków Podhalański',
    'Malbork',
    'Małogoszcz',
    'Małomice',
    'Margonin',
    'Marki',
    'Maszewo',
    'Miasteczko Śląskie',
    'Miastko',
    'Michałowo',
    'Miechów',
    'Miejska Górka',
    'Mielec',
    'Mielno',
    'Mieroszów',
    'Mieszkowice',
    'Międzybórz',
    'Międzychód',
    'Międzylesie',
    'Międzyrzec Podlaski',
    'Międzyrzecz',
    'Międzyzdroje',
    'Mikołajki',
    'Mikołów',
    'Mikstat',
    'Milanówek',
    'Milicz',
    'Miłakowo',
    'Miłomłyn',
    'Miłosław',
    'Mińsk Mazowiecki',
    'Mirosławiec',
    'Mirsk',
    'Mława',
    'Młynary',
    'Modliborzyce',
    'Mogielnica',
    'Mogilno',
    'Mońki',
    'Morawica',
    'Morąg',
    'Mordy',
    'Moryń',
    'Mosina',
    'Mrągowo',
    'Mrocza',
    'Mrozy',
    'Mszana Dolna',
    'Mszczonów',
    'Murowana Goślina',
    'Muszyna',
    'Mysłowice',
    'Myszków',
    'Myszyniec',
    'Myślenice',
    'Myślibórz',
    'Nakło nad Notecią',
    'Nałęczów',
    'Namysłów',
    'Narol',
    'Nasielsk',
    'Nekla',
    'Nidzica',
    'Niemcza',
    'Niemodlin',
    'Niepołomice',
    'Nieszawa',
    'Nisko',
    'Nowa Dęba',
    'Nowa Ruda',
    'Nowa Sarzyna',
    'Nowa Słupia',
    'Nowa Sól',
    'Nowe',
    'Nowe Brzesko',
    'Nowe Miasteczko',
    'Nowe Miasto Lubawskie',
    'Nowe Miasto nad Pilicą',
    'Nowe Skalmierzyce',
    'Nowe Warpno',
    'Nowogard',
    'Nowogrodziec',
    'Nowogród',
    'Nowogród Bobrzański',
    'Nowy Dwór Gdański',
    'Nowy Dwór Mazowiecki',
    'Nowy Korczyn',
    'Nowy Sącz',
    'Nowy Staw',
    'Nowy Targ',
    'Nowy Tomyśl',
    'Nowy Wiśnicz',
    'Nysa',
    'Oborniki',
    'Oborniki Śląskie',
    'Obrzycko',
    'Odolanów',
    'Ogrodzieniec',
    'Okonek',
    'Olecko',
    'Olesno',
    'Oleszyce',
    'Oleśnica',
    'Olkusz',
    'Olsztyn',
    'Olsztynek',
    'Olszyna',
    'Oława',
    'Opalenica',
    'Opatowiec',
    'Opatów',
    'Opatówek',
    'Opoczno',
    'Opole',
    'Opole Lubelskie',
    'Orneta',
    'Orzesze',
    'Orzysz',
    'Osieczna',
    'Osiek',
    'Ostrołęka',
    'Ostroróg',
    'Ostrowiec Świętokrzyski',
    'Ostróda',
    'Ostrów Lubelski',
    'Ostrów Mazowiecka',
    'Ostrów Wielkopolski',
    'Ostrzeszów',
    'Ośno Lubuskie',
    'Oświęcim',
    'Otmuchów',
    'Otwock',
    'Otyń',
    'Ozimek',
    'Ozorków',
    'Ożarów',
    'Ożarów Mazowiecki',
    'Pabianice',
    'Pacanów',
    'Paczków',
    'Pajęczno',
    'Pakość',
    'Parczew',
    'Pasłęk',
    'Pasym',
    'Pelplin',
    'Pełczyce',
    'Piaseczno',
    'Piaski',
    'Piastów',
    'Piechowice',
    'Piekary Śląskie',
    'Pieniężno',
    'Pieńsk',
    'Pierzchnica',
    'Pieszyce',
    'Pilawa',
    'Pilica',
    'Pilzno',
    'Piła',
    'Piława Górna',
    'Pińczów',
    'Pionki',
    'Piotrków Kujawski',
    'Piotrków Trybunalski',
    'Pisz',
    'Piwniczna-Zdrój',
    'Pleszew',
    'Płock',
    'Płońsk',
    'Płoty',
    'Pniewy',
    'Pobiedziska',
    'Poddębice',
    'Podkowa Leśna',
    'Pogorzela',
    'Polanica-Zdrój',
    'Polanów',
    'Police',
    'Polkowice',
    'Połaniec',
    'Połczyn-Zdrój',
    'Poniatowa',
    'Poniec',
    'Poręba',
    'Poznań',
    'Prabuty',
    'Praszka',
    'Prochowice',
    'Proszowice',
    'Prószków',
    'Pruchnik',
    'Prudnik',
    'Prusice',
    'Pruszcz Gdański',
    'Pruszków',
    'Przasnysz',
    'Przecław',
    'Przedbórz',
    'Przedecz',
    'Przemków',
    'Przemyśl',
    'Przeworsk',
    'Przysucha',
    'Pszczyna',
    'Pszów',
    'Puck',
    'Puławy',
    'Pułtusk',
    'Puszczykowo',
    'Pyrzyce',
    'Pyskowice',
    'Pyzdry',
    'Rabka-Zdrój',
    'Raciąż',
    'Racibórz',
    'Radków',
    'Radlin',
    'Radłów',
    'Radom',
    'Radomsko',
    'Radomyśl Wielki',
    'Radoszyce',
    'Radymno',
    'Radziejów',
    'Radzionków',
    'Radzymin',
    'Radzyń Chełmiński',
    'Radzyń Podlaski',
    'Rajgród',
    'Rakoniewice',
    'Raszków',
    'Rawa Mazowiecka',
    'Rawicz',
    'Recz',
    'Reda',
    'Rejowiec',
    'Rejowiec Fabryczny',
    'Resko',
    'Reszel',
    'Rogoźno',
    'Ropczyce',
    'Różan',
    'Ruciane-Nida',
    'Ruda Śląska',
    'Rudnik nad Sanem',
    'Rumia',
    'Rybnik',
    'Rychwał',
    'Rydułtowy',
    'Rydzyna',
    'Ryglice',
    'Ryki',
    'Rymanów',
    'Ryn',
    'Rypin',
    'Rzepin',
    'Rzeszów',
    'Rzgów',
    'Sandomierz',
    'Sanniki',
    'Sanok',
    'Sejny',
    'Serock',
    'Sędziszów',
    'Sędziszów Małopolski',
    'Sępopol',
    'Sępólno Krajeńskie',
    'Sianów',
    'Siechnice',
    'Siedlce',
    'Siedliszcze',
    'Siemianowice Śląskie',
    'Siemiatycze',
    'Sieniawa',
    'Sieradz',
    'Sieraków',
    'Sierpc',
    'Siewierz',
    'Skalbmierz',
    'Skała',
    'Skarszewy',
    'Skaryszew',
    'Skarżysko-Kamienna',
    'Skawina',
    'Skępe',
    'Skierniewice',
    'Skoczów',
    'Skoki',
    'Skórcz',
    'Skwierzyna',
    'Sława',
    'Sławków',
    'Sławno',
    'Słomniki',
    'Słubice',
    'Słupca',
    'Słupsk',
    'Sobótka',
    'Sochaczew',
    'Sokołów Małopolski',
    'Sokołów Podlaski',
    'Sokółka',
    'Solec Kujawski',
    'Sompolno',
    'Sopot',
    'Sosnowiec',
    'Sośnicowice',
    'Stalowa Wola',
    'Starachowice',
    'Stargard',
    'Starogard Gdański',
    'Stary Sącz',
    'Staszów',
    'Stawiski',
    'Stawiszyn',
    'Stąporków',
    'Stepnica',
    'Stęszew',
    'Stoczek Łukowski',
    'Stopnica',
    'Stronie Śląskie',
    'Strumień',
    'Stryków',
    'Strzegom',
    'Strzelce Krajeńskie',
    'Strzelce Opolskie',
    'Strzelin',
    'Strzelno',
    'Strzyżów',
    'Sucha Beskidzka',
    'Suchań',
    'Suchedniów',
    'Suchowola',
    'Sulechów',
    'Sulejów',
    'Sulejówek',
    'Sulęcin',
    'Sulmierzyce',
    'Sułkowice',
    'Supraśl',
    'Suraż',
    'Susz',
    'Suwałki',
    'Swarzędz',
    'Syców',
    'Szadek',
    'Szamocin',
    'Szamotuły',
    'Szczawnica',
    'Szczawno-Zdrój',
    'Szczebrzeszyn',
    'Szczecin',
    'Szczecinek',
    'Szczekociny',
    'Szczucin',
    'Szczuczyn',
    'Szczyrk',
    'Szczytna',
    'Szczytno',
    'Szepietowo',
    'Szklarska Poręba',
    'Szlichtyngowa',
    'Szprotawa',
    'Sztum',
    'Szubin',
    'Szydłowiec',
    'Szydłów',
    'Ścinawa',
    'Ślesin',
    'Śmigiel',
    'Śrem',
    'Środa Śląska',
    'Środa Wielkopolska',
    'Świątniki Górne',
    'Świdnica',
    'Świdnik',
    'Świdwin',
    'Świebodzice',
    'Świebodzin',
    'Świecie',
    'Świeradów-Zdrój',
    'Świerzawa',
    'Świętochłowice',
    'Świnoujście',
    'Tarczyn',
    'Tarnobrzeg',
    'Tarnogród',
    'Tarnowskie Góry',
    'Tarnów',
    'Tczew',
    'Terespol',
    'Tłuszcz',
    'Tolkmicko',
    'Tomaszów Lubelski',
    'Tomaszów Mazowiecki',
    'Toruń',
    'Torzym',
    'Toszek',
    'Trzcianka',
    'Trzciel',
    'Trzcińsko-Zdrój',
    'Trzebiatów',
    'Trzebinia',
    'Trzebnica',
    'Trzemeszno',
    'Tuchola',
    'Tuchów',
    'Tuczno',
    'Tuliszków',
    'Tułowice',
    'Turek',
    'Tuszyn',
    'Twardogóra',
    'Tychowo',
    'Tychy',
    'Tyczyn',
    'Tykocin',
    'Tyszowce',
    'Ujazd',
    'Ujście',
    'Ulanów',
    'Uniejów',
    'Urzędów',
    'Ustka',
    'Ustroń',
    'Ustrzyki Dolne',
    'Wadowice',
    'Wałbrzych',
    'Wałcz',
    'Warka',
    'Warszawa',
    'Warta',
    'Wasilków',
    'Wąbrzeźno',
    'Wąchock',
    'Wągrowiec',
    'Wąsosz',
    'Wejherowo',
    'Węgliniec',
    'Węgorzewo',
    'Węgorzyno',
    'Węgrów',
    'Wiązów',
    'Wielbark',
    'Wieleń',
    'Wielichowo',
    'Wieliczka',
    'Wieluń',
    'Wieruszów',
    'Więcbork',
    'Wilamowice',
    'Wisła',
    'Wiślica',
    'Witkowo',
    'Witnica',
    'Wleń',
    'Władysławowo',
    'Włocławek',
    'Włodawa',
    'Włoszczowa',
    'Wodzisław Śląski',
    'Wojcieszów',
    'Wojkowice',
    'Wojnicz',
    'Wolbórz',
    'Wolbrom',
    'Wolin',
    'Wolsztyn',
    'Wołczyn',
    'Wołomin',
    'Wołów',
    'Woźniki',
    'Wrocław',
    'Wronki',
    'Września',
    'Wschowa',
    'Wyrzysk',
    'Wysoka',
    'Wysokie Mazowieckie',
    'Wyszków',
    'Wyszogród',
    'Wyśmierzyce',
    'Zabłudów',
    'Zabrze',
    'Zagórów',
    'Zagórz',
    'Zakliczyn',
    'Zaklików',
    'Zakopane',
    'Zakroczym',
    'Zalewo',
    'Zambrów',
    'Zamość',
    'Zator',
    'Zawadzkie',
    'Zawichost',
    'Zawidów',
    'Zawiercie',
    'Ząbki',
    'Ząbkowice Śląskie',
    'Zbąszynek',
    'Zbąszyń',
    'Zduny',
    'Zduńska Wola',
    'Zdzieszowice',
    'Zelów',
    'Zgierz',
    'Zgorzelec',
    'Zielona Góra',
    'Zielonka',
    'Ziębice',
    'Złocieniec',
    'Złoczew',
    'Złotoryja',
    'Złotów',
    'Złoty Stok',
    'Zwierzyniec',
    'Zwoleń',
    'Żabno',
    'Żagań',
    'Żarki',
    'Żarów',
    'Żary',
    'Żelechów',
    'Żerków',
    'Żmigród',
    'Żnin',
    'Żory',
    'Żukowo',
    'Żuromin',
    'Żychlin',
    'Żyrardów',
    'Żywiec',
    'Gromadka',
    'Osiecznica',
    'Warta Bolesławiecka',
    'Męcinka',
    'Mściwojów',
    'Paszowice',
    'Wądroże Wielkie',
    'Janowice Wielkie',
    'Jeżów Sudecki',
    'Mysłakowice',
    'Podgórzyn',
    'Stara Kamienica',
    'Marciszów',
    'Platerówka',
    'Siekierczyn',
    'Sulików',
    'Pielgrzymka',
    'Zagrodno',
    'Jerzmanowa',
    'Kotla',
    'Pęcław',
    'Żukowice',
    'Jemielno',
    'Niechlów',
    'Krotoszyce',
    'Kunice',
    'Legnickie Pole',
    'Miłkowice',
    'Ruja',
    'Rudna',
    'Gaworzyce',
    'Grębocice',
    'Radwanice',
    'Łagiewniki',
    'Duszniki',
    'Lewin Kłodzki',
    'Dobromierz',
    'Marcinowice',
    'Szczawno',
    'Czarny Bór',
    'Stare Bogaczowice',
    'Walim',
    'Ciepłowody',
    'Kamieniec Ząbkowicki',
    'Stoszowice',
    'Cieszków',
    'Krośnice',
    'Dobroszyce',
    'Dziadowa Kłoda',
    'Domaniów',
    'Borów',
    'Kondratowice',
    'Przeworno',
    'Kostomłoty',
    'Malczyce',
    'Miękinia',
    'Udanin',
    'Wisznia Mała',
    'Zawonia',
    'Wińsko',
    'Czernica',
    'Długołęka',
    'Jordanów Śląski',
    'Kobierzyce',
    'Mietków',
    'Święta Katarzyna',
    'Żórawina',
    'Białe Błota',
    'Dąbrowa Chełmińska',
    'Dobrcz',
    'Nowa Wieś Wielka',
    'Osielsko',
    'Sicienko',
    'Czernikowo',
    'Lubicz',
    'Łubianka',
    'Łysomice',
    'Obrowo',
    'Wielka Nieszawka',
    'Zławieś Wielka',
    'Bobrowo',
    'Brzozie',
    'Bartniczka',
    'Świedziebnia',
    'Zbiczno',
    'Kijewo Królewskie',
    'Lisewo',
    'Papowo Biskupie',
    'Stolno',
    'Unisław',
    'Ciechocin',
    'Radomin',
    'Zbójno',
    'Gruta',
    'Rogóźno',
    'Świecie nad Osą',
    'Sośno',
    'Bukowiec',
    'Dragacz',
    'Drzycim',
    'Jeżewo',
    'Lniano',
    'Osie',
    'Pruszcz',
    'Świekatowo',
    'Warlubie',
    'Cekcyn',
    'Gostycyn',
    'Kęsowo',
    'Lubiewo',
    'Śliwice',
    'Dębowa Łąka',
    'Książki',
    'Płużnica',
    'Bądkowo',
    'Koneck',
    'Raciążek',
    'Waganiec',
    'Zakrzewo',
    'Dąbrowa Biskupia',
    'Rojewo',
    'Złotniki Kujawskie',
    'Bobrowniki',
    'Chrostkowo',
    'Kikół',
    'Tłuchowo',
    'Wielgie',
    'Dąbrowa',
    'Jeziora Wielkie',
    'Sadki',
    'Bytoń',
    'Dobre',
    'Osięciny',
    'Topólka',
    'Brzuze',
    'Rogowo',
    'Skrwilno',
    'Wąpielsk',
    'Baruchowo',
    'Boniewo',
    'Choceń',
    'Fabianki',
    'Lubanie',
    'Gąsawa',
    'Drelów',
    'Janów Podlaski',
    'Kodeń',
    'Konstantynów',
    'Leśna Podlaska',
    'Łomazy',
    'Piszczac',
    'Rokitno',
    'Rossosz',
    'Sławatycze',
    'Sosnówka',
    'Tuczna',
    'Wisznice',
    'Zalesie',
    'Dębowa Kłoda',
    'Jabłoń',
    'Milanów',
    'Podedwórze',
    'Siemień',
    'Sosnowica',
    'Borki',
    'Czemierniki',
    'Kąkolewnica Wschodnia',
    'Komarówka Podlaska',
    'Ulan',
    'Wohyń',
    'Hanna',
    'Hańsk',
    'Stary Brus',
    'Urszulin',
    'Wola Uhruska',
    'Wyryki',
    'Aleksandrów',
    'Biszcza',
    'Goraj',
    'Księżpol',
    'Łukowa',
    'Obsza',
    'Potok Górny',
    'Tereszpol',
    'Turobin',
    'Białopole',
    'Dorohusk',
    'Dubienka',
    'Kamień',
    'Leśniowice',
    'Ruda',
    'Sawin',
    'Wierzbica',
    'Wojsławice',
    'Żmudź',
    'Dołhobyczów',
    'Horodło',
    'Mircze',
    'Trzeszczany',
    'Uchanie',
    'Werbkowice',
    'Fajsławice',
    'Gorzków',
    'Izbica',
    'Kraśniczyn',
    'Łopiennik Górny',
    'Rudnik',
    'Siennica Różana',
    'Żółkiewka',
    'Bełżec',
    'Jarczów',
    'Krynice',
    'Rachanie',
    'Susiec',
    'Tarnawatka',
    'Telatyn',
    'Ulhówek',
    'Adamów',
    'Grabowiec',
    'Komarów',
    'Łabunie',
    'Miączyn',
    'Nielisz',
    'Radecznica',
    'Sitno',
    'Skierbieszów',
    'Stary Zamość',
    'Sułów',
    'Abramów',
    'Firlej',
    'Jeziorzany',
    'Kamionka',
    'Michów',
    'Niedźwiada',
    'Ostrówek',
    'Serniki',
    'Uścimów',
    'Borzechów',
    'Garbów',
    'Głusk',
    'Jabłonna',
    'Jastków',
    'Konopnica',
    'Krzczonów',
    'Niedrzwica Duża',
    'Niemce',
    'Strzyżewice',
    'Wojciechów',
    'Wólka',
    'Wysokie',
    'Zakrzew',
    'Cyców',
    'Ludwin',
    'Milejów',
    'Puchaczów',
    'Spiczyn',
    'Mełgiew',
    'Rybczewice',
    'Trawniki',
    'Batorz',
    'Dzwola',
    'Godziszów',
    'Potok Wielki',
    'Dzierzkowice',
    'Gościeradów',
    'Szastarka',
    'Trzydnik Duży',
    'Wilkołaz',
    'Zakrzówek',
    'Krzywda',
    'Serokomla',
    'Stanin',
    'Trzebieszów',
    'Wojcieszków',
    'Wola Mysłowska',
    'Chodel',
    'Karczmiska',
    'Łaziska',
    'Wilków',
    'Baranów',
    'Janowiec',
    'Końskowola',
    'Kurów',
    'Markuszów',
    'Wąwolnica',
    'Żyrzyn',
    'Kłoczew',
    'Nowodwór',
    'Stężyca',
    'Ułęż',
    'Bogdaniec',
    'Deszczno',
    'Lubiszyn',
    'Santok',
    'Bledzew',
    'Przytoczna',
    'Pszczew',
    'Górzyca',
    'Stare Kurowo',
    'Zwierzyn',
    'Krzeszyce',
    'Słońsk',
    'Bobrowice',
    'Bytnica',
    'Kolsko',
    'Siedlisko',
    'Lubrza',
    'Skąpe',
    'Szczaniec',
    'Bojadła',
    'Trzebiechów',
    'Zabór',
    'Brzeźnica',
    'Niegosławice',
    'Wymiarki',
    'Brody',
    'Lipinki Łużyckie',
    'Przewóz',
    'Trzebiel',
    'Tuplice',
    'Andrespol',
    'Brójce',
    'Dmosin',
    'Jeżów',
    'Nowosolna',
    'Rogów',
    'Dłutów',
    'Dobroń',
    'Ksawerów',
    'Lutomiersk',
    'Parzęczew',
    'Drużbice',
    'Kleszczów',
    'Kluki',
    'Rusiec',
    'Szczerców',
    'Białaczów',
    'Mniszków',
    'Paradyż',
    'Poświętne',
    'Żarnów',
    'Czarnocin',
    'Gorzkowice',
    'Grabica',
    'Łęki Szlacheckie',
    'Moszczenica',
    'Ręczno',
    'Rozprza',
    'Wola Krzysztoporska',
    'Dobryszyce',
    'Gidle',
    'Gomunice',
    'Kobiele Wielkie',
    'Kodrąb',
    'Lgota Wielka',
    'Ładzice',
    'Masłowice',
    'Wielgomłyny',
    'Żytno',
    'Będków',
    'Budziszewice',
    'Czerniewice',
    'Inowłódz',
    'Lubochnia',
    'Rokiciny',
    'Rzeczyca',
    'Żelechlinek',
    'Buczek',
    'Sędziejowice',
    'Widawa',
    'Wodzierady',
    'Kiełczygłów',
    'Nowa Brzeźnica',
    'Rząśnia',
    'Siemkowice',
    'Strzelce Wielkie',
    'Dalików',
    'Pęczniew',
    'Wartkowice',
    'Zadzim',
    'Brąszewice',
    'Brzeźnio',
    'Burzenin',
    'Goszczanów',
    'Klonowa',
    'Wróblew',
    'Czarnożyły',
    'Mokrsko',
    'Osjaków',
    'Pątnów',
    'Skomlin',
    'Wierzchlas',
    'Czastary',
    'Galewice',
    'Lututów',
    'Łubnice',
    'Sokolniki',
    'Zapolice',
    'Bedlno',
    'Dąbrowice',
    'Krzyżanów',
    'Łanięta',
    'Nowe Ostrowy',
    'Oporów',
    'Strzelce',
    'Daszyna',
    'Góra Świętej Małgorzaty',
    'Grabów',
    'Piątek',
    'Świnice Warckie',
    'Witonia',
    'Bielawy',
    'Chąśno',
    'Domaniewice',
    'Kiernozia',
    'Kocierzew Południowy',
    'Łyszkowice',
    'Nieborów',
    'Cielądz',
    'Regnów',
    'Sadkowice',
    'Bolimów',
    'Głuchów',
    'Godzianów',
    'Kowiesy',
    'Lipce Reymontowskie',
    'Maków',
    'Nowy Kawęczyn',
    'Słupia',
    'Drwinia',
    'Lipnica Murowana',
    'Łapanów',
    'Rzezawa',
    'Trzciana',
    'Żegocina',
    'Czernichów',
    'Igołomia',
    'Iwanowice',
    'Jerzmanowice',
    'Kocmyrzów',
    'Liszki',
    'Michałowice',
    'Mogilany',
    'Sułoszowa',
    'Wielka Wieś',
    'Zabierzów',
    'Zielonki',
    'Charsznica',
    'Gołcza',
    'Kozłów',
    'Książ Wielki',
    'Racławice',
    'Słaboszów',
    'Lubień',
    'Pcim',
    'Raciechowice',
    'Siepraw',
    'Tokarnia',
    'Wiśniowa',
    'Koniusza',
    'Pałecznica',
    'Radziemice',
    'Biskupice',
    'Gdów',
    'Kłaj',
    'Lipinki',
    'Łużna',
    'Ropa',
    'Sękowa',
    'Uście Gorlickie',
    'Jodłownik',
    'Kamienica',
    'Laskowa',
    'Łukowica',
    'Niedźwiedź',
    'Słopnice',
    'Tymbark',
    'Chełmiec',
    'Gródek nad Dunajcem',
    'Kamionka Wielka',
    'Korzenna',
    'Łabowa',
    'Łącko',
    'Łososina Dolna',
    'Nawojowa',
    'Podegrodzie',
    'Rytro',
    'Czarny Dunajec',
    'Czorsztyn',
    'Jabłonka',
    'Krościenko nad Dunajcem',
    'Lipnica Wielka',
    'Łapsze Niżne',
    'Ochotnica Dolna',
    'Raba Wyżna',
    'Spytkowice',
    'Szaflary',
    'Biały Dunajec',
    'Bukowina Tatrzańska',
    'Kościelisko',
    'Poronin',
    'Babice',
    'Bolesław',
    'Klucze',
    'Trzyciąż',
    'Polanka Wielka',
    'Przeciszów',
    'Budzów',
    'Bystra',
    'Stryszawa',
    'Zawoja',
    'Zembrzyce',
    'Lanckorona',
    'Mucharz',
    'Stryszów',
    'Tomice',
    'Wieprz',
    'Borzęcin',
    'Gnojnik',
    'Iwkowa',
    'Szczurowa',
    'Gręboszów',
    'Mędrzechów',
    'Radgoszcz',
    'Gromnik',
    'Lisia Góra',
    'Pleśna',
    'Rzepiennik Strzyżewski',
    'Skrzyszów',
    'Wierzchosławice',
    'Wietrzychowice',
    'Szerzyny',
    'Gołymin',
    'Grudusk',
    'Ojrzeń',
    'Opinogóra Górna',
    'Regimin',
    'Sońsk',
    'Pacyna',
    'Szczawin Kościelny',
    'Dzierzgowo',
    'Lipowiec Kościelny',
    'Radzanów',
    'Strzegowo',
    'Stupsk',
    'Szreńsk',
    'Szydłowo',
    'Wieczfnia Kościelna',
    'Wiśniewo',
    'Bielsk',
    'Bodzanów',
    'Brudzeń Duży',
    'Bulkowo',
    'Łąck',
    'Mała Wieś',
    'Nowy Duninów',
    'Radzanowo',
    'Słupno',
    'Stara Biała',
    'Staroźreby',
    'Baboszewo',
    'Czerwińsk nad Wisłą',
    'Dzierzążnia',
    'Joniec',
    'Naruszewo',
    'Nowe Miasto',
    'Sochocin',
    'Załuski',
    'Gozdowo',
    'Mochowo',
    'Rościszewo',
    'Szczutowo',
    'Zawidz',
    'Kuczbork',
    'Lutocin',
    'Siemiątkowo',
    'Huszlew',
    'Olszanka',
    'Platerów',
    'Sarnaki',
    'Stara Kornica',
    'Czerwonka',
    'Karniewo',
    'Krasnosielc',
    'Młynarze',
    'Płoniawy',
    'Rzewnie',
    'Sypniewo',
    'Szelków',
    'Baranowo',
    'Czarnia',
    'Czerwin',
    'Goworowo',
    'Kadzidło',
    'Lelis',
    'Łyse',
    'Olszewo',
    'Rzekuń',
    'Troszyn',
    'Andrzejewo',
    'Boguty',
    'Małkinia Górna',
    'Nur',
    'Stary Lubotyń',
    'Szulborze Wielkie',
    'Wąsewo',
    'Zaręby Kościelne',
    'Czernice Borowe',
    'Jednorożec',
    'Krasne',
    'Krzynowłoga Mała',
    'Gzy',
    'Obryte',
    'Pokrzywnica',
    'Świercze',
    'Winnica',
    'Zatory',
    'Domanice',
    'Korczew',
    'Kotuń',
    'Mokobody',
    'Paprotnia',
    'Przesmyki',
    'Skórzec',
    'Suchożebry',
    'Wiśniew',
    'Wodynie',
    'Zbuczyn',
    'Bielany',
    'Ceranów',
    'Jabłonna Lacka',
    'Repki',
    'Sabnie',
    'Sterdyń',
    'Grębków',
    'Korytnica',
    'Liw',
    'Miedzna',
    'Sadowne',
    'Stoczek',
    'Wierzbno',
    'Brańszczyk',
    'Długosiodło',
    'Rząśnik',
    'Somianka',
    'Zabrodzie',
    'Promna',
    'Stara Błotnica',
    'Stromiec',
    'Garbatka',
    'Głowaczów',
    'Gniewoszów',
    'Grabów nad Pilicą',
    'Magnuszew',
    'Sieciechów',
    'Chotcza',
    'Ciepielów',
    'Rzeczniów',
    'Sienno',
    'Solec nad Wisłą',
    'Borkowice',
    'Gielniów',
    'Klwów',
    'Odrzywół',
    'Potworów',
    'Rusinów',
    'Wieniawa',
    'Gózd',
    'Jastrzębia',
    'Jedlińsk',
    'Jedlnia',
    'Kowala',
    'Przytyk',
    'Wolanów',
    'Chlewiska',
    'Jastrząb',
    'Mirów',
    'Orońsko',
    'Kazanów',
    'Policzna',
    'Przyłęk',
    'Tczów',
    'Bemowo',
    'Białołęka',
    'Mokotów',
    'Ochota',
    'Praga',
    'Rembertów',
    'Śródmieście',
    'Targówek',
    'Ursus',
    'Ursynów',
    'Wawer',
    'Wesoła',
    'Wilanów',
    'Włochy',
    'Wola',
    'Żoliborz',
    'Borowie',
    'Maciejowice',
    'Miastków Kościelny',
    'Parysów',
    'Sobolew',
    'Trojanów',
    'Wilga',
    'Nieporęt',
    'Wieliszew',
    'Cegłów',
    'Dębe Wielkie',
    'Jakubów',
    'Latowicz',
    'Siennica',
    'Stanisławów',
    'Czosnów',
    'Leoncin',
    'Pomiechówek',
    'Celestynów',
    'Kołbiel',
    'Osieck',
    'Sobienie',
    'Wiązowna',
    'Dąbrówka',
    'Jadów',
    'Klembów',
    'Strachówka',
    'Jaktorów',
    'Żabia Wola',
    'Belsk Duży',
    'Błędów',
    'Chynów',
    'Goszczyn',
    'Jasieniec',
    'Lesznowola',
    'Prażmów',
    'Nadarzyn',
    'Raszyn',
    'Brochów',
    'Iłów',
    'Młodzieszyn',
    'Nowa Sucha',
    'Rybno',
    'Teresin',
    'Izabelin',
    'Kampinos',
    'Stare Babice',
    'Puszcza Mariańska',
    'Radziejowice',
    'Wiskitki',
    'Skarbimierz',
    'Lubsza',
    'Lasowice Wielkie',
    'Domaszowice',
    'Pokój',
    'Świerczów',
    'Kamiennik',
    'Łambinowice',
    'Pakosławice',
    'Skoroszyce',
    'Branice',
    'Bierawa',
    'Cisek',
    'Pawłowiczki',
    'Polska Cerekiew',
    'Reńska Wieś',
    'Strzeleczki',
    'Walce',
    'Rudniki',
    'Zębowice',
    'Chrząstowice',
    'Dobrzeń Wielki',
    'Komprachcice',
    'Łubniany',
    'Murów',
    'Popielów',
    'Tarnów Opolski',
    'Turawa',
    'Izbicko',
    'Jemielnica',
    'Baligród',
    'Cisna',
    'Czarna',
    'Lutowiska',
    'Olszanica',
    'Solina',
    'Domaradz',
    'Dydnia',
    'Haczów',
    'Jasienica Rosielna',
    'Nozdrzec',
    'Brzyska',
    'Dębowiec',
    'Krempna',
    'Nowy Żmigród',
    'Osiek Jasielski',
    'Skołyszyn',
    'Tarnowiec',
    'Chorkówka',
    'Korczyna',
    'Krościenko Wyżne',
    'Miejsce Piastowe',
    'Wojaszówka',
    'Besko',
    'Bukowsko',
    'Komańcza',
    'Tyrawa Wołoska',
    'Zarszyn',
    'Chłopice',
    'Laszki',
    'Pawłosiów',
    'Rokietnica',
    'Roźwienica',
    'Wiązownica',
    'Horyniec',
    'Stary Dzików',
    'Wielkie Oczy',
    'Bircza',
    'Dubiecko',
    'Fredropol',
    'Krasiczyn',
    'Krzywcza',
    'Medyka',
    'Orły',
    'Stubno',
    'Żurawica',
    'Adamówka',
    'Gać',
    'Jawornik Polski',
    'Tryńcza',
    'Zarzecze',
    'Cmolas',
    'Majdan Królewski',
    'Niwiska',
    'Raniżów',
    'Dzikowiec',
    'Markowa',
    'Rakszawa',
    'Żołynia',
    'Iwierzyce',
    'Ostrów',
    'Wielopole Skrzyńskie',
    'Hyżne',
    'Lubenia',
    'Świlcza',
    'Trzebownisko',
    'Czudec',
    'Frysztak',
    'Niebylec',
    'Jodłowa',
    'Żyraków',
    'Grodzisko Dolne',
    'Kuryłówka',
    'Borowa',
    'Czermin',
    'Gawłuszowice',
    'Padew Narodowa',
    'Tuszów Narodowy',
    'Wadowice Górne',
    'Harasiuki',
    'Jeżowe',
    'Krzeszów',
    'Bojanów',
    'Pysznica',
    'Radomyśl nad Sanem',
    'Zaleszany',
    'Gorzyce',
    'Grębów',
    'Dobrzyniewo Duże',
    'Gródek',
    'Juchnowiec Kościelny',
    'Turośń Kościelna',
    'Zawady',
    'Janów',
    'Korycin',
    'Kuźnica',
    'Nowy Dwór',
    'Sidra',
    'Szudziałowo',
    'Boćki',
    'Orla',
    'Rudka',
    'Wyszki',
    'Białowieża',
    'Czeremcha',
    'Czyże',
    'Dubicze Cerkiewne',
    'Narew',
    'Narewka',
    'Grabowo',
    'Mały Płock',
    'Turośl',
    'Miastkowo',
    'Piątnica',
    'Przytuły',
    'Śniadowo',
    'Wizna',
    'Zbójna',
    'Dziadkowice',
    'Grodzisk',
    'Mielnik',
    'Milejczyce',
    'Nurzec',
    'Perlejewo',
    'Klukowo',
    'Kulesze Kościelne',
    'Nowe Piekuty',
    'Sokoły',
    'Kołaki Kościelne',
    'Rutki',
    'Szumowo',
    'Bargłów Kościelny',
    'Nowinka',
    'Płaska',
    'Sztabin',
    'Radziłów',
    'Jasionówka',
    'Jaświły',
    'Krypno',
    'Trzcianne',
    'Giby',
    'Krasnopol',
    'Puńsk',
    'Bakałarzewo',
    'Filipów',
    'Jeleniewo',
    'Przerośl',
    'Raczki',
    'Rutka',
    'Szypliszki',
    'Wiżajny',
    'Cedry Wielkie',
    'Kolbudy',
    'Przywidz',
    'Pszczółki',
    'Suchy Dąb',
    'Trąbki Wielkie',
    'Chmielno',
    'Przodkowo',
    'Sierakowice',
    'Somonino',
    'Sulęczyno',
    'Ostaszewo',
    'Stegna',
    'Sztutowo',
    'Kosakowo',
    'Krokowa',
    'Choczewo',
    'Gniewino',
    'Linia',
    'Luzino',
    'Łęczyce',
    'Szemud',
    'Borzytuchom',
    'Czarna Dąbrówka',
    'Kołczygłowy',
    'Lipnica',
    'Parchowo',
    'Studzienice',
    'Trzebielino',
    'Tuchomie',
    'Konarzyny',
    'Koczała',
    'Przechlewo',
    'Rzeczenica',
    'Cewice',
    'Nowa Wieś Lęborska',
    'Wicko',
    'Damnica',
    'Dębnica Kaszubska',
    'Główczyce',
    'Kobylnica',
    'Potęgowo',
    'Smołdzino',
    'Dziemiany',
    'Karsin',
    'Liniewo',
    'Lipusz',
    'Nowa Karczma',
    'Stara Kiszewa',
    'Gardeja',
    'Ryjewo',
    'Sadlinki',
    'Lichnowy',
    'Mikołajki Pomorskie',
    'Miłoradz',
    'Stare Pole',
    'Stary Dzierzgoń',
    'Stary Targ',
    'Bobowo',
    'Kaliska',
    'Lubichowo',
    'Smętowo Graniczne',
    'Zblewo',
    'Morzeszczyn',
    'Subkowy',
    'Bestwina',
    'Buczkowice',
    'Jasienica',
    'Jaworze',
    'Kozy',
    'Porąbka',
    'Wilkowice',
    'Brenna',
    'Chybie',
    'Goleszów',
    'Hażlach',
    'Istebna',
    'Zebrzydowice',
    'Gilowice',
    'Jeleśnia',
    'Koszarawa',
    'Lipowa',
    'Łękawica',
    'Łodygowice',
    'Milówka',
    'Radziechowy',
    'Rajcza',
    'Ślemień',
    'Świnna',
    'Ujsoły',
    'Węgierska Górka',
    'Boronów',
    'Ciasna',
    'Herby',
    'Kochanowice',
    'Koszęcin',
    'Pawonków',
    'Krupski Młyn',
    'Ożarowice',
    'Świerklaniec',
    'Tworóg',
    'Zbrosławice',
    'Dąbrowa Zielona',
    'Kamienica Polska',
    'Kłomnice',
    'Konopiska',
    'Kruszyna',
    'Lelów',
    'Mstów',
    'Mykanów',
    'Poczesna',
    'Przyrów',
    'Rędziny',
    'Starcza',
    'Lipie',
    'Miedźno',
    'Panki',
    'Popów',
    'Przystajń',
    'Wręczyca Wielka',
    'Niegowa',
    'Poraj',
    'Gierałtowice',
    'Pilchowice',
    'Rudziniec',
    'Wielowieś',
    'Kornowac',
    'Krzyżanowice',
    'Nędza',
    'Pietrowice Wielkie',
    'Gaszowice',
    'Jejkowice',
    'Lyski',
    'Świerklany',
    'Godów',
    'Lubomia',
    'Marklowice',
    'Mszana',
    'Mierzęcice',
    'Psary',
    'Irządze',
    'Kroczyce',
    'Włodowice',
    'Żarnowiec',
    'Ornontowice',
    'Wyry',
    'Goczałkowice',
    'Kobiór',
    'Miedźna',
    'Pawłowice',
    'Suszec',
    'Bojszowy',
    'Chełm Śląski',
    'Bieliny',
    'Górno',
    'Łopuszno',
    'Masłów',
    'Miedziana Góra',
    'Mniów',
    'Piekoszów',
    'Raków',
    'Sitkówka',
    'Strawczyn',
    'Zagnańsk',
    'Fałków',
    'Gowarczów',
    'Ruda Maleniecka',
    'Smyków',
    'Bałtów',
    'Bodzechów',
    'Waśniów',
    'Bliżyn',
    'Łączna',
    'Skarżysko Kościelne',
    'Mirzec',
    'Pawłów',
    'Gnojno',
    'Solec',
    'Tuczępy',
    'Imielno',
    'Nagłowice',
    'Oksa',
    'Sobków',
    'Wodzisław',
    'Bejsce',
    'Baćkowice',
    'Iwaniska',
    'Lipnik',
    'Sadowie',
    'Tarłów',
    'Wojciechowice',
    'Kije',
    'Michałów',
    'Złota',
    'Dwikozy',
    'Klimontów',
    'Łoniów',
    'Obrazów',
    'Samborzec',
    'Wilczyce',
    'Bogoria',
    'Rytwiany',
    'Kluczewsko',
    'Krasocin',
    'Moskorzew',
    'Secemin',
    'Lelkowo',
    'Płoskinia',
    'Wilczęta',
    'Iłowo',
    'Płośnica',
    'Godkowo',
    'Gronowo Elbląskie',
    'Markusy',
    'Milejewo',
    'Rychliki',
    'Grodziczno',
    'Kurzętnik',
    'Dąbrówno',
    'Grunwald',
    'Łukta',
    'Małdyty',
    'Kalinowo',
    'Prostki',
    'Stare Juchy',
    'Banie Mazurskie',
    'Budry',
    'Kruklanki',
    'Miłki',
    'Pozezdrze',
    'Wydminy',
    'Dubeninki',
    'Kowale Oleckie',
    'Świętajno',
    'Wieliczki',
    'Barciany',
    'Srokowo',
    'Kiwity',
    'Lubomino',
    'Piecki',
    'Sorkwity',
    'Janowiec Kościelny',
    'Janowo',
    'Kozłowo',
    'Dywity',
    'Gietrzwałd',
    'Jonkowo',
    'Purda',
    'Stawiguda',
    'Świątki',
    'Dźwierzuty',
    'Jedwabno',
    'Rozogi',
    'Kotlin',
    'Blizanów',
    'Ceków',
    'Godziesze Wielkie',
    'Koźminek',
    'Lisków',
    'Mycielin',
    'Szczytniki',
    'Żelazków',
    'Bralin',
    'Łęka Opatowska',
    'Perzów',
    'Rychtal',
    'Trzcinica',
    'Rozdrażew',
    'Przygodzice',
    'Sieroszewice',
    'Sośnie',
    'Czajków',
    'Doruchów',
    'Kobyla Góra',
    'Kraszewice',
    'Gizałki',
    'Gołuchów',
    'Kiszkowo',
    'Łubowo',
    'Mieleszyn',
    'Niechanowo',
    'Babiak',
    'Chodów',
    'Grzegorzew',
    'Kościelec',
    'Olszówka',
    'Osiek Mały',
    'Grodziec',
    'Kazimierz Biskupi',
    'Kramsk',
    'Krzymów',
    'Skulsk',
    'Stare Miasto',
    'Wierzbinek',
    'Wilczyn',
    'Lądek',
    'Orchowo',
    'Ostrowite',
    'Powidz',
    'Strzałkowo',
    'Brudzew',
    'Kawęczyn',
    'Malanów',
    'Przykona',
    'Władysławów',
    'Kołaczkowo',
    'Pępowo',
    'Granowo',
    'Kamieniec',
    'Krzemieniewo',
    'Święciechowa',
    'Wijewo',
    'Włoszakowice',
    'Chrzypsko Wielkie',
    'Kwilcz',
    'Kuślin',
    'Miedzichowo',
    'Pakosław',
    'Przemęt',
    'Siedlec',
    'Budzyń',
    'Drawsko',
    'Lubasz',
    'Połajewo',
    'Białośliwie',
    'Kaczory',
    'Miasteczko Krajeńskie',
    'Damasławek',
    'Mieścisko',
    'Wapno',
    'Lipka',
    'Tarnówka',
    'Ryczywół',
    'Czerwonak',
    'Dopiewo',
    'Kleszczewo',
    'Komorniki',
    'Suchy Las',
    'Tarnowo Podgórne',
    'Kaźmierz',
    'Dominowo',
    'Krzykosy',
    'Nowe Miasto nad Wartą',
    'Zaniemyśl',
    'Ostrowice',
    'Wierzchowo',
    'Dygowo',
    'Rymań',
    'Siemyśl',
    'Ustronie Morskie',
    'Będzino',
    'Biesiekierz',
    'Manowo',
    'Świeszyno',
    'Malechowo',
    'Postomino',
    'Grzmiąca',
    'Brzeżno',
    'Rąbino',
    'Sławoborze',
    'Bierzwnik',
    'Krzęcin',
    'Brojce',
    'Karnice',
    'Radowo Małe',
    'Rewal',
    'Boleszkowice',
    'Nowogródek Pomorski',
    'Bielice',
    'Kozielice',
    'Przelewice',
    'Warnice',
    'Dolice',
    'Kobylanka',
    'Marianowo',
    'Stara Dąbrowa',
    'Osina',
    'Przybiernów',
    'Banie',
    'Stare Czarnowo',
    'Widuchowa',
    'Świerzno',
    'Kołbaskowo'
]);

export const advertisementSortOptions = toSameValueOptions([
    'po dacie malejąco',
    'po dacie rosnąco',
    'po cenie rosnąco',
    'po cenie malejąco',
    'po cenie za m2 rosnąco',
    'po cenie za m2 malejąco',
    'od największej różnicy dat modyfikacji i dodania'
]);

export const tenderSortOptions = [
    { label: 'po dacie malejąco', value: TenderSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: TenderSearchingRequestSortTypeEnum.DATE_ASC }
];

export const auctionSortOptions = [
    { label: 'po dacie malejąco', value: AuctionSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: AuctionSearchingRequestSortTypeEnum.DATE_ASC }
];

export const cooperativeSortOptions = [
    { label: 'po dacie malejąco', value: TenderSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: TenderSearchingRequestSortTypeEnum.DATE_ASC }
];

export const sortOptions = [
    { label: 'po dacie malejąco', value: SortType.DATE_DESC },
    { label: 'po dacie rosnąco', value: SortType.DATE_ASC },
    { label: 'po czynszu rosnąco', value: SortType.PRICE_ASC },
    { label: 'po czynszu malejąco', value: SortType.PRICE_DESC },
    // { label: 'po czynszu za m2 rosnąco', value: SortType.PRICE_PER_SQUARE_METER_ASC },
    // { label: 'po czynszu za m2 malejąco', value: SortType.PRICE_PER_SQUARE_METER_DESC },
    { label: 'od największej różnicy dat modyfikacji i dodania', value: SortType.MODIFICATION_DATE }
];

export const saleSortOptions = [
    { label: 'po dacie malejąco', value: SaleSortType.DATE_DESC },
    { label: 'po dacie rosnąco', value: SaleSortType.DATE_ASC },
    { label: 'po cenie rosnąco', value: SaleSortType.PRICE_ASC },
    { label: 'po cenie malejąco', value: SaleSortType.PRICE_DESC },
    { label: 'po cenie za m2 rosnąco', value: SaleSortType.PRICE_PER_SQUARE_METER_ASC },
    { label: 'po cenie za m2 malejąco', value: SaleSortType.PRICE_PER_SQUARE_METER_DESC },
    { label: 'od największej różnicy dat modyfikacji i dodania', value: SaleSortType.MODIFICATION_DATE },
    { label: 'po najlepiej ocenianych ogłoszeniach rosnąco', value: SaleSortType.SCORE_ASC },
    { label: 'po najlepiej ocenianych ogłoszeniach malejąco', value: SaleSortType.SCORE_DESC }
];

export const bailiffSortOptions = [
    { label: 'po dacie malejąco', value: BailiffNoticeSearchingRequestSortTypeEnum.DATE_DESC },
    { label: 'po dacie rosnąco', value: BailiffNoticeSearchingRequestSortTypeEnum.DATE_ASC }
];

export const cityToLower = cityOptions.map((x) => ({ label: x.label, value: x.value.toLowerCase() }));

export const timeRangeOptions = [
    { label: 'Rok', value: 'Rok' },
    { label: 'Półrocze', value: 'Półrocze' },
    { label: 'Kwartał', value: 'Kwartał' },
    { label: 'Miesiąc', value: 'Miesiąc' },
    { label: 'Tydzień', value: 'Tydzień' }
];

export const usersPropertyTypeOptions = [
    { label: 'Mieszkanie', value: PropertyType.FLAT },
    { label: 'Dom', value: PropertyType.HOUSE },
    { label: 'Działka', value: PropertyType.PLOT },
    { label: 'Inne', value: PropertyType.OTHER }
];

export const rentPropertyTypeOptions = [
    { label: 'Mieszkanie', value: RentModuleType.RENT_FLAT },
    { label: 'Dom', value: RentModuleType.RENT_HOUSE },
    { label: 'Działka', value: RentModuleType.RENT_PLOT },
    { label: 'Inne', value: RentModuleType.RENT_OTHER }
];

export const salePropertyTypeOptions = [
    { label: 'Mieszkanie', value: SaleModuleType.SALE_FLAT },
    { label: 'Dom', value: SaleModuleType.SALE_HOUSE },
    { label: 'Działka', value: SaleModuleType.SALE_PLOT },
    { label: 'Inne', value: SaleModuleType.SALE_OTHER }
];

export const analysiSalePropertyTypeOptions = [
    { label: 'Mieszkanie', value: SaleModule.SALE_FLAT },
    { label: 'Dom', value: SaleModule.SALE_HOUSE },
    { label: 'Działka', value: SaleModule.SALE_PLOT },
    { label: 'Inne', value: SaleModule.SALE_OTHER }
];
export const v2offerTypeOptions = [
    { label: 'Odstąpię deal', value: V2OfferType.DEAL },
    { label: 'Szukam inwestora', value: V2OfferType.INVESTOR },
    { label: 'Mam kapitał', value: V2OfferType.CAPITAL }
];

export const usersBuildingOptions = [
    { label: 'Dom', value: InvestorBoxDtoBuildingTypeEnum.Dom },
    { label: 'Działka', value: InvestorBoxDtoBuildingTypeEnum.Dzialka },
    { label: 'Inwestycja deweloperska', value: InvestorBoxDtoBuildingTypeEnum.InwestycjaDeweloperska },
    { label: 'Lokal', value: InvestorBoxDtoBuildingTypeEnum.Lokal },
    { label: 'Kamienica', value: InvestorBoxDtoBuildingTypeEnum.Kamienica },
    { label: 'Mieszkanie', value: InvestorBoxDtoBuildingTypeEnum.Mieszkanie }
];

export const featureOptions = [
    { label: 'Balkon', value: FilterDealDtoFeaturesEnum.Balkon },
    { label: 'Ogródek', value: FilterDealDtoFeaturesEnum.Ogrodek },
    { label: 'Piwnica', value: FilterDealDtoFeaturesEnum.Piwnica },
    { label: 'Taras', value: FilterDealDtoFeaturesEnum.Taras },
    { label: 'Winda', value: FilterDealDtoFeaturesEnum.Winda },
    { label: 'Klimatyzacja', value: FilterDealDtoFeaturesEnum.Klimatyzacja },
    { label: 'Dwupoziomowe', value: FilterDealDtoFeaturesEnum.Dwupoziomowe },
    { label: 'Oddzielna kuchnia', value: FilterDealDtoFeaturesEnum.OddzielnaKuchnia },
    { label: 'Pomieszczenie użytkowe', value: FilterDealDtoFeaturesEnum.PomUzytkowe },
    { label: 'Strych', value: FilterDealDtoFeaturesEnum.Strych }
];

export const propertyConditionOptions = [
    { label: 'Do remontu', value: InvestorBoxDtoPropertyConditionEnum.DoRemontu },
    { label: 'Do lekkich poprawek', value: InvestorBoxDtoPropertyConditionEnum.DoLekkichPoprawek },
    { label: 'Surowy', value: InvestorBoxDtoPropertyConditionEnum.Surowy },
    { label: 'Nie dotyczy', value: InvestorBoxDtoPropertyConditionEnum.NieDotyczy }
];

export const legalStatusDealOptions = [
    { label: 'Bez KW', value: DealBaseDtoLegalStatusEnum.BezKW },
    { label: 'Odrębna własność', value: DealBaseDtoLegalStatusEnum.OdrebnaWlasnosc },
    { label: 'Spółdzielcze własnościowe z KW', value: DealBaseDtoLegalStatusEnum.SpoldzielczeWlasnoscioweZKW },
    { label: 'Udział', value: DealBaseDtoLegalStatusEnum.Udzial }
];

export const legalStatusInvestorOptions = [
    { label: 'Własność', value: InvestorBaseDtoLegalStatusEnum.Wlasnosc },
    { label: 'Współwłasność', value: InvestorBaseDtoLegalStatusEnum.Wspolwlasnosc },
    { label: 'Udział', value: InvestorBaseDtoLegalStatusEnum.Udzial }
];

export const formOfProfitOptions = [
    { label: 'Hybryda', value: CapitalBaseDtoFormOfProfitEnum.Hybryda },
    { label: 'Procentowa', value: CapitalBaseDtoFormOfProfitEnum.Procentowa },
    { label: 'Udział w zysku', value: CapitalBaseDtoFormOfProfitEnum.UdzialWZysku }
];

export const investmentSecurityCapitalOptions = [
    { label: 'Hipoteka na innej nieruchomości', value: CapitalBaseDtoInvestmentSecurityEnum.HipotekaNaInnejNieruchomosci },
    { label: 'Hipoteka na nieruchomości kupowanej', value: CapitalBaseDtoInvestmentSecurityEnum.HipotekaNaNieruchomosciKupowanej },
    { label: 'Umowa pożyczki', value: CapitalBaseDtoInvestmentSecurityEnum.UmowaPozyczki },
    { label: 'Weksel', value: CapitalBaseDtoInvestmentSecurityEnum.Weksel },
    { label: 'Własność nabywanej nieruchomości poręczenie prywatne', value: CapitalBaseDtoInvestmentSecurityEnum.WlasnoscNabywanejNieruchomosciPoreczeniePrywatne }
];

export const investmentSecurityInvestorOptions = [
    { label: 'Hipoteka na innej nieruchomości', value: InvestorBaseDtoInvestmentSecurityEnum.HipotekaNaInnejNieruchomosci },
    { label: 'Hipoteka na nieruchomości kupowanej', value: InvestorBaseDtoInvestmentSecurityEnum.HipotekaNaNieruchomosciKupowanej },
    { label: 'Umowa pożyczki', value: InvestorBaseDtoInvestmentSecurityEnum.UmowaPozyczki },
    { label: 'Weksel', value: InvestorBaseDtoInvestmentSecurityEnum.Weksel },
    { label: 'Poręczenie prywatne', value: InvestorBaseDtoInvestmentSecurityEnum.PoreczeniePrywatne }
];

export const formOfInvestmentCapitalOptions = [
    { label: 'Umowa pożyczki', value: CapitalBaseDtoFormOfInvestmentEnum.UmowaPozyczki },
    { label: 'Umowa inwestycyjna', value: CapitalBaseDtoFormOfInvestmentEnum.UmowaInwestycyjna },
    { label: 'Umowa o współpracy', value: CapitalBaseDtoFormOfInvestmentEnum.UmowaOWspolpracy }
];

export const preContractOptions = [
    { label: 'Tak', value: BooleanEnum.Tak },
    { label: 'Nie', value: BooleanEnum.Nie }
];

export const constructionCrewInvestorOptions = [
    { label: 'Tak', value: BooleanEnum.Tak },
    { label: 'Nie', value: BooleanEnum.Nie }
];

export const extraSmsToBuyOptions = [
    { label: '100', value: '100' },
    { label: '200', value: '200' },
    { label: '300', value: '300' },
    { label: '400', value: '400' },
    { label: '500', value: '500' },
    { label: '600', value: '600' },
    { label: '700', value: '700' },
    { label: '800', value: '800' }
];
