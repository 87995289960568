import dayjs from 'dayjs';
import { getArrayFilters } from 'utils/generateActiveFiltersContent';
import { CooperativeFilterReturnData, CooperativeFiltersStateType } from 'utils/types/Cooperative';
import { CooperativeSearchingRequestSortTypeEnum, FilterLocation } from 'utils/types/CooperativeModels';
import { CitiesType, DistrictType } from 'utils/types/Locations';

import { FilterItem } from 'components/common/V2ActiveFilters';
import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export const setCooperativeStateValues = (state: CooperativeFilterReturnData): CooperativeFiltersStateType => {
    const dateFromWithDefault = state.dateAddedFrom || dayjs().subtract(3, 'day').toISOString();

    return {
        filterId: state.filterId === 0 ? null : state.filterId,
        wantedKeywords: state.wantedKeywords ?? [],
        unwantedKeywords: state.unwantedKeywords ?? [],
        locations: state.locations ?? [],
        offerAdded: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.dateAddedTo && state.dateAddedTo.includes('T') ? state.dateAddedTo.split('T')[0] : state.dateAddedTo || ''
        },
        savedFilter: state.savedFilter ?? [],
        daysAmount: state.daysAmount ?? 3,
        dateRangeFilter: !!state.dateAddedTo,
        notificationsDelay: state.notificationsDelay?.toString() ?? '',
        alertSms: state.alertSms ?? false,
        alertEmail: state.alertEmail ?? false,
        alertPush: state.alertPush ?? false,
        name: state.name ?? '',
        module: state.module ?? 'Cooperative',
        city: state.city ?? [],
        searchLocationResult: [],
        savedLocations: state.savedLocations ?? {},
        sortType: state.sortType
    };
};

export const getCooperativeValueFromState = (state: CooperativeFiltersStateType): CooperativeFilterReturnData => {
    return {
        filterId: state.filterId!,
        name: state.name || '',
        notificationsDelay: Number(state.notificationsDelay),
        alertSms: state.alertSms,
        alertEmail: state.alertEmail,
        alertPush: state.alertPush,
        locations: state.locations,
        wantedKeywords: state.wantedKeywords,
        unwantedKeywords: state.unwantedKeywords,
        daysAmount: state.dateRangeFilter ? undefined : state.daysAmount,
        dateAddedFrom: state.dateRangeFilter ? dateFormat(dateToFunc(state.offerAdded.from)) : undefined,
        dateAddedTo: state.dateRangeFilter && state.offerAdded.to ? dateFormat(dateFromFunc(state.offerAdded.to)) : undefined,
        module: state.module,
        savedLocations: state.savedLocations,
        searchLocationResult: state.searchLocationResult,
        sortType: CooperativeSearchingRequestSortTypeEnum.DATE_DESC,
        city: state.city
    };
};

const cooperativeFilterInitialState = setCooperativeStateValues({
    filterId: 0,
    name: '',
    unwantedKeywords: [],
    wantedKeywords: [],
    locations: [],
    city: [],
    alertSms: false,
    alertEmail: false,
    alertPush: false,
    savedFilter: [],
    notificationsDelay: 0,
    dateAddedFrom: '',
    dateAddedTo: '',
    daysAmount: 30,
    module: 'COOPERATIVE',
    savedLocations: {},
    searchLocationResult: [],
    sortType: CooperativeSearchingRequestSortTypeEnum.DATE_DESC
});

export const initialState = {
    cooperativeFilterInitialState: () => {
        return cooperativeFilterInitialState;
    }
};

export const getCooperativeFiltersFromState = (state: CooperativeFiltersStateType): FilterItem[] => {
    return [
        ...getArrayFilters('Lokalizacja', Object.values(state.savedLocations).map((x: CitiesType | DistrictType | FilterLocation) => x.name ?? '').filter(Boolean), 'savedLocations', true),
        ...getArrayFilters('Słowa pożądane', state.wantedKeywords, 'wantedKeywords'),
        ...getArrayFilters('Słowa niepożądane', state.unwantedKeywords, 'unwantedKeywords'),
        ...state.daysAmount && !state.dateRangeFilter ? [{ label: 'Ogłoszenia od', value: state.daysAmount === -1 ? 'wszystkie dni' : `${state.daysAmount} dni`, noDeleteButton: true }] : [],
        ...state.dateRangeFilter
            ? [
                { label: 'Data od', value: state.offerAdded.from, noDeleteButton: true },
                { label: 'Data do', value: state.offerAdded.to, filterName: 'offerAdded' }
            ]
            : []
    ];
};

