import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ReactionType } from 'store/Offers/State';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import BailiffContext from 'utils/context/BailiffContext';
import { generateSingleOfferLink } from 'utils/generateSingleOfferLink';
import { getStringFromDate } from 'utils/getStringFromDate';
import { getBailiffFiltersFromState, getBailiffValueFromState } from 'utils/state-managment/bailiff/bailiffFilter';
import { BailiffOfferResponseContent } from 'utils/state-managment/bailiff/bailiffOffer';
import { BailiffFiltersStateType } from 'utils/types/Bailiff';
import { MultipleFilterType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';

import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import { InteractiveElements } from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { TabType } from 'components/common/Tabs/V2Tabs';
import {
    bailiffOfferListFunctions
} from 'components/functions/offerListFunctions/bailiffOfferListFunctions/bailiffOfferListFunctions';
import { generateOfferActionsElements } from 'components/functions/shared/generateOfferActionsElements';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';

const BailiffOffers = () => {
    const {
        bailiffFiltersState,
        handleChange,
        bailiffOfferState,
        loadBailiffOffers,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync
    } = useContext(BailiffContext);
    const [moreButtonActiveId, setMoreButtonActiveId] = useState<string | null>(null);
    const [detailsMoreButtonActiveId, setDetailsMoreButtonActiveId] = useState<string | null>(null);

    const [renderList, setRenderList] = useState<RenderList[]>([]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        loadBailiffOffers({ page: currentPage, rows });
    }, [bailiffFiltersState]);

    const handleActiveFiltersChange = () => {
        loadBailiffOffers({ page: 1, rows: 25 });
    };

    const handleOlderAdverts = () => {
        const date = bailiffFiltersState.offerAdded.from ? new Date(new Date(bailiffFiltersState.offerAdded.from).setDate(new Date(bailiffFiltersState.offerAdded.from).getDate() - 6)) : null;
        const dateString = date && getStringFromDate(date.toDateString());

        loadBailiffOffers({
            requestData: {
                ...getBailiffValueFromState(bailiffFiltersState),
                ...{ offerAddedFrom: !bailiffFiltersState.daysAmount ? dateString ?? undefined : undefined },
                ...{ daysAmount: bailiffFiltersState.daysAmount ? bailiffFiltersState.daysAmount + 6 : undefined }
            },
            page: bailiffOfferState.bailiffOfferList?.pageable?.pageNumber,
            rows: bailiffOfferState.bailiffOfferList && bailiffOfferState.bailiffOfferList.size ? bailiffOfferState.bailiffOfferList.size : 25
        });
    };

    const onNoteAdd = () => {
        // TODO: INVEST-257 Czy ta funkcja jest potrzebna?
        // if (!showNote && showLastNote) {
        //     showLastNote();
        // }
        //
        // if (!data.note?.noteId) {
        //     openOfferTab('note');
        // }
    };

    const onMessageClick = () => {
        onNoteAdd();
    };

    const handleDelete = (noteId: number, id: number) => {
        deleteNote(id, noteId);
    };

    const handleSaveNote = async (value: string, advertId: number, id?: number) => {
        updateNote(value, advertId, id || 0);
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: BailiffOfferResponseContent) => {
        if (!detailsOpen) {
            if (offer.reaction && !offer.reaction.viewedStatus) {
                reactionUpdate(offer.bailiffNoticeId, offer.location!, {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                } as ReactionType);
            }

            loadOfferDetailsAsync(offer.bailiffNoticeId);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: BailiffOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        reactionUpdate(offer.bailiffNoticeId, city, userReaction as UserReactions);
    };

    const handleDeleteFilterValue = (filterName: string, value?: string | number | null | string[], index?: number) => {
        const newState = { ...bailiffFiltersState };

        if (index !== undefined && Array.isArray(newState[filterName as keyof BailiffFiltersStateType])) {
            const array = newState[filterName as keyof BailiffFiltersStateType] as (string | number | null)[];
            array.splice(index, 1);
            (newState[filterName as keyof BailiffFiltersStateType] as (string | number | null)[]) = array;
        } else if (
            typeof newState[filterName as keyof BailiffFiltersStateType] === 'object' &&
            newState[filterName as keyof BailiffFiltersStateType] !== null &&
            'from' in (newState[filterName as keyof BailiffFiltersStateType] as object) &&
            'to' in (newState[filterName as keyof BailiffFiltersStateType] as object)
        ) {
            const rangeFilter = newState[filterName as keyof BailiffFiltersStateType] as MultipleFilterType;

            if (value === rangeFilter.from) {
                rangeFilter.from = null;
            } else if (value === rangeFilter.to) {
                rangeFilter.to = null;
            }
        } else {
            (newState[filterName as keyof BailiffFiltersStateType] as string | number | null | undefined) = null;
        }
        handleChange(filterName, newState[filterName as keyof BailiffFiltersStateType] as ObjectAndSingleValueChangeType);
    };

    const generateInteractiveElements = (offer: BailiffOfferResponseContent): InteractiveElements[] => {
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'bailiff-notice');
        const isMoreButtonActive = moreButtonActiveId === offer.bailiffNoticeId.toString();
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: onMessageClick
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            {
                icon: 'squares' as IconEnum,
                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(offer.bailiffNoticeId.toString()),
                additionalJSXSibling: isMoreButtonActive ? <OfferActions onClickOutside={() => setMoreButtonActiveId(null)} elements={offerActionsElements} /> : undefined
            }
        ];
    };

    const generateSquareIcon = (offerId: string, isDetail: boolean, offer: BailiffOfferResponseContent) => {
        const buttonId = isDetail ? `${offerId}-detail` : offerId;
        const isMoreButtonActive = isDetail ? detailsMoreButtonActiveId === buttonId : moreButtonActiveId === buttonId;
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'bailiff-notice');
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return {
            icon: 'squares' as IconEnum,
            tooltipText: isTouchDevice() ? undefined : 'Więcej',
            onClick: () => {
                if (isDetail) {
                    isMoreButtonActive
                        ? setDetailsMoreButtonActiveId(null)
                        : setDetailsMoreButtonActiveId(buttonId);
                } else {
                    isMoreButtonActive
                        ? setMoreButtonActiveId(null)
                        : setMoreButtonActiveId(buttonId);
                }
            },
            additionalJSXSibling: isMoreButtonActive
                ? <OfferActions
                    onClickOutside={() => isDetail ? setDetailsMoreButtonActiveId(null) : setMoreButtonActiveId(null)}
                    isInModal={isDetail}
                    elements={offerActionsElements}
                />
                : undefined
        };
    };

    const handleNotes = async (offer: BailiffOfferResponseContent) => {
        loadNotesAsync(offer.bailiffNoticeId.toString());
    };

    const generateCardData = (offer: BailiffOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        return {
            preTitle: `Lokalizacja sądu: ${offer.officeLocation ? offer.officeLocation : 'brak informacji'}`,
            additionalInfoBadge: `${offer.dateAdded}`,
            infoElements: bailiffOfferListFunctions.generateInfoElements(offer),
            title: offer.location || '',
            interactiveElements: generateInteractiveElements(offer),
            note: offer.newestNote,
            thumbnailPath: offer.image ? offer.image : './defaultImg.png',
            subMainValues
        };
    };

    useEffect(() => {
        const offersList = bailiffOfferState.bailiffOfferList.content
            ? bailiffOfferState.bailiffOfferList?.content.map((offer): RenderList => {
                const interactiveElements = generateInteractiveElements(offer);
                interactiveElements.splice(-1);
                const detailsTabContent = bailiffOfferListFunctions.generateOfferDetailsTabContent(offer);

                return {
                    id: offer.bailiffNoticeId,
                    onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                    baseCardContent: {
                        bigImage: true,
                        cardData: {
                            ...generateCardData(offer),
                            interactiveElements: [
                                ...interactiveElements,
                                generateSquareIcon(offer.bailiffNoticeId.toString(), false, offer)
                            ]
                        },
                        openOfferTab: () => {
                        }
                    },
                    detailsCardData: {
                        interactiveElements: [
                            ...interactiveElements,
                            generateSquareIcon(offer.bailiffNoticeId.toString(), true, offer)
                        ],
                        thumbnailPath: offer.image ? offer.image : './defaultImg.png',
                        additionalInfoBadge: `${offer.dateAdded}`,
                        onToggleContainerDetails: () => {
                        },
                        tabsContent: {
                            toggleContainerDetails: () => {
                            },
                            interactiveElements: [
                                ...interactiveElements,
                                generateSquareIcon(offer.bailiffNoticeId.toString(), true, offer)
                            ],
                            detailsTabContent,
                            notesTabContent: {
                                notes: bailiffOfferState.notes,
                                handleDelete: (noteId: string) => handleDelete(Number(noteId), offer.bailiffNoticeId),
                                handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.bailiffNoticeId, Number(id))
                            },
                            numberOfNotes: offer.numberOfNotes || 0,
                            handleLoadNotes: () => handleNotes(offer)
                        }
                    }
                };
            })
            : [];

        setRenderList(offersList);
    }, [
        bailiffOfferState.bailiffOfferList?.content,
        bailiffOfferState.notes,
        moreButtonActiveId,
        detailsMoreButtonActiveId
    ]);

    return (
        <div>
            <V2OfferList totalPages={bailiffOfferState.bailiffOfferList.totalPages} handleChangePage={handleChangePage}
                pageNumber={bailiffOfferState.bailiffOfferList?.pageable?.pageNumber || 1}
                lastPage={bailiffOfferState.bailiffOfferList.last}
                handleOlderAdverts={handleOlderAdverts}
                isLoadMoreButton
                handleActiveFiltersChange={handleActiveFiltersChange}
                showList={!bailiffOfferState.bailiffOfferList.empty && bailiffOfferState.bailiffOfferList.content?.length > 0}
                filters={getBailiffFiltersFromState(bailiffFiltersState)}
                activeFiltersState={bailiffFiltersState}
                onDeleteFilter={handleDeleteFilterValue}
                showNoItemsText={bailiffOfferState.bailiffOfferList && bailiffOfferState.bailiffOfferList.empty && bailiffOfferState.bailiffOfferList.content.length === 0 && bailiffOfferState.bailiffOfferList.afterSearch}
                renderList={renderList}
            />
        </div>
    );
};

export default BailiffOffers;
