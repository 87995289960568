import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { AnalysisElements, Title } from 'components/analysis/AnalysisSaleList';
import { AreaRangeAverageMeterAndTotalPrice } from 'components/analysis/components/AreaRangeAverageMeterAndTotalPrice';
import { AreaRangePie } from 'components/analysis/components/AreaRangePie';
import { AreaRangeSquareMeterBars } from 'components/analysis/components/AreaRangeSquareMeterBars';
import { AreaRangeTable } from 'components/analysis/components/AreaRangeTable';
import { AreaRangeTotalPriceBars } from 'components/analysis/components/AreaRangeTotalPriceBars';
import { TimeRangeAverageMeterAndArea } from 'components/analysis/components/TimeRangeAverageMeterAndArea';
import { TimeRangeAverageMeterAndTotalPrice } from 'components/analysis/components/TimeRangeAverageMeterAndTotalPrice';
import { TimeRangeAverageTotalPriceAndArea } from 'components/analysis/components/TimeRangeAverageTotalPriceAndArea';
import { TimeRangePie } from 'components/analysis/components/TimeRangePie';
import { TimeRangeSquareMeterBars } from 'components/analysis/components/TimeRangeSquareMeterBars';
import { TimeRangeTable } from 'components/analysis/components/TimeRangeTable';
import { TimeRangeTotalPriceBars } from 'components/analysis/components/TimeRangeTotalPriceBars';
import { BarChartType, ComposedChartType, LineBarChartType, PieChartType } from 'components/analysis/models';

import { PdfWrapper } from './StaticElements';

const TitleWrapper = styled.div`
    padding: 12px;
`;

interface AnalysisPdfPageProperties {
    analysis: AnalysisElements;
    transactionCountData: PieChartType[];
    averagePriceByArea: BarChartType[];
    totalPriceByArea: BarChartType[];
    averagePriceByMeter: LineBarChartType[];
    transactionRangesData: PieChartType[];
    averagePriceByTime: BarChartType[];
    totalPriceByTime: BarChartType[];
    averagePriceAreaByTime: LineBarChartType[];
    averagePriceMeterByTime: LineBarChartType[];
    averageAllPriceByTime: LineBarChartType[];
    trendDataChart: ComposedChartType[];
}

const AnalysisPdfPage = forwardRef<HTMLDivElement, AnalysisPdfPageProperties>(({
    analysis,
    transactionCountData,
    averagePriceByArea,
    totalPriceByArea,
    averagePriceByMeter,
    transactionRangesData,
    averagePriceByTime,
    totalPriceByTime,
    averagePriceAreaByTime,
    averagePriceMeterByTime,
    averageAllPriceByTime
}, ref) => {
    return (
        <PdfWrapper id={'pdfSection'} ref={ref}>
            {analysis.areaAnalysisList && analysis.areaAnalysisList.length > 0 && <>
                <TitleWrapper><Title>Raport wg przedziałów powierzchni</Title></TitleWrapper>
                <AreaRangeTable analysis={analysis} />
                <AreaRangePie transactionCountData={transactionCountData}/>
                <AreaRangeSquareMeterBars averagePriceByArea={averagePriceByArea}/>
                <AreaRangeTotalPriceBars totalPriceByArea={totalPriceByArea}/>
                <AreaRangeAverageMeterAndTotalPrice averagePriceByMeter={averagePriceByMeter}/>
            </>}
            {analysis.timeAnalysisList && analysis.timeAnalysisList.length > 0 && <>
                <TitleWrapper><Title>Raport według czasu</Title></TitleWrapper>
                <TimeRangeTable analysis={analysis}/>
                <TimeRangePie transactionRangesData={transactionRangesData} />
                <TimeRangeSquareMeterBars averagePriceByTime={averagePriceByTime}/>
                <TimeRangeTotalPriceBars totalPriceByTime={totalPriceByTime}/>
                <TimeRangeAverageMeterAndTotalPrice averagePriceAreaByTime={averagePriceAreaByTime}/>
                <TimeRangeAverageMeterAndArea averagePriceMeterByTime={averagePriceMeterByTime}/>
                <TimeRangeAverageTotalPriceAndArea averageAllPriceByTime={averageAllPriceByTime}/>
            </>}
            {/* TODO: Do odkomentowania po naprawie raportów weg trendu */}
            {/* {analysis && analysis.trendResult && analysis.trendResult.length > 0 && (<>*/}
            {/*    <TitleWrapper><Title>Raport według trendu</Title></TitleWrapper>*/}
            {/*    <TrendRangeAverageTotalPriceAndArea trendDataChart={trendDataChart} analysis={analysis}/>*/}
            {/* </>)}*/}
        </PdfWrapper>
    );
});

export default AnalysisPdfPage;
