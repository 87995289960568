import React, { forwardRef } from 'react';
import investoroLogo from 'assets/icons/investoro_pdf_logo.png';
import dayjs from 'dayjs';
import styled from 'styled-components';

const PdfPageHeader = styled.div`
    background: #F2F5F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-bottom: 1px solid #030D451A;

    p {
        --font-size-body: 16px;

        line-height: 30px;
    }

    img {
        width: 150px;
    }
`;

const PdfPageFooter = styled.div`
    background: #F2F5F9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    border-top: 1px solid #030D451A;

    p {
        --font-size-body: 16px;

        line-height: 30px;
    }

    img {
        width: 150px;
    }
`;

export const PdfWrapper = styled.div`
    background-color: #F2F5F9;
    position: absolute;
    top: 0;
    left: 100vw;
    width: 1000px;
    
    .accordion-card-content {
        grid-template-columns: 1fr 1fr !important;
    }
`;

export const PdfHeader = forwardRef((_, ref) => <PdfPageHeader ref={ref}>
    <img src={investoroLogo} alt="logo"/>

    <p>Raport cen ofertowych (ogłoszenia sprzedaży) | {dayjs(new Date()).format('DD.MM.YYYY')}</p>
</PdfPageHeader>);

export const PdfFooter = forwardRef((_, ref) => <PdfPageFooter ref={ref}>
    <p>{dayjs(new Date()).format('DD.MM.YYYY')} | www.investoro.pl</p>
    {/* <p>01</p>*/}
</PdfPageFooter>);
