import dayjs from 'dayjs';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { getArrayFilters, getRangeFilters } from 'utils/generateActiveFiltersContent';
import { getNameFromSaleModule } from 'utils/getNameFromModule';
import { SaleFilterReturnData, SaleFiltersStateType } from 'utils/types/Sale';
import { Module } from 'utils/types/SaleModels';
import { SortType } from 'utils/types/SortType';

import { FilterItem } from 'components/common/V2ActiveFilters';
import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export const setSaleStateValues = (state: SaleFilterReturnData): SaleFiltersStateType => {
    const dateFromWithDefault = state.offerAddedFrom || dayjs().subtract(3, 'day').toISOString();

    return {
        ...state,
        id: state.id === 0 ? undefined : state.id,
        // @ts-expect-error TODO: INVEST-254 fix this
        city: state.city ?? [],
        wantedKeywords: state.wantedKeywords ?? [],
        unwantedKeywords: state.unwantedKeywords ?? [],
        wantedPhoneNumbers: state.wantedPhoneNumbers ?? [],
        unwantedPhoneNumbers: state.unwantedPhoneNumbers ?? [],
        numberOfRooms: { // houses, flats
            from: state.numberOfRoomsFrom ?? null,
            to: state.numberOfRoomsTo ?? null
        },
        offerAdded: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.offerAddedTo && state.offerAddedTo.includes('T') ? state.offerAddedTo.split('T')[0] : state.offerAddedTo || ''
        },
        floor: { // other, houses, flats
            from: state.floorFrom ?? '',
            to: state.floorTo ?? ''
        },
        floors: { // flats
            from: state.floorsFrom ?? '',
            to: state.floorsTo ?? ''
        },
        area: {
            from: state.areaFrom ?? '',
            to: state.areaTo ?? ''
        },
        pricePerSquareMeter: {
            from: state.pricePerSquareMeterFrom ?? '',
            to: state.pricePerSquareMeterTo ?? ''
        },
        buildingType: state.buildingType ?? [],
        price: {
            from: state.priceFrom ?? '',
            to: state.priceTo ?? ''
        },
        builtYear: { // other, houses, flats
            from: state.builtYearFrom ?? '',
            to: state.builtYearTo ?? ''
        },
        savedFilter: state.savedFilter ?? [],
        daysAmount: state.daysAmount ?? 3,
        dateRangeFilter: !!state.offerAddedTo,
        lowerOriginalPrice: state.lowerOriginalPrice ?? false
    };
};

export const getSaleValueFromState = (state: SaleFiltersStateType): SaleFilterReturnData => {
    const dataToReturn: SaleFilterReturnData = {
        ...state,
        notificationsDelay: Number(state.notificationsDelay),
        name: state.name ? state.name : '',
        city: state.city ? state.city : [],
        offerAddedTo: state.dateRangeFilter && state.offerAdded.to ? dateFormat(dateFromFunc(state.offerAdded.to)) : undefined,
        offerAddedFrom: state.dateRangeFilter ? dateFormat(dateToFunc(state.offerAdded.from)) : undefined,
        areaFrom: stringToNumber(state.area.from),
        areaTo: stringToNumber(state.area.to),
        priceFrom: stringToNumber(state.price.from),
        priceTo: stringToNumber(state.price.to),
        pricePerSquareMeterFrom: stringToNumber(state.pricePerSquareMeter.from),
        pricePerSquareMeterTo: stringToNumber(state.pricePerSquareMeter.to),
        numberOfRoomsFrom: stringToNumber(state.numberOfRooms.from),
        numberOfRoomsTo: stringToNumber(state.numberOfRooms.to),
        builtYearFrom: stringToNumber(state.builtYear.from),
        builtYearTo: stringToNumber(state.builtYear.to),
        buildingType:
            state.buildingType?.length > 0
                ? state.buildingType
                : [],
        // @ts-expect-error TODO: INVEST-254
        floorFrom: stringToNumber(state.floor.from),
        // @ts-expect-error TODO: INVEST-254
        floorTo: stringToNumber(state.floor.to),
        floorsFrom: stringToNumber(state.floors.from),
        floorsTo: stringToNumber(state.floors.to),
        typeOfMarket: state.typeOfMarket?.length > 0 ? state.typeOfMarket : [],
        savedFilter: undefined,
        daysAmount: state.dateRangeFilter ? undefined : state.daysAmount,
        lowerOriginalPrice: state.lowerOriginalPrice
    };

    return dataToReturn;
};

const saleFilterInitialState = setSaleStateValues({
    id: 0,
    name: '',
    numberOfRoomsFrom: undefined,
    numberOfRoomsTo: undefined,
    floorFrom: undefined,
    floorTo: undefined,
    floorsFrom: undefined,
    floorsTo: undefined,
    unwantedKeywords: [],
    wantedKeywords: [],
    unwantedPhoneNumbers: [],
    wantedPhoneNumbers: [],
    city: [],
    locations: [],
    offerFrom: [],
    savedLocations: {},
    searchLocationResult: [],
    areaFrom: undefined,
    areaTo: undefined,
    typeOfMarket: [],
    buildingType: [],
    priceFrom: undefined,
    priceTo: undefined,
    pricePerSquareMeterFrom: undefined,
    pricePerSquareMeterTo: undefined,
    builtYearFrom: undefined,
    builtYearTo: undefined,
    alertSms: false,
    alertEmail: false,
    pushAlert: false,
    savedFilter: [],
    module: Module.SALE_FLAT,
    tossedUp: true,
    notificationsDelay: 0,
    includeZeroArea: true,
    groupDuplicate: true,
    offerAddedFrom: '',
    offerAddedTo: '',
    modification: true,
    daysAmount: 3,
    sortType: SortType.DATE_DESC,
    lowerOriginalPrice: false
});

export const initialState = {
    saleFilterInitialState: () => {
        return saleFilterInitialState;
    }
};

export const getSaleFiltersFromState = (state: SaleFiltersStateType): FilterItem[] => {
    return [
        ...getArrayFilters('Lokalizacja', Object.values(state.savedLocations).map((x) => x.fullName), 'savedLocations', true),
        ...getRangeFilters('Cena', state.price, 'price', 'zł'),
        ...getRangeFilters('Metraż', state.area, 'area', 'm2'),
        ...getRangeFilters('Liczba pokoi', state.numberOfRooms, 'numberOfRooms'),
        ...getRangeFilters('Piętro', state.floor, 'floor'),
        ...getRangeFilters('Ilość pięter', state.floors, 'floors'),
        ...getRangeFilters('Rok budowy', state.builtYear, 'builtYear'),
        ...getRangeFilters('Cena za m²', state.pricePerSquareMeter, 'pricePerSquareMeter'),
        ...getArrayFilters('Typ budynku', state.buildingType, 'buildingType'),
        ...getArrayFilters('Oferta od', state.offerFrom, 'offerFrom'),
        ...getArrayFilters('Rynek', state.typeOfMarket, 'typeOfMarket'),
        ...getArrayFilters('Słowa pożądane', state.wantedKeywords, 'wantedKeywords'),
        ...getArrayFilters('Słowa niepożądane', state.unwantedKeywords, 'unwantedKeywords'),
        ...getArrayFilters('Numery pożądane', state.wantedPhoneNumbers, 'wantedPhoneNumbers'),
        ...getArrayFilters('Numery niepożądane', state.unwantedPhoneNumbers, 'unwantedPhoneNumbers'),
        { label: 'Typ nieruchomości', value: getNameFromSaleModule(state.module), index: 0, noDeleteButton: true },
        ...state.daysAmount && !state.dateRangeFilter ? [{ label: 'Ogłoszenia od', value: state.daysAmount === -1 ? 'wszystkie dni' : `${state.daysAmount} dni`, noDeleteButton: true }] : [],
        ...state.dateRangeFilter
            ? [
                { label: 'Data od', value: state.offerAdded.from, noDeleteButton: true },
                { label: 'Data do', value: state.offerAdded.to, filterName: 'offerAdded' }
            ]
            : []
    ];
};
