import { CooperativeDetailsResponse,
    CooperativeResponse,
    CooperativeSearchingRequestSortTypeEnum, NoteResponse } from 'utils/types/CooperativeModels';
import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';

export interface CooperativeOfferStateType {
  isLoading: boolean,
  cooperativeOfferList: CooperativeOffersResponse,
  sortType: CooperativeSearchingRequestSortTypeEnum;
  filtersState: FiltersStateType;
  pageType: ModulesType;
  filteredCity: string;
  notes?: NoteResponse[],
  portalLlist?: string[],
}

export type CooperativeOfferDetailsResponse = CooperativeDetailsResponse;

export interface CooperativeOfferResponseContent extends CooperativeResponse {
  detailedContent?: CooperativeOfferDetailsResponse;
}

export interface CooperativeOffersResponse {
  empty: boolean,
  content: CooperativeOfferResponseContent[],
  pageable: {
      pageNumber: number,
      pageSize: number,
  },
  numberOfElements: number,
  totalPages: number,
  totalElements: number,
  last: boolean,
  size: number,
  afterSearch: boolean,
  portalList: string[]
}

export const cooperativeInitialState: CooperativeOfferStateType = {
    isLoading: false,
    cooperativeOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false,
        afterSearch: false,
        portalList: []
    },
    sortType: CooperativeSearchingRequestSortTypeEnum.DATE_DESC,
    filtersState: {} as FiltersStateType,
    pageType: 'cooperative',
    filteredCity: ''
};
