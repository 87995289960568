/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProfileDto {
  /**
   * Nazwa firmy lub imię i nazwisko
   * @example "Testowy Michał"
   */
  name: string;
  /**
   * Email
   * @example "test@test.pl"
   */
  email: string;
  /**
   * Numer telefonu
   * @example "123456789"
   */
  phoneNumber: string;
  /**
   * Opis
   * @example "Opis testowy"
   */
  description?: string;
  /**
   * Zdjęcie użytkownika
   * @example "35trfgr5435.webp"
   */
  userPhoto?: string;
}

export interface MapResponse {
  empty?: boolean;
  [key: string]: any;
}

export enum BooleanEnum {
  Tak = "Tak",
  Nie = "Nie",
}

export enum BuildingTypeEnum {
  Lokal = "Lokal",
  Mieszkanie = "Mieszkanie",
  Dzialka = "Działka",
  Kamienica = "Kamienica",
  Dom = "Dom",
  InwestycjaDeweloperska = "Inwestycja deweloperska",
}

export interface FilterEditInvestorDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  buildingType?: BuildingTypeEnum[];
  /** Stan prawny */
  legalStatus?: FilterEditInvestorDtoLegalStatusEnum;
  propertyCondition?: PropertyConditionEnum[];
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 5
   */
  floorTo?: number;
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorsFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 5
   */
  floorsTo?: number;
  /**
   * Powierzchnia od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  preContract?: BooleanEnum[];
  constructionCrew?: BooleanEnum[];
  /**
   * Wkład własny od
   * @format double
   * @min 0
   * @example 1000
   */
  ownContributionFrom?: number;
  /**
   * Wkład własny do
   * @format double
   * @min 0
   * @example 3000
   */
  ownContributionTo?: number;
  /**
   * Wkład inwestora od
   * @format double
   * @min 0
   * @example 1000
   */
  investorContributionFrom?: number;
  /**
   * Wkład inwestora do
   * @format double
   * @min 0
   * @example 1000
   */
  investorContributionTo?: number;
  /**
   * Całkowita wartość inwestycji od
   * @format double
   * @min 0
   * @example 400000
   */
  totalInvestmentValueFrom?: number;
  /**
   * Całkowita wartość inwestycji do
   * @format double
   * @min 0
   * @example 700000
   */
  totalInvestmentValueTo?: number;
  /**
   * Minimalna cena sprzedaży od
   * @format double
   * @min 0
   * @example 450000
   */
  minimumSalePrice?: number;
  /**
   * Maksymalna cena sprzedaży do
   * @format double
   * @min 0
   * @example 700000
   */
  maximumSalePrice?: number;
  /**
   * Szacowana data sprzedaży od
   * @format date-time
   */
  estimatedSaleDateFrom?: string;
  /**
   * Szacowana data sprzedaży do
   * @format date-time
   */
  estimatedSaleDateTo?: string;
  /**
   * Kwota inwestycji
   * Kwota inwestycji bierze dwa pola z ogłoszeń 'investmentAmountFrom' i 'investmentAmountTo'
   * @format double
   * @min 0
   * @example 10000
   */
  investmentAmountFrom?: number;
  /**
   * Kwota inwestycji
   * Kwota inwestycji bierze dwa pola z ogłoszeń 'investmentAmountFrom' i 'investmentAmountTo'
   * @format double
   * @example 500000
   */
  investmentAmountTo?: number;
  /**
   * Procent od
   * @format double
   * @min 0
   * @max 100
   * @example 10
   */
  percentageOfInvestmentFrom?: number;
  /**
   * Procent do
   * @format double
   * @min 0
   * @max 100
   * @example 30
   */
  percentageOfInvestmentTo?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach od
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach do
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTimeTo?: number;
  /** Zabezpieczenie */
  investmentSecurity?: FilterEditInvestorDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: FilterEditInvestorDtoFormOfInvestmentEnum;
  /**
   * Id filtra do edycji
   * @format uuid
   * @example "187fbe74-5729-47ff-b7c8-769be5cc60bc"
   */
  filterId: string;
}

/** Lokalizacja */
export interface LocationDto {
  /**
   * @format int32
   * @example 14
   */
  voivodeshipNumber?: number;
  /**
   * @format int32
   * @example 19
   */
  countyNumber?: number;
  /**
   * @format int32
   * @example 15
   */
  communityNumber?: number;
  /**
   * @format int32
   * @example 4
   */
  kindNumber?: number;
  /**
   * @format int32
   * @example 1
   */
  subRegionNumber?: number;
  /**
   * @format int32
   * @example 969379
   */
  regionNumber?: number;
  /**
   * @format int32
   * @example 12548
   */
  streetNumber?: number;
  /**
   * Pełna nazwa
   * @example "Rybaki"
   */
  fullName?: string;
}

export enum PropertyConditionEnum {
  DoLekkichPoprawek = "Do lekkich poprawek",
  DoRemontu = "Do remontu",
  Surowy = "Surowy",
  NieDotyczy = "Nie dotyczy",
}

export interface FilterEditDealDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  /**
   * Prognozowany zysk od
   * @format double
   * @min 0
   * @example 1000
   */
  expectedProfitFrom?: number;
  /**
   * Prognozowany zysk do
   * @format double
   * @min 0
   * @example 3000
   */
  expectedProfitTo?: number;
  /**
   * Cena od
   * @format double
   * @min 0
   * @example 10000
   */
  priceFrom?: number;
  /**
   * Cena do
   * @format double
   * @min 0
   * @example 30000
   */
  priceTo?: number;
  /**
   * Nakład na remont od
   * @format double
   * @min 0
   * @example 2000
   */
  renovationExpensesFrom?: number;
  /**
   * Nakład na remont do
   * @format double
   * @min 0
   * @example 5000
   */
  renovationExpensesTo?: number;
  /**
   * Kwota do zrealizowania inwestycji od
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudgetFrom?: number;
  /**
   * Kwota do zrealizowania inwestycji do
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudgetTo?: number;
  /**
   * ROI od
   * @format double
   * @min 0
   * @example 10
   */
  roiFrom?: number;
  /**
   * ROI do
   * @format double
   * @min 0
   * @example 30
   */
  roiTo?: number;
  /**
   * Odstępne od
   * @format double
   * @min 0
   * @example 10
   */
  leaseFeeFrom?: number;
  /**
   * Odstępne do
   * @format double
   * @min 0
   * @example 40
   */
  leaseFeeTo?: number;
  buildingType?: BuildingTypeEnum[];
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorTo?: number;
  /**
   * Piętra od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 2
   */
  floorsFrom?: number;
  /**
   * Piętra do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 2
   */
  floorsTo?: number;
  /**
   * Powierzchnia od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia do
   * @format double
   * @min 0
   * @example 300
   */
  areaTo?: number;
  /**
   * Udogodnienia
   * @example ["Balkon"]
   */
  features?: FilterEditDealDtoFeaturesEnum;
  preContract?: BooleanEnum[];
  /**
   * Dostępne od
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Przewidywany przychód z najmu od
   * @format double
   * @min 0
   */
  rentalIncomeFrom?: number;
  /**
   * Przewidywany przychód z najmu do
   * @format double
   * @min 0
   */
  rentalIncomeTo?: number;
  propertyCondition?: PropertyConditionEnum[];
  /**
   * Stan prawny
   * @example ["Udział"]
   */
  legalStatus?: FilterEditDealDtoLegalStatusEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Id filtra do edycji
   * @format uuid
   * @example "187fbe74-5729-47ff-b7c8-769be5cc60bc"
   */
  filterId: string;
}

export interface FilterEditCapitalDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  /**
   * Minimalna kwota inwestycji od
   * @format double
   * @min 0
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji do
   * @format double
   * @min 0
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji od
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji do
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeTo?: number;
  /** Forma zysku */
  formOfProfit?: FilterEditCapitalDtoFormOfProfitEnum;
  /**
   * Minimalna kwota zysku od
   * @format double
   * @min 0
   * @example 10000
   */
  minimumProfitAmountFrom?: number;
  /**
   * Minimalna kwota zysku do
   * @format double
   * @min 0
   * @example 30000
   */
  minimumProfitAmountTo?: number;
  /**
   * Oczekiwana stopa zwrotu od
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @max 100
   * @example 20
   */
  expectedReturnRateFrom?: number;
  /**
   * Oczekiwana stopa zwrotu do
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @max 100
   * @example 20
   */
  expectedReturnRateTo?: number;
  /** Zabezpieczenia */
  investmentSecurity?: FilterEditCapitalDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: FilterEditCapitalDtoFormOfInvestmentEnum;
  /**
   * Id filtra do edycji
   * @format uuid
   * @example "187fbe74-5729-47ff-b7c8-769be5cc60bc"
   */
  filterId: string;
}

/**
 * Kosztorys
 * Pole typu opis
 */
export interface CostEstimateDto {
  /**
   * Nazwa pozycji
   * @example "Pozycja 1"
   */
  name?: string;
  /**
   * Wartość pozycji
   * @format double
   * @example 1000
   */
  price?: number;
}

export interface InvestorEditDto {
  city: LocationDto;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * Opis
   * @example "Opis testowy"
   */
  description: string;
  buildingType?: BuildingTypeEnum;
  /**
   * Stan prawny
   * Pojedynczy wybór
   * @example "Udział"
   */
  legalStatus?: InvestorEditDtoLegalStatusEnum;
  propertyCondition?: PropertyConditionEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: 0
   * @format int32
   * @min 0
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @min 0
   * @example 100
   */
  area?: number;
  /**
   * Podpisana umowa przedwstępna
   * @example true
   */
  preContract?: boolean;
  /**
   * Mam ekipę budowlaną
   * @example true
   */
  constructionCrew?: boolean;
  /**
   * Wkład własny
   * @format double
   * @min 0
   * @example 1000
   */
  ownContribution?: number;
  /**
   * Wkład inwestora
   * @format double
   * @min 0
   * @example 1000
   */
  investorContribution?: number;
  /**
   * Całkowita wartość inwestycji
   * @format double
   * @min 0
   * @example 400000
   */
  totalInvestmentValue?: number;
  /**
   * Minimalna cena sprzedaży
   * @format double
   * @min 0
   * @example 450000
   */
  minimumSalePrice?: number;
  /**
   * Maksymalna cena sprzedaży
   * @format double
   * @min 0
   * @example 500000
   */
  maximumSalePrice?: number;
  /**
   * Szacowana data sprzedaży
   * @format date-time
   */
  estimatedSaleDate?: string;
  /**
   * Kosztorys
   * Pole typu opis
   */
  costEstimate?: CostEstimateDto[];
  /**
   * Kwota inwestycji od
   * @format double
   * @min 0
   * @example 10000
   */
  investmentAmountFrom?: number;
  /**
   * Kwota inwestycji do
   * @format double
   * @min 0
   * @example 100000
   */
  investmentAmountTo?: number;
  /**
   * Procent
   * @format double
   * @min 0
   * @max 100
   * @example 10
   */
  percentageOfInvestment?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTime?: number;
  /** Finansowanie */
  funding?: string;
  /** Zabezpieczenie */
  investmentSecurity?: InvestorEditDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: InvestorEditDtoFormOfInvestmentEnum;
  /**
   * Szczegóły
   * @example "Szczegóły inwestycji"
   */
  details?: string;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Documents
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_5duTghDX.pdf"]
   */
  documents?: string[];
  /**
   * Id ogłoszenia do edycji
   * @format uuid
   */
  advertisementId: string;
  /** Czy ogłoszenie jest aktywne */
  activationStatus?: boolean;
}

export interface DealEditDto {
  city: LocationDto;
  /**
   * Prognozowany zysk
   * @format double
   * @min 0
   * @example 1000
   */
  expectedProfit?: number;
  /**
   * Cena
   * @format double
   * @min 0
   * @example 10000
   */
  price?: number;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * Opis
   * @example "Opis testowy"
   */
  description: string;
  /**
   * Nakład na remont
   * @format double
   * @min 0
   * @example 2000
   */
  renovationExpenses?: number;
  /**
   * Kwota do zrealizowania inwestycji
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudget?: number;
  /**
   * ROI
   * @format double
   * @min 0
   * @example 10
   */
  roi?: number;
  /**
   * Odstępne
   * @format double
   * @min 0
   * @example 10
   */
  leaseFee?: number;
  buildingType?: BuildingTypeEnum;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: 0
   * @format int32
   * @min 0
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @min 0
   * @example 100
   */
  area?: number;
  /**
   * Udogodnienia
   * Wielokrotny wybór
   * @example ["Balkon"]
   */
  features?: DealEditDtoFeaturesEnum;
  /**
   * Podpisana umowa przedwstępna
   * @example true
   */
  preContract?: boolean;
  /**
   * Dostępne od
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Przewidywany cel inwestycji
   * @example "Przewidywany cel inwestycji"
   */
  investmentGoal?: string;
  /**
   * Przewidywany przychód z najmu
   * @format double
   * @min 0
   */
  rentalIncome?: number;
  propertyCondition?: PropertyConditionEnum;
  /**
   * Stan prawny
   * Pojedynczy wybór
   * @example "Odrębna własność"
   */
  legalStatus?: DealEditDtoLegalStatusEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Documents
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_5duTghDX.pdf"]
   */
  documents?: string[];
  /**
   * Id ogłoszenia do edycji
   * @format uuid
   */
  advertisementId: string;
  /** Czy ogłoszenie jest aktywne */
  activationStatus?: boolean;
}

export interface CapitalEditDto {
  city: LocationDto;
  /** Tytuł ogłoszenia */
  title: string;
  /** Opis ogłoszenia */
  description: string;
  /**
   * Minimalna kwota inwestycji
   * @format double
   * @min 0
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji
   * @format double
   * @min 0
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeTo?: number;
  /**
   * Forma zysku
   * Pojedynczy wybór
   */
  formOfProfit?: CapitalEditDtoFormOfProfitEnum;
  /**
   * Minimalna kwota zysku
   * @format double
   * @min 0
   * @example 10000
   */
  minimumProfitAmount?: number;
  /**
   * Oczekiwana stopa zwrotu
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @example 20
   */
  expectedReturnRate?: number;
  /**
   * Zabezpieczenie
   * Wielokrotny wybór
   */
  investmentSecurity?: CapitalEditDtoInvestmentSecurityEnum;
  /**
   * Forma inwestycji
   * Wielokrotny wybór
   */
  formOfInvestment?: CapitalEditDtoFormOfInvestmentEnum;
  /**
   * Id ogłoszenia do edycji
   * @format uuid
   */
  advertisementId: string;
  /** Czy ogłoszenie jest aktywne */
  activationStatus?: boolean;
}

export interface SendMessage {
  /**
   * Id ogłoszenia
   * Id powiązanego ogłoszenia z wiadomością
   * @format uuid
   */
  advertisementId: string;
  /**
   * Kto wysyła wiadomość
   * Pole odpowiadające typowi użytkownika który wysłał wiadomość, jest właściciel ogłoszenia lub zainteresowany
   */
  messageSender?: SendMessageMessageSenderEnum;
  /**
   * Treść wiadomości
   * Pole nie może być puste
   */
  content: string;
  /**
   * Id pierwszej wiadomości
   * Jeżeli jest to pierwsza wiadomość pole powinno mieć wartość 0
   * @format uuid
   */
  firstMessageId: string;
}

export interface FilterInvestorDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  buildingType?: BuildingTypeEnum[];
  /** Stan prawny */
  legalStatus?: FilterInvestorDtoLegalStatusEnum;
  propertyCondition?: PropertyConditionEnum[];
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 5
   */
  floorTo?: number;
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorsFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 5
   */
  floorsTo?: number;
  /**
   * Powierzchnia od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  preContract?: BooleanEnum[];
  constructionCrew?: BooleanEnum[];
  /**
   * Wkład własny od
   * @format double
   * @min 0
   * @example 1000
   */
  ownContributionFrom?: number;
  /**
   * Wkład własny do
   * @format double
   * @min 0
   * @example 3000
   */
  ownContributionTo?: number;
  /**
   * Wkład inwestora od
   * @format double
   * @min 0
   * @example 1000
   */
  investorContributionFrom?: number;
  /**
   * Wkład inwestora do
   * @format double
   * @min 0
   * @example 1000
   */
  investorContributionTo?: number;
  /**
   * Całkowita wartość inwestycji od
   * @format double
   * @min 0
   * @example 400000
   */
  totalInvestmentValueFrom?: number;
  /**
   * Całkowita wartość inwestycji do
   * @format double
   * @min 0
   * @example 700000
   */
  totalInvestmentValueTo?: number;
  /**
   * Minimalna cena sprzedaży od
   * @format double
   * @min 0
   * @example 450000
   */
  minimumSalePrice?: number;
  /**
   * Maksymalna cena sprzedaży do
   * @format double
   * @min 0
   * @example 700000
   */
  maximumSalePrice?: number;
  /**
   * Szacowana data sprzedaży od
   * @format date-time
   */
  estimatedSaleDateFrom?: string;
  /**
   * Szacowana data sprzedaży do
   * @format date-time
   */
  estimatedSaleDateTo?: string;
  /**
   * Kwota inwestycji
   * Kwota inwestycji bierze dwa pola z ogłoszeń 'investmentAmountFrom' i 'investmentAmountTo'
   * @format double
   * @min 0
   * @example 10000
   */
  investmentAmountFrom?: number;
  /**
   * Kwota inwestycji
   * Kwota inwestycji bierze dwa pola z ogłoszeń 'investmentAmountFrom' i 'investmentAmountTo'
   * @format double
   * @example 500000
   */
  investmentAmountTo?: number;
  /**
   * Procent od
   * @format double
   * @min 0
   * @max 100
   * @example 10
   */
  percentageOfInvestmentFrom?: number;
  /**
   * Procent do
   * @format double
   * @min 0
   * @max 100
   * @example 30
   */
  percentageOfInvestmentTo?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach od
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach do
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTimeTo?: number;
  /** Zabezpieczenie */
  investmentSecurity?: FilterInvestorDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: FilterInvestorDtoFormOfInvestmentEnum;
}

export interface FilterDealDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  /**
   * Prognozowany zysk od
   * @format double
   * @min 0
   * @example 1000
   */
  expectedProfitFrom?: number;
  /**
   * Prognozowany zysk do
   * @format double
   * @min 0
   * @example 3000
   */
  expectedProfitTo?: number;
  /**
   * Cena od
   * @format double
   * @min 0
   * @example 10000
   */
  priceFrom?: number;
  /**
   * Cena do
   * @format double
   * @min 0
   * @example 30000
   */
  priceTo?: number;
  /**
   * Nakład na remont od
   * @format double
   * @min 0
   * @example 2000
   */
  renovationExpensesFrom?: number;
  /**
   * Nakład na remont do
   * @format double
   * @min 0
   * @example 5000
   */
  renovationExpensesTo?: number;
  /**
   * Kwota do zrealizowania inwestycji od
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudgetFrom?: number;
  /**
   * Kwota do zrealizowania inwestycji do
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudgetTo?: number;
  /**
   * ROI od
   * @format double
   * @min 0
   * @example 10
   */
  roiFrom?: number;
  /**
   * ROI do
   * @format double
   * @min 0
   * @example 30
   */
  roiTo?: number;
  /**
   * Odstępne od
   * @format double
   * @min 0
   * @example 10
   */
  leaseFeeFrom?: number;
  /**
   * Odstępne do
   * @format double
   * @min 0
   * @example 40
   */
  leaseFeeTo?: number;
  buildingType?: BuildingTypeEnum[];
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorTo?: number;
  /**
   * Piętra od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 2
   */
  floorsFrom?: number;
  /**
   * Piętra do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 2
   */
  floorsTo?: number;
  /**
   * Powierzchnia od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia do
   * @format double
   * @min 0
   * @example 300
   */
  areaTo?: number;
  /**
   * Udogodnienia
   * @example ["Balkon"]
   */
  features?: FilterDealDtoFeaturesEnum;
  preContract?: BooleanEnum[];
  /**
   * Dostępne od
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Przewidywany przychód z najmu od
   * @format double
   * @min 0
   */
  rentalIncomeFrom?: number;
  /**
   * Przewidywany przychód z najmu do
   * @format double
   * @min 0
   */
  rentalIncomeTo?: number;
  propertyCondition?: PropertyConditionEnum[];
  /**
   * Stan prawny
   * @example ["Udział"]
   */
  legalStatus?: FilterDealDtoLegalStatusEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
}

export interface FilterCapitalDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  /**
   * Minimalna kwota inwestycji od
   * @format double
   * @min 0
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji do
   * @format double
   * @min 0
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji od
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji do
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeTo?: number;
  /** Forma zysku */
  formOfProfit?: FilterCapitalDtoFormOfProfitEnum;
  /**
   * Minimalna kwota zysku od
   * @format double
   * @min 0
   * @example 10000
   */
  minimumProfitAmountFrom?: number;
  /**
   * Minimalna kwota zysku do
   * @format double
   * @min 0
   * @example 30000
   */
  minimumProfitAmountTo?: number;
  /**
   * Oczekiwana stopa zwrotu od
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @max 100
   * @example 20
   */
  expectedReturnRateFrom?: number;
  /**
   * Oczekiwana stopa zwrotu do
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @max 100
   * @example 20
   */
  expectedReturnRateTo?: number;
  /** Zabezpieczenia */
  investmentSecurity?: FilterCapitalDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: FilterCapitalDtoFormOfInvestmentEnum;
}

export interface FilterInvestorPageDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  buildingType?: BuildingTypeEnum[];
  /** Stan prawny */
  legalStatus?: FilterInvestorPageDtoLegalStatusEnum;
  propertyCondition?: PropertyConditionEnum[];
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 5
   */
  floorTo?: number;
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorsFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 5
   */
  floorsTo?: number;
  /**
   * Powierzchnia od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  preContract?: BooleanEnum[];
  constructionCrew?: BooleanEnum[];
  /**
   * Wkład własny od
   * @format double
   * @min 0
   * @example 1000
   */
  ownContributionFrom?: number;
  /**
   * Wkład własny do
   * @format double
   * @min 0
   * @example 3000
   */
  ownContributionTo?: number;
  /**
   * Wkład inwestora od
   * @format double
   * @min 0
   * @example 1000
   */
  investorContributionFrom?: number;
  /**
   * Wkład inwestora do
   * @format double
   * @min 0
   * @example 1000
   */
  investorContributionTo?: number;
  /**
   * Całkowita wartość inwestycji od
   * @format double
   * @min 0
   * @example 400000
   */
  totalInvestmentValueFrom?: number;
  /**
   * Całkowita wartość inwestycji do
   * @format double
   * @min 0
   * @example 700000
   */
  totalInvestmentValueTo?: number;
  /**
   * Minimalna cena sprzedaży od
   * @format double
   * @min 0
   * @example 450000
   */
  minimumSalePrice?: number;
  /**
   * Maksymalna cena sprzedaży do
   * @format double
   * @min 0
   * @example 700000
   */
  maximumSalePrice?: number;
  /**
   * Szacowana data sprzedaży od
   * @format date-time
   */
  estimatedSaleDateFrom?: string;
  /**
   * Szacowana data sprzedaży do
   * @format date-time
   */
  estimatedSaleDateTo?: string;
  /**
   * Kwota inwestycji
   * Kwota inwestycji bierze dwa pola z ogłoszeń 'investmentAmountFrom' i 'investmentAmountTo'
   * @format double
   * @min 0
   * @example 10000
   */
  investmentAmountFrom?: number;
  /**
   * Kwota inwestycji
   * Kwota inwestycji bierze dwa pola z ogłoszeń 'investmentAmountFrom' i 'investmentAmountTo'
   * @format double
   * @example 500000
   */
  investmentAmountTo?: number;
  /**
   * Procent od
   * @format double
   * @min 0
   * @max 100
   * @example 10
   */
  percentageOfInvestmentFrom?: number;
  /**
   * Procent do
   * @format double
   * @min 0
   * @max 100
   * @example 30
   */
  percentageOfInvestmentTo?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach od
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach do
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTimeTo?: number;
  /** Zabezpieczenie */
  investmentSecurity?: FilterInvestorPageDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: FilterInvestorPageDtoFormOfInvestmentEnum;
  /**
   * Sortowanie
   * @default "DATE_DESC"
   * @example "DATE_DESC"
   */
  sortType: FilterInvestorPageDtoSortTypeEnum;
}

export interface InvestorBoxDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /** Lokalizacja */
  location: string;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * Wkład inwestora
   * @format double
   * @example 1000
   */
  investorContribution?: number;
  /**
   * Rodzaj nieruchomości
   * Pojedynczy wybór
   * @example "Lokal"
   */
  buildingType?: InvestorBoxDtoBuildingTypeEnum;
  /**
   * Stan prawny
   * Pojedynczy wybór
   * @example "Udział"
   */
  legalStatus?: InvestorBoxDtoLegalStatusEnum;
  /**
   * Stan nieruchomości
   * Pojedynczy wybór
   * @example "Surowy"
   */
  propertyCondition?: InvestorBoxDtoPropertyConditionEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: 0
   * @format int32
   * @min 0
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @min 0
   * @example 100
   */
  area?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTime?: number;
  /**
   * Całkowita wartość inwestycji
   * @format double
   * @example 400000
   */
  totalInvestmentValue?: number;
  /**
   * Procent
   * @format double
   * @example 10
   */
  percentageOfInvestment?: number;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Data dodania
   * @format date-time
   */
  dateAdded: string;
  /**
   * Id profilu
   * @format uuid
   */
  profileId: string;
  /** Nazwa profilu */
  username: string;
  /** Zdjęcie profilu */
  userPhoto?: string;
  /** Moduł */
  module?: InvestorBoxDtoModuleEnum;
  /** Numer telefonu */
  phoneNumber: string;
  reactionDto: ReactionDto;
  note?: NoteDto;
  /**
   * Liczba notatek
   * @format int32
   */
  numberOfNotes: number;
  messageDetail?: MessageDetailAd;
}

export interface InvestorBoxDtoPage {
  content?: InvestorBoxDto[];
  pageable?: PageableObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
  last?: boolean;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  empty?: boolean;
}

export interface MessageDetail {
  /**
   * Id pojedynczej wiadomości
   * @format uuid
   */
  messageId: string;
  /**
   * Zawartość wiadomości
   * @example "Wiadomość testowa"
   */
  content: string;
  /**
   * Data wysłania
   * @format date-time
   */
  sendDate: string;
  /**
   * Data odczytania
   * null w przypadku braku przeczytania
   * @format date-time
   */
  readTime?: string;
  /**
   * Kto wysłał
   * @example "AD_OWNER"
   */
  messageSender: MessageDetailMessageSenderEnum;
}

/** Wiadomości */
export interface MessageDetailAd {
  adOwner?: boolean;
  /** @format int64 */
  numberOfNewMessages?: number;
  messageDetails?: MessageDetail[];
}

/** Notatka */
export interface NoteDto {
  /**
   * Notatka
   * @format uuid
   */
  noteId: string;
  /** Treść */
  content: string;
  /**
   * Data
   * @format date-time
   */
  date: string;
}

export interface PageableObject {
  /** @format int32 */
  pageNumber?: number;
  /** @format int32 */
  pageSize?: number;
  /** @format int64 */
  offset?: number;
  sort?: SortObject;
  paged?: boolean;
  unpaged?: boolean;
}

/** Reakcja */
export interface ReactionDto {
  /** Like Status */
  likeStatus?: boolean;
  /** Unlike Status */
  unlikeStatus?: boolean;
  /** Viewed Status */
  viewedStatus?: boolean;
  /** Outdated Status */
  outdatedStatus?: boolean;
  /** Arranged Status */
  arrangedStatus?: boolean;
  /** Phone Status */
  phoneStatus?: boolean;
  /** Hide Status */
  openedStatus?: boolean;
  /** Hide Status */
  hideStatus?: boolean;
}

export interface SortObject {
  sorted?: boolean;
  empty?: boolean;
  unsorted?: boolean;
}

export interface FilterDealPageDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  /**
   * Prognozowany zysk od
   * @format double
   * @min 0
   * @example 1000
   */
  expectedProfitFrom?: number;
  /**
   * Prognozowany zysk do
   * @format double
   * @min 0
   * @example 3000
   */
  expectedProfitTo?: number;
  /**
   * Cena od
   * @format double
   * @min 0
   * @example 10000
   */
  priceFrom?: number;
  /**
   * Cena do
   * @format double
   * @min 0
   * @example 30000
   */
  priceTo?: number;
  /**
   * Nakład na remont od
   * @format double
   * @min 0
   * @example 2000
   */
  renovationExpensesFrom?: number;
  /**
   * Nakład na remont do
   * @format double
   * @min 0
   * @example 5000
   */
  renovationExpensesTo?: number;
  /**
   * Kwota do zrealizowania inwestycji od
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudgetFrom?: number;
  /**
   * Kwota do zrealizowania inwestycji do
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudgetTo?: number;
  /**
   * ROI od
   * @format double
   * @min 0
   * @example 10
   */
  roiFrom?: number;
  /**
   * ROI do
   * @format double
   * @min 0
   * @example 30
   */
  roiTo?: number;
  /**
   * Odstępne od
   * @format double
   * @min 0
   * @example 10
   */
  leaseFeeFrom?: number;
  /**
   * Odstępne do
   * @format double
   * @min 0
   * @example 40
   */
  leaseFeeTo?: number;
  buildingType?: BuildingTypeEnum[];
  /**
   * Piętro od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floorTo?: number;
  /**
   * Piętra od
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 2
   */
  floorsFrom?: number;
  /**
   * Piętra do
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 2
   */
  floorsTo?: number;
  /**
   * Powierzchnia od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia do
   * @format double
   * @min 0
   * @example 300
   */
  areaTo?: number;
  /**
   * Udogodnienia
   * @example ["Balkon"]
   */
  features?: FilterDealPageDtoFeaturesEnum;
  preContract?: BooleanEnum[];
  /**
   * Dostępne od
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Przewidywany przychód z najmu od
   * @format double
   * @min 0
   */
  rentalIncomeFrom?: number;
  /**
   * Przewidywany przychód z najmu do
   * @format double
   * @min 0
   */
  rentalIncomeTo?: number;
  propertyCondition?: PropertyConditionEnum[];
  /**
   * Stan prawny
   * @example ["Udział"]
   */
  legalStatus?: FilterDealPageDtoLegalStatusEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Sortowanie
   * @default "DATE_DESC"
   * @example "DATE_DESC"
   */
  sortType: FilterDealPageDtoSortTypeEnum;
}

export interface DealBoxDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /**
   * Lokalizacja
   * @example "Warszawa"
   */
  location: string;
  /**
   * Prognozowany zysk
   * @format double
   * @example 1000
   */
  expectedProfit?: number;
  /**
   * Cena
   * @format double
   * @example 10000
   */
  price?: number;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * ROI
   * @format double
   * @example 10
   */
  roi?: number;
  /**
   * Rodzaj nieruchomości
   * @example "Lokal"
   */
  buildingType?: DealBoxDtoBuildingTypeEnum;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Odstępne
   * @format double
   * @example 10
   */
  leaseFee?: number;
  /**
   * Stan nieruchomości
   * @example "Do lekkich poprawek"
   */
  propertyCondition?: DealBoxDtoPropertyConditionEnum;
  /**
   * Stan prawny
   * @example "Odrębna własność"
   */
  legalStatus?: DealBoxDtoLegalStatusEnum;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Data dodania
   * @format date-time
   */
  dateAdded: string;
  /**
   * Id profilu
   * @format uuid
   */
  profileId: string;
  /** Nazwa profilu */
  username: string;
  /** Zdjęcie użytkownika */
  userPhoto?: string;
  /** Moduł */
  module?: DealBoxDtoModuleEnum;
  /** Numer telefonu */
  phoneNumber: string;
  reactionDto: ReactionDto;
  note?: NoteDto;
  /**
   * Liczba notatek
   * @format int32
   */
  numberOfNotes: number;
  messageDetail?: MessageDetailAd;
}

export interface DealBoxDtoPage {
  content?: DealBoxDto[];
  pageable?: PageableObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
  last?: boolean;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  empty?: boolean;
}

export interface FilterCapitalPageDto {
  /** Lokalizacja */
  city?: LocationDto[];
  /**
   * Zerowe wartości
   * @example true
   */
  includeZeroArea?: boolean;
  /**
   * Pożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["inwestycja"]
   */
  wantedKeywords?: string[];
  /**
   * Niepożądane słowa
   * Maksymalna liczba znaków wynosi 2000
   * @example ["nowa"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["123456789"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Niepożądane numery telefonów
   * Maksymalna liczba znaków wynosi 2000
   * @example ["987654321"]
   */
  unwantedPhoneNumbers?: string[];
  /**
   * Data dodania od
   * @format date-time
   */
  dateAddedFrom?: string;
  /**
   * Data dodania do
   * @format date-time
   */
  dateAddedTo?: string;
  /**
   * Dni
   * @format int32
   * @example 1
   */
  daysAmount?: number;
  /**
   * Nazwa
   * @example "Testowa nazwa"
   */
  name?: string;
  /**
   * Powiadomienie Sms
   * @example true
   */
  alertSms?: boolean;
  /**
   * Powiadomienie Email
   * @example true
   */
  alertEmail?: boolean;
  /**
   * Powiadomienie Push
   * @example false
   */
  pushAlert?: boolean;
  /**
   * Minimalna kwota inwestycji od
   * @format double
   * @min 0
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji do
   * @format double
   * @min 0
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji od
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji do
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeTo?: number;
  /** Forma zysku */
  formOfProfit?: FilterCapitalPageDtoFormOfProfitEnum;
  /**
   * Minimalna kwota zysku od
   * @format double
   * @min 0
   * @example 10000
   */
  minimumProfitAmountFrom?: number;
  /**
   * Minimalna kwota zysku do
   * @format double
   * @min 0
   * @example 30000
   */
  minimumProfitAmountTo?: number;
  /**
   * Oczekiwana stopa zwrotu od
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @max 100
   * @example 20
   */
  expectedReturnRateFrom?: number;
  /**
   * Oczekiwana stopa zwrotu do
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @max 100
   * @example 20
   */
  expectedReturnRateTo?: number;
  /** Zabezpieczenia */
  investmentSecurity?: FilterCapitalPageDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: FilterCapitalPageDtoFormOfInvestmentEnum;
  /**
   * Sortowanie
   * @default "DATE_DESC"
   * @example "DATE_DESC"
   */
  sortType: FilterCapitalPageDtoSortTypeEnum;
}

export interface CapitalBoxDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /**
   * Lokalizacja
   * @example "Warszawa"
   */
  location: string;
  /**
   * Tytuł ogłoszenia
   * @example "Title"
   */
  title: string;
  /**
   * Minimalna kwota inwestycji
   * @format double
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji
   * @format double
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji od
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji do
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 5
   */
  investmentTimeTo?: number;
  /**
   * Minimalna kwota zysku
   * @format double
   * @example 10000
   */
  minimumProfitAmount?: number;
  /**
   * Oczekiwana stopa zwrotu
   * Stopa zwrotu w procentach
   * @format double
   * @example 20
   */
  expectedReturnRate?: number;
  /**
   * Data dodania
   * @format date-time
   */
  dateAdded: string;
  /**
   * Id profilu
   * @format uuid
   */
  profileId: string;
  /** Nazwa użytkownika */
  username: string;
  /** Zdjęcie użytkownika */
  userPhoto?: string;
  /** Moduł */
  module?: CapitalBoxDtoModuleEnum;
  /** Numer telefonu */
  phoneNumber: string;
  reactionDto: ReactionDto;
  note?: NoteDto;
  /**
   * Liczba notatek
   * @format int32
   */
  numberOfNotes: number;
  messageDetail?: MessageDetailAd;
}

export interface CapitalBoxDtoPage {
  content?: CapitalBoxDto[];
  pageable?: PageableObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
  last?: boolean;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  empty?: boolean;
}

export interface InvestorBaseDto {
  city: LocationDto;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * Opis
   * @example "Opis testowy"
   */
  description: string;
  buildingType?: BuildingTypeEnum;
  /**
   * Stan prawny
   * Pojedynczy wybór
   * @example "Udział"
   */
  legalStatus?: InvestorBaseDtoLegalStatusEnum;
  propertyCondition?: PropertyConditionEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: 0
   * @format int32
   * @min 0
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @min 0
   * @example 100
   */
  area?: number;
  /**
   * Podpisana umowa przedwstępna
   * @example true
   */
  preContract?: boolean;
  /**
   * Mam ekipę budowlaną
   * @example true
   */
  constructionCrew?: boolean;
  /**
   * Wkład własny
   * @format double
   * @min 0
   * @example 1000
   */
  ownContribution?: number;
  /**
   * Wkład inwestora
   * @format double
   * @min 0
   * @example 1000
   */
  investorContribution?: number;
  /**
   * Całkowita wartość inwestycji
   * @format double
   * @min 0
   * @example 400000
   */
  totalInvestmentValue?: number;
  /**
   * Minimalna cena sprzedaży
   * @format double
   * @min 0
   * @example 450000
   */
  minimumSalePrice?: number;
  /**
   * Maksymalna cena sprzedaży
   * @format double
   * @min 0
   * @example 500000
   */
  maximumSalePrice?: number;
  /**
   * Szacowana data sprzedaży
   * @format date-time
   */
  estimatedSaleDate?: string;
  /**
   * Kosztorys
   * Pole typu opis
   */
  costEstimate?: CostEstimateDto[];
  /**
   * Kwota inwestycji od
   * @format double
   * @min 0
   * @example 10000
   */
  investmentAmountFrom?: number;
  /**
   * Kwota inwestycji do
   * @format double
   * @min 0
   * @example 100000
   */
  investmentAmountTo?: number;
  /**
   * Procent
   * @format double
   * @min 0
   * @max 100
   * @example 10
   */
  percentageOfInvestment?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTime?: number;
  /** Finansowanie */
  funding?: string;
  /** Zabezpieczenie */
  investmentSecurity?: InvestorBaseDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: InvestorBaseDtoFormOfInvestmentEnum;
  /**
   * Szczegóły
   * @example "Szczegóły inwestycji"
   */
  details?: string;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Documents
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_5duTghDX.pdf"]
   */
  documents?: string[];
}

export interface DealBaseDto {
  city: LocationDto;
  /**
   * Prognozowany zysk
   * @format double
   * @min 0
   * @example 1000
   */
  expectedProfit?: number;
  /**
   * Cena
   * @format double
   * @min 0
   * @example 10000
   */
  price?: number;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * Opis
   * @example "Opis testowy"
   */
  description: string;
  /**
   * Nakład na remont
   * @format double
   * @min 0
   * @example 2000
   */
  renovationExpenses?: number;
  /**
   * Kwota do zrealizowania inwestycji
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudget?: number;
  /**
   * ROI
   * @format double
   * @min 0
   * @example 10
   */
  roi?: number;
  /**
   * Odstępne
   * @format double
   * @min 0
   * @example 10
   */
  leaseFee?: number;
  buildingType?: BuildingTypeEnum;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: 0
   * @format int32
   * @min 0
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @min 0
   * @example 100
   */
  area?: number;
  /**
   * Udogodnienia
   * Wielokrotny wybór
   * @example ["Balkon"]
   */
  features?: DealBaseDtoFeaturesEnum;
  /**
   * Podpisana umowa przedwstępna
   * @example true
   */
  preContract?: boolean;
  /**
   * Dostępne od
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Przewidywany cel inwestycji
   * @example "Przewidywany cel inwestycji"
   */
  investmentGoal?: string;
  /**
   * Przewidywany przychód z najmu
   * @format double
   * @min 0
   */
  rentalIncome?: number;
  propertyCondition?: PropertyConditionEnum;
  /**
   * Stan prawny
   * Pojedynczy wybór
   * @example "Odrębna własność"
   */
  legalStatus?: DealBaseDtoLegalStatusEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Documents
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_5duTghDX.pdf"]
   */
  documents?: string[];
}

export interface CapitalBaseDto {
  city: LocationDto;
  /** Tytuł ogłoszenia */
  title: string;
  /** Opis ogłoszenia */
  description: string;
  /**
   * Minimalna kwota inwestycji
   * @format double
   * @min 0
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji
   * @format double
   * @min 0
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @min 3
   * @max 12
   * @example 3
   */
  investmentTimeTo?: number;
  /**
   * Forma zysku
   * Pojedynczy wybór
   */
  formOfProfit?: CapitalBaseDtoFormOfProfitEnum;
  /**
   * Minimalna kwota zysku
   * @format double
   * @min 0
   * @example 10000
   */
  minimumProfitAmount?: number;
  /**
   * Oczekiwana stopa zwrotu
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @example 20
   */
  expectedReturnRate?: number;
  /**
   * Zabezpieczenie
   * Wielokrotny wybór
   */
  investmentSecurity?: CapitalBaseDtoInvestmentSecurityEnum;
  /**
   * Forma inwestycji
   * Wielokrotny wybór
   */
  formOfInvestment?: CapitalBaseDtoFormOfInvestmentEnum;
}

export interface BoxInfoDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * Data dodania
   * @format date-time
   */
  dateAdded: string;
  /**
   * Moduł
   * @example "USER_OFFER_CAPITAL"
   */
  module: BoxInfoDtoModuleEnum;
  /**
   * Lokalizacja
   * @example "Warszawa"
   */
  location: string;
  /**
   * Minimalna kwota inwestycji
   * @format double
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji
   * @format double
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 3
   */
  investmentTime?: number;
  /**
   * Wkład inwestora
   * @format double
   * @example 1000
   */
  investorContribution?: number;
  /**
   * Czas trwania inwestycji od
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji do
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 5
   */
  investmentTimeTo?: number;
  /**
   * Minimalna kwota zysku
   * @format double
   * @example 10000
   */
  minimumProfitAmount?: number;
  /**
   * Oczekiwana stopa zwrotu
   * Stopa zwrotu w procentach
   * @format double
   * @example 20
   */
  expectedReturnRate?: number;
  /**
   * Prognozowany zysk
   * @format double
   * @example 1000
   */
  expectedProfit?: number;
  /**
   * Cena
   * @format double
   * @example 10000
   */
  price?: number;
  /**
   * ROI
   * @format double
   * @example 10
   */
  roi?: number;
  /**
   * Rodzaj nieruchomości
   * @example "Lokal"
   */
  buildingType?: BoxInfoDtoBuildingTypeEnum;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Stan nieruchomości
   * @example "Do lekkich poprawek"
   */
  propertyCondition?: BoxInfoDtoPropertyConditionEnum;
  /**
   * Odstępne
   * @format double
   * @example 10
   */
  leaseFee?: number;
  /**
   * Stan prawny
   * @example "Odrębna własność"
   */
  legalStatus?: BoxInfoDtoLegalStatusEnum;
  landRegistryNumber?: string;
  /**
   * Całkowita wartość inwestycji
   * @format double
   * @example 400000
   */
  totalInvestmentValue?: number;
  /**
   * Procent
   * @format double
   * @example 10
   */
  percentageOfInvestment?: number;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  reactionDto: ReactionDto;
  note: NoteDto;
  /**
   * Liczba notatek
   * @format int32
   */
  numberOfNotes: number;
  messageDetail?: MessageDetailAd;
  /**
   * Id profilu
   * @format uuid
   */
  profileId: string;
  /** Nazwa użytkownika */
  username: string;
  /** Numer telefonu */
  phoneNumber: string;
  /** Email */
  email: string;
  /** Opis */
  description?: string;
  /** Zdjęcie */
  userPhoto?: string;
}

export interface FolderMessage {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /** Tytuł */
  title?: string;
  /**
   * Cena
   * @format double
   */
  price?: number;
  /** Zdjęcie główne */
  mainPhoto?: string;
  /** Moduł */
  module: FolderMessageModuleEnum;
  /** Lista wiadomości do danego ogłoszenia */
  messages?: LastMessage[];
}

export interface LastMessage {
  /**
   * Id ostatniej wiadomości
   * @format uuid
   */
  lastMessageId: string;
  /** Imię i nazwisko */
  name?: string;
  /** Zdjęcie */
  photo?: string;
  /** Treść ostatniej wiadomości */
  content?: string;
  /**
   * Data wysłania wiadomości
   * @format date-time
   */
  sendDate?: string;
  /** Kto wysłał */
  messageSender?: LastMessageMessageSenderEnum;
  /**
   * Czy to nowa wiadomość
   * Wartość true jeżeli wiadomość przyjdzie od innego użytkownika
   */
  newMessage?: boolean;
}

export interface LastMessageBuy {
  /**
   * Id ostatniej wiadomości
   * @format int64
   */
  lastMessageId: number;
  /** Tytuł ogłoszenia */
  title?: string;
  /**
   * Cena
   * @format double
   */
  price?: number;
  /** Zdjęcie główne */
  mainPhoto?: string;
  /** Imię i nazwisko użytkownika */
  name?: string;
  /** Treść ostatniej wiadomości */
  content?: string;
  /**
   * Data wysłania
   * @format date-time
   */
  sendDate?: string;
  /** Kto wysłał */
  messageSender?: LastMessageBuyMessageSenderEnum;
  /** Moduł */
  module?: LastMessageBuyModuleEnum;
  /**
   * Czy to nowa wiadomość
   * Wartość true jeżeli wiadomość przyjdzie od innego użytkownika
   */
  newMessage?: boolean;
}

export interface FilterList {
  /**
   * Id filtra
   * @format uuid
   */
  filterId?: string;
  /** Nazwa filtra */
  name?: string;
  /** Moduł */
  module: FilterListModuleEnum;
}

export interface BoxEditDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title: string;
  /**
   * Data dodania
   * @format date-time
   */
  dateAdded: string;
  /**
   * Moduł
   * @example "USER_OFFER_CAPITAL"
   */
  module: BoxEditDtoModuleEnum;
  /**
   * Lokalizacja
   * @example "Warszawa"
   */
  location: string;
  /**
   * Minimalna kwota inwestycji
   * @format double
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji
   * @format double
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Czas trwania inwestycji
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 3
   */
  investmentTime?: number;
  /**
   * Wkład inwestora
   * @format double
   * @example 1000
   */
  investorContribution?: number;
  /**
   * Czas trwania inwestycji od
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 3
   */
  investmentTimeFrom?: number;
  /**
   * Czas trwania inwestycji do
   * Czas w miesiącach od 3 do 12
   * @format int32
   * @example 5
   */
  investmentTimeTo?: number;
  /**
   * Minimalna kwota zysku
   * @format double
   * @example 10000
   */
  minimumProfitAmount?: number;
  /**
   * Oczekiwana stopa zwrotu
   * Stopa zwrotu w procentach
   * @format double
   * @example 20
   */
  expectedReturnRate?: number;
  /**
   * Prognozowany zysk
   * @format double
   * @example 1000
   */
  expectedProfit?: number;
  /**
   * Cena
   * @format double
   * @example 10000
   */
  price?: number;
  /**
   * ROI
   * @format double
   * @example 10
   */
  roi?: number;
  /**
   * Rodzaj nieruchomości
   * @example "Lokal"
   */
  buildingType?: BoxEditDtoBuildingTypeEnum;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @example 100
   */
  area?: number;
  /**
   * Stan nieruchomości
   * @example "Do lekkich poprawek"
   */
  propertyCondition?: BoxEditDtoPropertyConditionEnum;
  /**
   * Odstępne
   * @format double
   * @example 10
   */
  leaseFee?: number;
  /**
   * Stan prawny
   * @example "Odrębna własność"
   */
  legalStatus?: BoxEditDtoLegalStatusEnum;
  landRegistryNumber?: string;
  /**
   * Całkowita wartość inwestycji
   * @format double
   * @example 400000
   */
  totalInvestmentValue?: number;
  /**
   * Procent
   * @format double
   * @example 10
   */
  percentageOfInvestment?: number;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Like Status
   * @format int32
   * @example 1
   */
  likeStatus: number;
  /**
   * Unlike Status
   * @format int32
   * @example 1
   */
  unlikeStatus: number;
  /**
   * Viewed Status
   * @format int32
   * @example 1
   */
  viewedStatus: number;
  /**
   * Outdated Status
   * @format int32
   * @example 1
   */
  outdatedStatus: number;
  /**
   * Arranged Status
   * @format int32
   * @example 1
   */
  arrangedStatus: number;
  /**
   * Phone Status
   * @format int32
   * @example 1
   */
  phoneStatus: number;
  /**
   * Opened Status
   * @format int32
   * @example 1
   */
  openedStatus: number;
  /**
   * Hide Status
   * @format int32
   * @example 1
   */
  hideStatus: number;
  /** Status ogłoszenia */
  activationStatus?: boolean;
}

export interface BoxEditDtoPage {
  content?: BoxEditDto[];
  pageable?: PageableObject;
  /** @format int64 */
  totalElements?: number;
  /** @format int32 */
  totalPages?: number;
  last?: boolean;
  /** @format int32 */
  size?: number;
  /** @format int32 */
  number?: number;
  sort?: SortObject;
  /** @format int32 */
  numberOfElements?: number;
  first?: boolean;
  empty?: boolean;
}

export interface InvestorDetailDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /** Lokalizacja */
  location?: string;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title?: string;
  /**
   * Opis
   * @example "Opis testowy"
   */
  description?: string;
  /**
   * Rodzaj nieruchomości
   * Pojedynczy wybór
   * @example "Lokal"
   */
  buildingType?: InvestorDetailDtoBuildingTypeEnum;
  /**
   * Stan prawny
   * Pojedynczy wybór
   * @example "Udział"
   */
  legalStatus?: InvestorDetailDtoLegalStatusEnum;
  /**
   * Stan nieruchomości
   * Pojedynczy wybór
   * @example "Surowy"
   */
  propertyCondition?: InvestorDetailDtoPropertyConditionEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: 0
   * @format int32
   * @min 0
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @min 0
   * @example 100
   */
  area?: number;
  /**
   * Podpisana umowa przedwstępna
   * @example true
   */
  preContract: boolean;
  /**
   * Mam ekipę budowlaną
   * @example true
   */
  constructionCrew: boolean;
  /**
   * Wkład własny
   * @format double
   * @min 0
   * @example 1000
   */
  ownContribution?: number;
  /**
   * Wkład inwestora
   * @format double
   * @min 0
   * @example 1000
   */
  investorContribution?: number;
  /**
   * Całkowita wartość inwestycji
   * @format double
   * @min 0
   * @example 400000
   */
  totalInvestmentValue?: number;
  /**
   * Minimalna cena sprzedaży
   * @format double
   * @min 0
   * @example 450000
   */
  minimumSalePrice?: number;
  /**
   * Maksymalna cena sprzedaży
   * @format double
   * @min 0
   * @example 500000
   */
  maximumSalePrice?: number;
  /**
   * Szacowana data sprzedaży
   * @format date-time
   */
  estimatedSaleDate?: string;
  /**
   * Kosztorys
   * Pole typu opis
   */
  costEstimate?: CostEstimateDto[];
  /**
   * Kwota inwestycji od
   * @format double
   * @min 0
   * @example 10000
   */
  investmentAmountFrom?: number;
  /**
   * Kwota inwestycji do
   * @format double
   * @min 0
   * @example 100000
   */
  investmentAmountTo?: number;
  /**
   * Procent
   * @format double
   * @min 0
   * @max 100
   * @example 10
   */
  percentageOfInvestment?: number;
  /**
   * Czas inwestycji
   * Czas inwestycji w miesiącach
   * @format int32
   * @min 0
   * @example 3
   */
  investmentTime?: number;
  /** Finansowanie */
  funding?: string;
  /** Zabezpieczenie */
  investmentSecurity?: InvestorDetailDtoInvestmentSecurityEnum;
  /** Forma inwestycji */
  formOfInvestment?: InvestorDetailDtoFormOfInvestmentEnum;
  /**
   * Szczegóły
   * @example "Szczegóły inwestycji"
   */
  details?: string;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Documents
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_5duTghDX.pdf"]
   */
  documents?: string[];
  reactionDto?: ReactionDto;
  /** Notatka */
  note: NoteDto[];
  /**
   * Liczba notatek
   * @format int32
   */
  numberOfNotes?: number;
  /**
   * Id profilu
   * @format uuid
   */
  profileId: string;
  /** Nazwa użytkownika */
  username: string;
  /** Numer telefonu */
  phoneNumber: string;
  /** Email */
  email?: string;
}

export interface DealDetailDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /** Lokalizacja */
  location?: string;
  /**
   * Prognozowany zysk
   * @format double
   * @min 0
   * @example 1000
   */
  expectedProfit?: number;
  /**
   * Cena
   * @format double
   * @min 0
   * @example 10000
   */
  price?: number;
  /**
   * Tytuł
   * @example "Tytuł"
   */
  title?: string;
  /**
   * Opis
   * @example "Opis testowy"
   */
  description?: string;
  /**
   * Nakład na remont
   * @format double
   * @min 0
   * @example 2000
   */
  renovationExpenses?: number;
  /**
   * Kwota do zrealizowania inwestycji
   * @format double
   * @min 0
   * @example 1000
   */
  investmentBudget?: number;
  /**
   * ROI
   * @format double
   * @min 0
   * @example 10
   */
  roi?: number;
  /**
   * Odstępne
   * @format double
   * @min 0
   * @example 10
   */
  leaseFee?: number;
  /**
   * Rodzaj nieruchomości
   * Pojedynczy wybór
   * @example "Lokal"
   */
  buildingType?: DealDetailDtoBuildingTypeEnum;
  /**
   * Piętro
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min -1
   * @example 2
   */
  floor?: number;
  /**
   * Piętra
   * Najmniejsza dostępna wartość: -1
   * @format int32
   * @min 0
   * @example 2
   */
  floors?: number;
  /**
   * Powierzchnia
   * @format double
   * @min 0
   * @example 100
   */
  area?: number;
  /**
   * Udogodnienia
   * Wielokrotny wybór
   * @example ["Balkon"]
   */
  features?: DealDetailDtoFeaturesEnum;
  /**
   * Podpisana umowa przedwstępna
   * @example true
   */
  preContract: boolean;
  /**
   * Dostępne od
   * @format date-time
   */
  availableFrom?: string;
  /**
   * Przewidywany cel inwestycji
   * @example "Przewidywany cel inwestycji"
   */
  investmentGoal?: string;
  /**
   * Przewidywany przychód z najmu
   * @format double
   * @min 0
   */
  rentalIncome?: number;
  /**
   * Stan nieruchomości
   * Pojedynczy wybór
   * @example "Do lekkich poprawek"
   */
  propertyCondition?: DealDetailDtoPropertyConditionEnum;
  /**
   * Stan prawny
   * Pojedynczy wybór
   * @example "Odrębna własność"
   */
  legalStatus?: DealDetailDtoLegalStatusEnum;
  /**
   * Numer księgi wieczystej
   * @example "OL10/00000123/9"
   */
  landRegistryNumber?: string;
  /**
   * Zdjęcia
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_7HuTRhlV.webp"]
   */
  photos?: string[];
  /**
   * Documents
   * Nazwy utworzonych wcześniej zdjęć
   * @example ["20231120132743_5duTghDX.pdf"]
   */
  documents?: string[];
  reactionDto?: ReactionDto;
  /** Notatka */
  note: NoteDto[];
  /**
   * Liczba notatek
   * @format int32
   */
  numberOfNotes?: number;
  /**
   * Id profilu
   * @format uuid
   */
  profileId: string;
  /** Nazwa użytkownika */
  username: string;
  /** Numer telefonu */
  phoneNumber: string;
  /** Email */
  email?: string;
}

export interface CapitalDetailDto {
  /**
   * Id ogłoszenia
   * @format uuid
   */
  advertisementId: string;
  /** Lokalizacja */
  location?: string;
  /** Tytuł ogłoszenia */
  title?: string;
  /** Opis ogłoszenia */
  description?: string;
  /**
   * Minimalna kwota inwestycji
   * @format double
   * @min 0
   * @example 10000
   */
  minimumInvestmentValue?: number;
  /**
   * Maksymalna kwota inwestycji
   * @format double
   * @min 0
   * @example 200000
   */
  maximumInvestmentValue?: number;
  /**
   * Forma zysku
   * Pojedynczy wybór
   */
  formOfProfit?: CapitalDetailDtoFormOfProfitEnum;
  /**
   * Minimalna kwota zysku
   * @format double
   * @min 0
   * @example 10000
   */
  minimumProfitAmount?: number;
  /**
   * Oczekiwana stopa zwrotu
   * Stopa zwrotu w procentach
   * @format double
   * @min 0
   * @example 20
   */
  expectedReturnRate?: number;
  /**
   * Zabezpieczenie
   * Pojedynczy wybór
   */
  investmentSecurity?: CapitalDetailDtoInvestmentSecurityEnum;
  /**
   * Forma inwestycji
   * Pojedynczy wybór
   */
  formOfInvestment?: CapitalDetailDtoFormOfInvestmentEnum;
  reactionDto?: ReactionDto;
  /** Notatka */
  note: NoteDto[];
  /**
   * Liczba notatek
   * @format int32
   */
  numberOfNotes?: number;
  /**
   * Id profilu
   * @format uuid
   */
  profileId: string;
  /** Nazwa użytkownika */
  username: string;
  /** Numer telefonu */
  phoneNumber: string;
  /** Email */
  email?: string;
}

/** Stan prawny */
export enum FilterEditInvestorDtoLegalStatusEnum {
  Udzial = "Udział",
  Wlasnosc = "Własność",
  Wspolwlasnosc = "Współwłasność",
}

/** Zabezpieczenie */
export enum FilterEditInvestorDtoInvestmentSecurityEnum {
  PoreczeniePrywatne = "Poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum FilterEditInvestorDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Udogodnienia
 * @example ["Balkon"]
 */
export enum FilterEditDealDtoFeaturesEnum {
  Balkon = "Balkon",
  PomUzytkowe = "Pom. użytkowe",
  GarazMiejsceParkingowe = "Garaż/Miejsce parkingowe",
  Piwnica = "Piwnica",
  Ogrodek = "Ogródek",
  Taras = "Taras",
  Winda = "Winda",
  Dwupoziomowe = "Dwupoziomowe",
  OddzielnaKuchnia = "Oddzielna kuchnia",
  Klimatyzacja = "Klimatyzacja",
  Strych = "Strych",
}

/**
 * Stan prawny
 * @example ["Udział"]
 */
export enum FilterEditDealDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/** Forma zysku */
export enum FilterEditCapitalDtoFormOfProfitEnum {
  Procentowa = "Procentowa",
  UdzialWZysku = "Udział w zysku",
  Hybryda = "Hybryda",
}

/** Zabezpieczenia */
export enum FilterEditCapitalDtoInvestmentSecurityEnum {
  WlasnoscNabywanejNieruchomosciPoreczeniePrywatne = "Własność nabywanej nieruchomości poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum FilterEditCapitalDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Stan prawny
 * Pojedynczy wybór
 * @example "Udział"
 */
export enum InvestorEditDtoLegalStatusEnum {
  Udzial = "Udział",
  Wlasnosc = "Własność",
  Wspolwlasnosc = "Współwłasność",
}

/** Zabezpieczenie */
export enum InvestorEditDtoInvestmentSecurityEnum {
  PoreczeniePrywatne = "Poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum InvestorEditDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Udogodnienia
 * Wielokrotny wybór
 * @example ["Balkon"]
 */
export enum DealEditDtoFeaturesEnum {
  Balkon = "Balkon",
  PomUzytkowe = "Pom. użytkowe",
  GarazMiejsceParkingowe = "Garaż/Miejsce parkingowe",
  Piwnica = "Piwnica",
  Ogrodek = "Ogródek",
  Taras = "Taras",
  Winda = "Winda",
  Dwupoziomowe = "Dwupoziomowe",
  OddzielnaKuchnia = "Oddzielna kuchnia",
  Klimatyzacja = "Klimatyzacja",
  Strych = "Strych",
}

/**
 * Stan prawny
 * Pojedynczy wybór
 * @example "Odrębna własność"
 */
export enum DealEditDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/**
 * Forma zysku
 * Pojedynczy wybór
 */
export enum CapitalEditDtoFormOfProfitEnum {
  Procentowa = "Procentowa",
  UdzialWZysku = "Udział w zysku",
  Hybryda = "Hybryda",
}

/**
 * Zabezpieczenie
 * Wielokrotny wybór
 */
export enum CapitalEditDtoInvestmentSecurityEnum {
  WlasnoscNabywanejNieruchomosciPoreczeniePrywatne = "Własność nabywanej nieruchomości poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/**
 * Forma inwestycji
 * Wielokrotny wybór
 */
export enum CapitalEditDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Kto wysyła wiadomość
 * Pole odpowiadające typowi użytkownika który wysłał wiadomość, jest właściciel ogłoszenia lub zainteresowany
 */
export enum SendMessageMessageSenderEnum {
  AD_OWNER = "AD_OWNER",
  INTERESTED = "INTERESTED",
}

/** Stan prawny */
export enum FilterInvestorDtoLegalStatusEnum {
  Udzial = "Udział",
  Wlasnosc = "Własność",
  Wspolwlasnosc = "Współwłasność",
}

/** Zabezpieczenie */
export enum FilterInvestorDtoInvestmentSecurityEnum {
  PoreczeniePrywatne = "Poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum FilterInvestorDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Udogodnienia
 * @example ["Balkon"]
 */
export enum FilterDealDtoFeaturesEnum {
  Balkon = "Balkon",
  PomUzytkowe = "Pom. użytkowe",
  GarazMiejsceParkingowe = "Garaż/Miejsce parkingowe",
  Piwnica = "Piwnica",
  Ogrodek = "Ogródek",
  Taras = "Taras",
  Winda = "Winda",
  Dwupoziomowe = "Dwupoziomowe",
  OddzielnaKuchnia = "Oddzielna kuchnia",
  Klimatyzacja = "Klimatyzacja",
  Strych = "Strych",
}

/**
 * Stan prawny
 * @example ["Udział"]
 */
export enum FilterDealDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/** Forma zysku */
export enum FilterCapitalDtoFormOfProfitEnum {
  Procentowa = "Procentowa",
  UdzialWZysku = "Udział w zysku",
  Hybryda = "Hybryda",
}

/** Zabezpieczenia */
export enum FilterCapitalDtoInvestmentSecurityEnum {
  WlasnoscNabywanejNieruchomosciPoreczeniePrywatne = "Własność nabywanej nieruchomości poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum FilterCapitalDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/** Stan prawny */
export enum FilterInvestorPageDtoLegalStatusEnum {
  Udzial = "Udział",
  Wlasnosc = "Własność",
  Wspolwlasnosc = "Współwłasność",
}

/** Zabezpieczenie */
export enum FilterInvestorPageDtoInvestmentSecurityEnum {
  PoreczeniePrywatne = "Poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum FilterInvestorPageDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Sortowanie
 * @default "DATE_DESC"
 * @example "DATE_DESC"
 */
export enum FilterInvestorPageDtoSortTypeEnum {
  DATE_DESC = "DATE_DESC",
  DATE_ASC = "DATE_ASC",
  INVESTMENT_TIME_DESC = "INVESTMENT_TIME_DESC",
  INVESTMENT_TIME_ASC = "INVESTMENT_TIME_ASC",
  INVESTOR_CONTRIBUTION_DESC = "INVESTOR_CONTRIBUTION_DESC",
  INVESTOR_CONTRIBUTION_ASC = "INVESTOR_CONTRIBUTION_ASC",
}

/**
 * Rodzaj nieruchomości
 * Pojedynczy wybór
 * @example "Lokal"
 */
export enum InvestorBoxDtoBuildingTypeEnum {
  Lokal = "Lokal",
  Mieszkanie = "Mieszkanie",
  Dzialka = "Działka",
  Kamienica = "Kamienica",
  Dom = "Dom",
  InwestycjaDeweloperska = "Inwestycja deweloperska",
}

/**
 * Stan prawny
 * Pojedynczy wybór
 * @example "Udział"
 */
export enum InvestorBoxDtoLegalStatusEnum {
  Udzial = "Udział",
  Wlasnosc = "Własność",
  Wspolwlasnosc = "Współwłasność",
}

/**
 * Stan nieruchomości
 * Pojedynczy wybór
 * @example "Surowy"
 */
export enum InvestorBoxDtoPropertyConditionEnum {
  DoLekkichPoprawek = "Do lekkich poprawek",
  DoRemontu = "Do remontu",
  Surowy = "Surowy",
  NieDotyczy = "Nie dotyczy",
}

/** Moduł */
export enum InvestorBoxDtoModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/**
 * Kto wysłał
 * @example "AD_OWNER"
 */
export enum MessageDetailMessageSenderEnum {
  AD_OWNER = "AD_OWNER",
  INTERESTED = "INTERESTED",
}

/**
 * Udogodnienia
 * @example ["Balkon"]
 */
export enum FilterDealPageDtoFeaturesEnum {
  Balkon = "Balkon",
  PomUzytkowe = "Pom. użytkowe",
  GarazMiejsceParkingowe = "Garaż/Miejsce parkingowe",
  Piwnica = "Piwnica",
  Ogrodek = "Ogródek",
  Taras = "Taras",
  Winda = "Winda",
  Dwupoziomowe = "Dwupoziomowe",
  OddzielnaKuchnia = "Oddzielna kuchnia",
  Klimatyzacja = "Klimatyzacja",
  Strych = "Strych",
}

/**
 * Stan prawny
 * @example ["Udział"]
 */
export enum FilterDealPageDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/**
 * Sortowanie
 * @default "DATE_DESC"
 * @example "DATE_DESC"
 */
export enum FilterDealPageDtoSortTypeEnum {
  DATE_DESC = "DATE_DESC",
  DATE_ASC = "DATE_ASC",
  PRICE_DESC = "PRICE_DESC",
  PRICE_ASC = "PRICE_ASC",
  ROI_DESC = "ROI_DESC",
  ROI_ASC = "ROI_ASC",
  EXPECTED_PROFIT_DESC = "EXPECTED_PROFIT_DESC",
  EXPECTED_PROFIT_ASC = "EXPECTED_PROFIT_ASC",
}

/**
 * Rodzaj nieruchomości
 * @example "Lokal"
 */
export enum DealBoxDtoBuildingTypeEnum {
  Lokal = "Lokal",
  Mieszkanie = "Mieszkanie",
  Dzialka = "Działka",
  Kamienica = "Kamienica",
  Dom = "Dom",
  InwestycjaDeweloperska = "Inwestycja deweloperska",
}

/**
 * Stan nieruchomości
 * @example "Do lekkich poprawek"
 */
export enum DealBoxDtoPropertyConditionEnum {
  DoLekkichPoprawek = "Do lekkich poprawek",
  DoRemontu = "Do remontu",
  Surowy = "Surowy",
  NieDotyczy = "Nie dotyczy",
}

/**
 * Stan prawny
 * @example "Odrębna własność"
 */
export enum DealBoxDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/** Moduł */
export enum DealBoxDtoModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/** Forma zysku */
export enum FilterCapitalPageDtoFormOfProfitEnum {
  Procentowa = "Procentowa",
  UdzialWZysku = "Udział w zysku",
  Hybryda = "Hybryda",
}

/** Zabezpieczenia */
export enum FilterCapitalPageDtoInvestmentSecurityEnum {
  WlasnoscNabywanejNieruchomosciPoreczeniePrywatne = "Własność nabywanej nieruchomości poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum FilterCapitalPageDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Sortowanie
 * @default "DATE_DESC"
 * @example "DATE_DESC"
 */
export enum FilterCapitalPageDtoSortTypeEnum {
  DATE_DESC = "DATE_DESC",
  DATE_ASC = "DATE_ASC",
  MINIMUM_PROFIT_AMOUNT_DESC = "MINIMUM_PROFIT_AMOUNT_DESC",
  MINIMUM_PROFIT_AMOUNT_ASC = "MINIMUM_PROFIT_AMOUNT_ASC",
  EXPECTED_RETURN_RATE_DESC = "EXPECTED_RETURN_RATE_DESC",
  EXPECTED_RETURN_RATE_ASC = "EXPECTED_RETURN_RATE_ASC",
}

/** Moduł */
export enum CapitalBoxDtoModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/**
 * Stan prawny
 * Pojedynczy wybór
 * @example "Udział"
 */
export enum InvestorBaseDtoLegalStatusEnum {
  Udzial = "Udział",
  Wlasnosc = "Własność",
  Wspolwlasnosc = "Współwłasność",
}

/** Zabezpieczenie */
export enum InvestorBaseDtoInvestmentSecurityEnum {
  PoreczeniePrywatne = "Poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum InvestorBaseDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Udogodnienia
 * Wielokrotny wybór
 * @example ["Balkon"]
 */
export enum DealBaseDtoFeaturesEnum {
  Balkon = "Balkon",
  PomUzytkowe = "Pom. użytkowe",
  GarazMiejsceParkingowe = "Garaż/Miejsce parkingowe",
  Piwnica = "Piwnica",
  Ogrodek = "Ogródek",
  Taras = "Taras",
  Winda = "Winda",
  Dwupoziomowe = "Dwupoziomowe",
  OddzielnaKuchnia = "Oddzielna kuchnia",
  Klimatyzacja = "Klimatyzacja",
  Strych = "Strych",
}

/**
 * Stan prawny
 * Pojedynczy wybór
 * @example "Odrębna własność"
 */
export enum DealBaseDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/**
 * Forma zysku
 * Pojedynczy wybór
 */
export enum CapitalBaseDtoFormOfProfitEnum {
  Procentowa = "Procentowa",
  UdzialWZysku = "Udział w zysku",
  Hybryda = "Hybryda",
}

/**
 * Zabezpieczenie
 * Wielokrotny wybór
 */
export enum CapitalBaseDtoInvestmentSecurityEnum {
  WlasnoscNabywanejNieruchomosciPoreczeniePrywatne = "Własność nabywanej nieruchomości poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/**
 * Forma inwestycji
 * Wielokrotny wybór
 */
export enum CapitalBaseDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Moduł
 * @example "USER_OFFER_CAPITAL"
 */
export enum BoxInfoDtoModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/**
 * Rodzaj nieruchomości
 * @example "Lokal"
 */
export enum BoxInfoDtoBuildingTypeEnum {
  Lokal = "Lokal",
  Mieszkanie = "Mieszkanie",
  Dzialka = "Działka",
  Kamienica = "Kamienica",
  Dom = "Dom",
  InwestycjaDeweloperska = "Inwestycja deweloperska",
}

/**
 * Stan nieruchomości
 * @example "Do lekkich poprawek"
 */
export enum BoxInfoDtoPropertyConditionEnum {
  DoLekkichPoprawek = "Do lekkich poprawek",
  DoRemontu = "Do remontu",
  Surowy = "Surowy",
  NieDotyczy = "Nie dotyczy",
}

/**
 * Stan prawny
 * @example "Odrębna własność"
 */
export enum BoxInfoDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/** Moduł */
export enum FolderMessageModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/** Kto wysłał */
export enum LastMessageMessageSenderEnum {
  AD_OWNER = "AD_OWNER",
  INTERESTED = "INTERESTED",
}

/** Kto wysłał */
export enum LastMessageBuyMessageSenderEnum {
  AD_OWNER = "AD_OWNER",
  INTERESTED = "INTERESTED",
}

/** Moduł */
export enum LastMessageBuyModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/** Moduł */
export enum FilterListModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/**
 * Moduł
 * @example "USER_OFFER_CAPITAL"
 */
export enum BoxEditDtoModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

/**
 * Rodzaj nieruchomości
 * @example "Lokal"
 */
export enum BoxEditDtoBuildingTypeEnum {
  Lokal = "Lokal",
  Mieszkanie = "Mieszkanie",
  Dzialka = "Działka",
  Kamienica = "Kamienica",
  Dom = "Dom",
  InwestycjaDeweloperska = "Inwestycja deweloperska",
}

/**
 * Stan nieruchomości
 * @example "Do lekkich poprawek"
 */
export enum BoxEditDtoPropertyConditionEnum {
  DoLekkichPoprawek = "Do lekkich poprawek",
  DoRemontu = "Do remontu",
  Surowy = "Surowy",
  NieDotyczy = "Nie dotyczy",
}

/**
 * Stan prawny
 * @example "Odrębna własność"
 */
export enum BoxEditDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/**
 * Rodzaj nieruchomości
 * Pojedynczy wybór
 * @example "Lokal"
 */
export enum InvestorDetailDtoBuildingTypeEnum {
  Lokal = "Lokal",
  Mieszkanie = "Mieszkanie",
  Dzialka = "Działka",
  Kamienica = "Kamienica",
  Dom = "Dom",
  InwestycjaDeweloperska = "Inwestycja deweloperska",
}

/**
 * Stan prawny
 * Pojedynczy wybór
 * @example "Udział"
 */
export enum InvestorDetailDtoLegalStatusEnum {
  Udzial = "Udział",
  Wlasnosc = "Własność",
  Wspolwlasnosc = "Współwłasność",
}

/**
 * Stan nieruchomości
 * Pojedynczy wybór
 * @example "Surowy"
 */
export enum InvestorDetailDtoPropertyConditionEnum {
  DoLekkichPoprawek = "Do lekkich poprawek",
  DoRemontu = "Do remontu",
  Surowy = "Surowy",
  NieDotyczy = "Nie dotyczy",
}

/** Zabezpieczenie */
export enum InvestorDetailDtoInvestmentSecurityEnum {
  PoreczeniePrywatne = "Poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/** Forma inwestycji */
export enum InvestorDetailDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

/**
 * Rodzaj nieruchomości
 * Pojedynczy wybór
 * @example "Lokal"
 */
export enum DealDetailDtoBuildingTypeEnum {
  Lokal = "Lokal",
  Mieszkanie = "Mieszkanie",
  Dzialka = "Działka",
  Kamienica = "Kamienica",
  Dom = "Dom",
  InwestycjaDeweloperska = "Inwestycja deweloperska",
}

/**
 * Udogodnienia
 * Wielokrotny wybór
 * @example ["Balkon"]
 */
export enum DealDetailDtoFeaturesEnum {
  Balkon = "Balkon",
  PomUzytkowe = "Pom. użytkowe",
  GarazMiejsceParkingowe = "Garaż/Miejsce parkingowe",
  Piwnica = "Piwnica",
  Ogrodek = "Ogródek",
  Taras = "Taras",
  Winda = "Winda",
  Dwupoziomowe = "Dwupoziomowe",
  OddzielnaKuchnia = "Oddzielna kuchnia",
  Klimatyzacja = "Klimatyzacja",
  Strych = "Strych",
}

/**
 * Stan nieruchomości
 * Pojedynczy wybór
 * @example "Do lekkich poprawek"
 */
export enum DealDetailDtoPropertyConditionEnum {
  DoLekkichPoprawek = "Do lekkich poprawek",
  DoRemontu = "Do remontu",
  Surowy = "Surowy",
  NieDotyczy = "Nie dotyczy",
}

/**
 * Stan prawny
 * Pojedynczy wybór
 * @example "Odrębna własność"
 */
export enum DealDetailDtoLegalStatusEnum {
  OdrebnaWlasnosc = "Odrębna własność",
  SpoldzielczeWlasnoscioweZKW = "Spółdzielcze własnościowe z KW",
  BezKW = "Bez KW",
  Udzial = "Udział",
}

/**
 * Forma zysku
 * Pojedynczy wybór
 */
export enum CapitalDetailDtoFormOfProfitEnum {
  Procentowa = "Procentowa",
  UdzialWZysku = "Udział w zysku",
  Hybryda = "Hybryda",
}

/**
 * Zabezpieczenie
 * Pojedynczy wybór
 */
export enum CapitalDetailDtoInvestmentSecurityEnum {
  WlasnoscNabywanejNieruchomosciPoreczeniePrywatne = "Własność nabywanej nieruchomości poręczenie prywatne",
  UmowaPozyczki = "Umowa pożyczki",
  Weksel = "Weksel",
  HipotekaNaNieruchomosciKupowanej = "Hipoteka na nieruchomości kupowanej",
  HipotekaNaInnejNieruchomosci = "Hipoteka na innej nieruchomości",
}

/**
 * Forma inwestycji
 * Pojedynczy wybór
 */
export enum CapitalDetailDtoFormOfInvestmentEnum {
  UmowaPozyczki = "Umowa pożyczki",
  UmowaOWspolpracy = "Umowa o współpracy",
  UmowaInwestycyjna = "Umowa inwestycyjna",
}

export enum EditAdvertisementStatusParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum CreateNewAdvertisementPhotoParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum SendMessageParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum CreateNewDocumentParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum FiltersListParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum AdvertisementPhotoParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum ListAdvertisementsParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum DeleteFilterParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export enum DeleteAdvertisementParamsModuleEnum {
  USER_OFFER_CAPITAL = "USER_OFFER_CAPITAL",
  USER_OFFER_DEAL = "USER_OFFER_DEAL",
  USER_OFFER_INVESTOR = "USER_OFFER_INVESTOR",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://192.168.31.12:8080";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://192.168.31.12:8080
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  userOffer = {
    /**
     * @description Nowa nazwa zdjęcia spowoduje zastąpienie starego zdjęcia nowym, a brak usunie stare jeżeli istniało i zostawi je puste.
     *
     * @tags Profil użytkownika
     * @name UpdateMyProfile
     * @summary Aktualizacja profilu.
     * @request PUT:/user-offer/profile/update
     * @secure
     */
    updateMyProfile: (data: ProfileDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/profile/update`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Potrzebne id ostatniej przeczytanej wiadomości wszystkie poprzednie jeśli nie były przeczytane zostaną oznaczone jako przeczytane z datą ostatniej przeczytanej wiadomości
     *
     * @tags Wiadomości
     * @name UpdateMessageStatus
     * @summary Ustawienie przeczytania wiadomości
     * @request PUT:/user-offer/message/read
     * @secure
     */
    updateMessageStatus: (
      query: {
        /** @format uuid */
        lastMessageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/message/read`,
        method: "PUT",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name EditFilterInvestor
     * @summary Edycja filtra 'Investor'
     * @request PUT:/user-offer/filter/edit/investor
     * @secure
     */
    editFilterInvestor: (data: FilterEditInvestorDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/filter/edit/investor`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name EditFilterDeal
     * @summary Edycja filtra 'Deal'
     * @request PUT:/user-offer/filter/edit/deal
     * @secure
     */
    editFilterDeal: (data: FilterEditDealDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/filter/edit/deal`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name EditFilterCapital
     * @summary Edycja filtra 'Capital'
     * @request PUT:/user-offer/filter/edit/capital
     * @secure
     */
    editFilterCapital: (data: FilterEditCapitalDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/filter/edit/capital`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name EditAdvertisementStatus
     * @summary Edycja statusu ogłoszenia
     * @request PUT:/user-offer/advertisement/edit/status
     * @secure
     */
    editAdvertisementStatus: (
      query: {
        module: EditAdvertisementStatusParamsModuleEnum;
        /** @format uuid */
        advertisementId: string;
        status: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/advertisement/edit/status`,
        method: "PUT",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name SaveEditInvestor
     * @summary Zapis edytowanego ogłoszenia 'Investor'
     * @request PUT:/user-offer/advertisement/edit/investor/save
     * @secure
     */
    saveEditInvestor: (data: InvestorEditDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/advertisement/edit/investor/save`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name SaveEditDeal
     * @summary Zapis edytowanego ogłoszenia 'Deal'
     * @request PUT:/user-offer/advertisement/edit/deal/save
     * @secure
     */
    saveEditDeal: (data: DealEditDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/advertisement/edit/deal/save`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name SaveEditCapital
     * @summary Zapis edytowanego ogłoszenia 'Mam kapitał'
     * @request PUT:/user-offer/advertisement/edit/capital/save
     * @secure
     */
    saveEditCapital: (data: CapitalEditDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/advertisement/edit/capital/save`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profil użytkownika
     * @name AddNewProfile
     * @summary Dodanie profilu
     * @request POST:/user-offer/profile/add
     * @secure
     */
    addNewProfile: (data: ProfileDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/profile/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Dostępne formaty: JPG, JPEG, PNG, WEBP, TIFF, BMP, GIF
     *
     * @tags Operacje ze zdjęciami
     * @name CreateNewProfilePhoto
     * @summary Tworzenie zdjęć podczas dodawania ogłoszenia.
     * @request POST:/user-offer/photo/profile/add
     * @secure
     */
    createNewProfilePhoto: (
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/photo/profile/add`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Dostępne formaty: JPG, JPEG, PNG, WEBP, TIFF, BMP, GIF
     *
     * @tags Operacje ze zdjęciami
     * @name CreateNewAdvertisementPhoto
     * @summary Tworzenie zdjęć podczas dodawania ogłoszenia.
     * @request POST:/user-offer/photo/advertisement/add
     * @secure
     */
    createNewAdvertisementPhoto: (
      query: {
        module: CreateNewAdvertisementPhotoParamsModuleEnum;
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/photo/advertisement/add`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wiadomości
     * @name SendMessage
     * @summary Wysłanie wiadomości do ogłoszenia
     * @request POST:/user-offer/message/send
     * @secure
     */
    sendMessage: (
      query: {
        module: SendMessageParamsModuleEnum;
      },
      data: SendMessage,
      params: RequestParams = {},
    ) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/message/send`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name CreateInvestor
     * @summary Dodanie filtra 'Investor'
     * @request POST:/user-offer/filter/add/investor
     * @secure
     */
    createInvestor: (data: FilterInvestorDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/filter/add/investor`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name CreateDeal
     * @summary Dodanie filtra 'Deal'
     * @request POST:/user-offer/filter/add/deal
     * @secure
     */
    createDeal: (data: FilterDealDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/filter/add/deal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name CreateCapital
     * @summary Dodanie filtra 'Capital'
     * @request POST:/user-offer/filter/add/capital
     * @secure
     */
    createCapital: (data: FilterCapitalDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/filter/add/capital`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Dostępne formaty: PDF, CSV, DOC, DOCX, RTF, XLSX, XLS, TXT
     *
     * @tags Operacje z dokumentami
     * @name CreateNewDocument
     * @summary Tworzenie dokumentów podczas dodawania
     * @request POST:/user-offer/document/add
     * @secure
     */
    createNewDocument: (
      query: {
        module: CreateNewDocumentParamsModuleEnum;
      },
      data: {
        /** @format binary */
        file: File;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/document/add`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń
     * @name SearchInvestor
     * @summary Wyszukiwanie ofert 'Investor'
     * @request POST:/user-offer/advertisement/search/investor
     * @secure
     */
    searchInvestor: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      data: FilterInvestorPageDto,
      params: RequestParams = {},
    ) =>
      this.http.request<InvestorBoxDtoPage, object>({
        path: `/user-offer/advertisement/search/investor`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń
     * @name SearchDeal
     * @summary Wyszukiwanie ofert 'Deal'
     * @request POST:/user-offer/advertisement/search/deal
     * @secure
     */
    searchDeal: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      data: FilterDealPageDto,
      params: RequestParams = {},
    ) =>
      this.http.request<DealBoxDtoPage, object>({
        path: `/user-offer/advertisement/search/deal`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń
     * @name SearchCapital
     * @summary Wyszukiwanie ofert 'Capital'
     * @request POST:/user-offer/advertisement/search/capital
     * @secure
     */
    searchCapital: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      data: FilterCapitalPageDto,
      params: RequestParams = {},
    ) =>
      this.http.request<CapitalBoxDtoPage, object>({
        path: `/user-offer/advertisement/search/capital`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name CreateInvestor1
     * @summary Dodanie ogłoszenia 'Inwestor'
     * @request POST:/user-offer/advertisement/add/investor
     * @secure
     */
    createInvestor1: (data: InvestorBaseDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/advertisement/add/investor`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name CreateDeal1
     * @summary Dodanie ogłoszenia 'Deal'
     * @request POST:/user-offer/advertisement/add/deal
     * @secure
     */
    createDeal1: (data: DealBaseDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/advertisement/add/deal`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name CreateCapital1
     * @summary Dodanie ogłoszenia 'Mam kapitał'
     * @request POST:/user-offer/advertisement/add/capital
     * @secure
     */
    createCapital1: (data: CapitalBaseDto, params: RequestParams = {}) =>
      this.http.request<object, object | MapResponse>({
        path: `/user-offer/advertisement/add/capital`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profil użytkownika
     * @name MyProfile
     * @summary Pobieranie mojego profilu
     * @request GET:/user-offer/profile/my
     * @secure
     */
    myProfile: (params: RequestParams = {}) =>
      this.http.request<ProfileDto, object>({
        path: `/user-offer/profile/my`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profil użytkownika
     * @name ExistProfile
     * @summary Sprawdzanie czy istnieje profil
     * @request GET:/user-offer/profile/exist
     * @secure
     */
    existProfile: (params: RequestParams = {}) =>
      this.http.request<object, object>({
        path: `/user-offer/profile/exist`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Profil użytkownika
     * @name FindUserAdvertisementsByProfile
     * @summary Pobieranie profilu użytkownika z ogłoszeniami przez innych użytkowników
     * @request GET:/user-offer/profile/advertisements
     * @secure
     */
    findUserAdvertisementsByProfile: (
      query: {
        /** @format uuid */
        profileId: string;
        /** @format int32 */
        page: number;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BoxInfoDto, object>({
        path: `/user-offer/profile/advertisements`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Pobieranie folderów z wiadomościami do moich ogłoszeń
     *
     * @tags Wiadomości
     * @name GetMessageSale
     * @summary Pobieranie wiadomości do ogłoszeń wystawionych przez użytkownika
     * @request GET:/user-offer/message/my/sale
     * @secure
     */
    getMessageSale: (params: RequestParams = {}) =>
      this.http.request<FolderMessage[], object>({
        path: `/user-offer/message/my/sale`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wiadomości
     * @name GetMessageBuy
     * @summary Pobieranie wiadomości do którymi jest zainteresowany użytkownik
     * @request GET:/user-offer/message/my/buy
     * @secure
     */
    getMessageBuy: (params: RequestParams = {}) =>
      this.http.request<LastMessageBuy[], object>({
        path: `/user-offer/message/my/buy`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Potrzebne id ostatniej wiadomości
     *
     * @tags Wiadomości
     * @name GetMessageDetail
     * @summary Pobieranie szczegółów wiadomości
     * @request GET:/user-offer/message/detail
     * @secure
     */
    getMessageDetail: (
      query: {
        /** @format uuid */
        lastMessageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<MessageDetail[], object>({
        path: `/user-offer/message/detail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Potrzebne id ostatniej wiadomości. Zwraca ostatnią wiadomość w momencie znalezienia
     *
     * @tags Wiadomości
     * @name CheckNewMessage
     * @summary Sprawdzanie nowej wiadomości w /my/sale i /my/buy
     * @request GET:/user-offer/message/check-new
     * @secure
     */
    checkNewMessage: (
      query: {
        /** @format uuid */
        lastMessageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<LastMessage, object>({
        path: `/user-offer/message/check-new`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Potrzebne id ostatniej wiadomości. Zwraca listę nowych wiadomość w momencie znalezienia
     *
     * @tags Wiadomości
     * @name CheckDetailNewMessage
     * @summary Sprawdzanie nowej wiadomości w /detail
     * @request GET:/user-offer/message/check-new/detail
     * @secure
     */
    checkDetailNewMessage: (
      query: {
        /** @format uuid */
        lastMessageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<MessageDetail[], object>({
        path: `/user-offer/message/check-new/detail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name FiltersList
     * @summary Pobieranie istniejących filtrów filtrów
     * @request GET:/user-offer/filter/list
     * @secure
     */
    filtersList: (
      query?: {
        module?: FiltersListParamsModuleEnum;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<FilterList[], object>({
        path: `/user-offer/filter/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name DetailInvestor
     * @summary Pobieranie szczegółów filtra 'Inwestor' do edycji
     * @request GET:/user-offer/filter/detail/investor
     * @secure
     */
    detailInvestor: (
      query: {
        /** @format uuid */
        filterId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<FilterEditInvestorDto, object>({
        path: `/user-offer/filter/detail/investor`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name DetailDeal
     * @summary Pobieranie szczegółów filtra 'Deal' do edycji
     * @request GET:/user-offer/filter/detail/deal
     * @secure
     */
    detailDeal: (
      query: {
        /** @format uuid */
        filterId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<FilterEditDealDto, object>({
        path: `/user-offer/filter/detail/deal`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name DetailCapital
     * @summary Pobieranie szczegółów filtra 'Mam kapitał' do edycji
     * @request GET:/user-offer/filter/detail/capital
     * @secure
     */
    detailCapital: (
      query: {
        /** @format uuid */
        filterId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<FilterEditCapitalDto, object>({
        path: `/user-offer/filter/detail/capital`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Pobieranie dokumentów do ofert użytkownika
     *
     * @tags Operacje z dokumentami
     * @name AdvertisementPhoto
     * @summary Pobieranie dokumentów
     * @request GET:/user-offer/document/download
     * @secure
     */
    advertisementPhoto: (
      query: {
        fileName: string;
        module: AdvertisementPhotoParamsModuleEnum;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/document/download`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń
     * @name SearchAll
     * @summary Wyszukiwanie wszystkich ofert
     * @request GET:/user-offer/advertisement/search/all
     * @secure
     */
    searchAll: (
      query: {
        /** @format int32 */
        size: number;
        /** @format int32 */
        page: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BoxInfoDto, object>({
        path: `/user-offer/advertisement/search/all`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name ListAdvertisements
     * @summary Pobieranie listy ogłoszeń użytkownika
     * @request GET:/user-offer/advertisement/edit/list
     * @secure
     */
    listAdvertisements: (
      query: {
        /** @format int32 */
        page: number;
        module?: ListAdvertisementsParamsModuleEnum;
        /** @format int32 */
        size: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<BoxEditDtoPage[], object>({
        path: `/user-offer/advertisement/edit/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name DetailInvestor1
     * @summary Pobieranie szczegółów ogłoszenia 'Inwestor' do edycji
     * @request GET:/user-offer/advertisement/edit/investor/detail
     * @secure
     */
    detailInvestor1: (
      query: {
        /** @format uuid */
        advertisementId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestorEditDto, object>({
        path: `/user-offer/advertisement/edit/investor/detail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name DetailDeal1
     * @summary Pobieranie szczegółów ogłoszenia 'Deal' do edycji
     * @request GET:/user-offer/advertisement/edit/deal/detail
     * @secure
     */
    detailDeal1: (
      query: {
        /** @format uuid */
        advertisementId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DealEditDto, object>({
        path: `/user-offer/advertisement/edit/deal/detail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie ogłoszeniami
     * @name DetailCapital1
     * @summary Pobieranie szczegółów ogłoszenia 'Mam kapitał' do edycji
     * @request GET:/user-offer/advertisement/edit/capital/detail
     * @secure
     */
    detailCapital1: (
      query: {
        /** @format uuid */
        advertisementId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<CapitalEditDto, object>({
        path: `/user-offer/advertisement/edit/capital/detail`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń
     * @name DetailInvestor2
     * @summary Pobieranie szczegółów ogłoszenia 'Inwestor'
     * @request GET:/user-offer/advertisement/detail/investor
     * @secure
     */
    detailInvestor2: (
      query: {
        /** @format uuid */
        advertisementId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<InvestorDetailDto, object>({
        path: `/user-offer/advertisement/detail/investor`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń
     * @name DetailDeal2
     * @summary Pobieranie szczegółów ogłoszenia 'Deal'
     * @request GET:/user-offer/advertisement/detail/deal
     * @secure
     */
    detailDeal2: (
      query: {
        /** @format uuid */
        advertisementId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<DealDetailDto, object>({
        path: `/user-offer/advertisement/detail/deal`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Wyszukiwanie ogłoszeń
     * @name DetailCapital2
     * @summary Pobieranie szczegółów ogłoszenia 'Mam kapitał'
     * @request GET:/user-offer/advertisement/detail/capital
     * @secure
     */
    detailCapital2: (
      query: {
        /** @format uuid */
        advertisementId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<CapitalDetailDto, object>({
        path: `/user-offer/advertisement/detail/capital`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Potrzebne id ostatniej wiadomości, do usunięcia całej konwersacji.
     *
     * @tags Wiadomości
     * @name DeleteConversation
     * @summary Usuwanie konwersacji
     * @request DELETE:/user-offer/message/delete
     * @secure
     */
    deleteConversation: (
      query: {
        /** @format uuid */
        lastMessageId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/message/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami
     * @name DeleteFilter
     * @summary Usuwanie istniejącego filtra
     * @request DELETE:/user-offer/filter/delete
     * @secure
     */
    deleteFilter: (
      query: {
        module: DeleteFilterParamsModuleEnum;
        /** @format uuid */
        filterId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/filter/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Usuwanie istniejącego ogłoszenia przez użytkownika
     *
     * @tags Zarządzanie ogłoszeniami
     * @name DeleteAdvertisement
     * @summary Usuwanie ogłoszenia
     * @request DELETE:/user-offer/advertisement/edit/delete
     * @secure
     */
    deleteAdvertisement: (
      query: {
        module: DeleteAdvertisementParamsModuleEnum;
        /** @format uuid */
        advertisementId: string;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<object, object>({
        path: `/user-offer/advertisement/edit/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
