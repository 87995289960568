import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { ReactionType } from 'store/Offers/State';
import SaleContext from 'utils/context/SaleContext';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { SaleOfferDuplicatesResponse, SaleOfferResponseContent } from 'utils/state-managment/sale/saleOffer';
import { ModulesType } from 'utils/types/ModulesType';
import { UserReactions } from 'utils/types/OfferData';
import { Module } from 'utils/types/SaleModels';

import {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { SliderImages } from 'components/common/Slider/Slider';
import { V2DuplicatesTabProps } from 'components/common/Tabs/tabs/V2DuplicatesTab';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import { saleAndRentOfferListFunctions } from 'components/functions/offerListFunctions/saleAndRentOfferListFunctions/saleAndRentOfferListFunctions';
import { RenderList } from 'components/offerList/V2OfferList';

export const useSalesHook = (filters: FavoritePageFiltersType, activeFilter: ModulesType, subActiveFilter: Module[]) => {
    const {
        saleOfferState,
        loadDuplicates,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync,
        clearOffers,
        loadFavoriteOffers,
        updateOpinion,
        deleteOpinion,
        loadOpinionsAsync
    } = useContext(SaleContext);
    const [renderList, setRenderList] = useState<RenderList[]>([]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        loadFavoriteOffers(subActiveFilter, currentPage ?? 1, rows ?? 25, filters);
    }, [
        filters,
        activeFilter,
        subActiveFilter
    ]);

    const onNoteAdd = () => {
        // TODO: INVEST-257 Czy ta funkcja jest potrzebna?
        // if (!showNote && showLastNote) {
        //     showLastNote();
        // }
        //
        // if (!data.note?.noteId) {
        //     openOfferTab('note');
        // }
    };

    const onMessageClick = () => {
        onNoteAdd();
    };

    const handleDelete = (noteId: number, id: number, type: Module) => {
        deleteNote(id, type, noteId);
    };

    const handleSaveNote = async (value: string, advertId: number, type: Module, id?: number) => {
        updateNote(type, value, advertId, id || 0);
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: SaleOfferResponseContent) => {
        if (!detailsOpen) {
            if (offer.reaction && !offer.reaction.viewedStatus) {
                reactionUpdate(offer.advertisementId, offer.location!, {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                } as ReactionType, offer.module);
            }
            loadOfferDetailsAsync(offer.advertisementId, offer.module);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: SaleOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        reactionUpdate(offer.advertisementId, city, userReaction as UserReactions, offer.module);
    };

    const generateInteractiveElements = (offer: SaleOfferResponseContent): InteractiveElements[] => {
        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction?.viewedStatus,
                disabled: offer.reaction?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction?.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction?.unlikeStatus,
                disabled: offer.reaction?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: onMessageClick
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction?.hideStatus,
                disabled: offer.reaction?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction?.phoneStatus,
                disabled: offer.reaction?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction?.arrangedStatus,
                disabled: offer.reaction?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction?.arrangedStatus, offer)
            },
            ...offer.phoneNumber
                ? [
                    {
                        icon: IconEnum.PHONE,
                        isLink: true,
                        active: false,
                        isPhoneBtn: false,
                        text: <a href={`tel:${offer.phoneNumber}`}>{offer.phoneNumber}</a>
                    }
                ]
                : [],
            {
                tooltipText: 'Przejdź do ogłoszenia',
                icon: IconEnum.LINK,
                isLink: true,
                noText: true,
                text: <a target="_blank" rel="noopener noreferrer" href={offer.link}/>
            },
            ...offer.photos && offer.photos.length > 0
                ? [
                    {
                        icon: IconEnum.PHOTOS,
                        isModalOpen: true,
                        isLink: true,
                        text: <p>Zobacz zdjęcia</p>,
                        isPhotoBtn: true,
                        visibleDuringModal: true,
                        sliderElements: {
                            photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                            title: offer.title
                        }
                    }
                ]
                : []
        ];
    };

    const handleDuplicates = async (offer: SaleOfferResponseContent) => {
        loadDuplicates(offer.module, offer.advertisementId);
    };

    const handleNotes = async (offer: SaleOfferResponseContent) => {
        loadNotesAsync(offer.advertisementId.toString(), offer.module);
    };

    const handleSaveOpinion = (rating: number, opinion: string, id: number, module: Module) => {
        updateOpinion(module, opinion, rating, id);
    };

    const generateCardData = (offer: SaleOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        if (offer.pricePerSquareMeter) {
            subMainValues.push(
                <><b>{formatFilterPrice(offer.pricePerSquareMeter)?.toString()}</b> zł / m<sup>2</sup></>
            );
        }

        return {
            preTitle: offer.location,
            lowestPriceLabel: offer.duplicateWithLowestPrice,
            privateOfferLabel: offer.duplicateWithPrivateOffer,
            oldPrice: offer.priceBeforeModification?.toString(),
            date: offer.dateAdded,
            title: offer.title,
            infoElements: saleAndRentOfferListFunctions.generateInfoElements(offer),
            interactiveElements: generateInteractiveElements(offer),
            modifications: {
                duplicationsNumber: offer.numberOfDuplicates ?? 0,
                modificationsNumber: offer.numberOfModifications ?? 0,
                boostsNumber: offer.numberOfRaises ?? 0
            },
            note: offer.note,
            modificationDate: offer.lastUpdated,
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            ratingScore: offer.score ?? 0,
            scorePrecision: offer.scorePrecision ?? 0,
            additionalInfoBadge: offer.offerFrom,
            images: createImagesForSlider(offer.photos || [], offer.module),
            mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
            subMainValues,
            link: {
                url: offer.link,
                name: offer.portal
            },
            ratingContainer: {
                opinionsNumber: offer.numberOfCommunityOpinions,
                opinionsRating: offer.communityScore,
                commentsCount: offer.numberOfCommunityComments,
                title: offer.title,
                userRatingValue: offer.myOpinion?.numberOfStars,
                userRatingComment: offer.myOpinion?.content || undefined,
                handleGetComments: () => handleNotes(offer),
                handleSendRating: (rating: number, description: string) => offer.myOpinion ? updateOpinion(offer.module, description, rating, offer.advertisementId, offer.myOpinion?.id) : handleSaveOpinion(rating, description, offer.advertisementId, offer.module)
            }
        };
    };

    const generateDuplicateCardData = (offer: SaleOfferDuplicatesResponse) => {
        return {
            preTitle: offer.location,
            oldPrice: offer.priceBeforeModification?.toString(),
            date: offer.dateAdded,
            title: offer.title,
            infoElements: saleAndRentOfferListFunctions.generateInfoElements(offer as SaleOfferResponseContent),
            interactiveElements: generateInteractiveElements(offer as SaleOfferResponseContent),
            modifications: {
                modificationsNumber: offer.numberOfModifications ?? 0,
                boostsNumber: offer.numberOfRaises ?? 0
            },
            note: offer.note,
            modificationDate: offer.lastUpdated,
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module!) : './defaultImg.png',
            ratingScore: offer.score ?? 0,
            additionalInfoBadge: offer.offerFrom,
            images: createImagesForSlider(offer.photos || [], offer.module!),
            mainValue: offer.price ? `${offer.price} zł` : '- zł',
            link: {
                url: offer.link,
                name: offer.portal
            }
        };
    };

    useEffect(() => {
        const offersList = saleOfferState.saleOfferList.content
            ? saleOfferState.saleOfferList?.content.map((offer): RenderList => {
                const interactiveElements = generateInteractiveElements(offer);
                const detailsTabContent = saleAndRentOfferListFunctions.generateOfferDetailsTabContent(offer);
                const duplicatesTabContent: V2DuplicatesTabProps = {
                    duplicates: offer.duplicates?.map((duplicate) => {
                        return {
                            id: duplicate.advertisementId,
                            bigImage: true,
                            cardData: generateDuplicateCardData({ ...duplicate, module: offer.module }),
                            onClick: () => {},
                            openOfferTab: () => {}
                        };
                    })
                };

                return {
                    id: offer.advertisementId,
                    onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                    baseCardContent: {
                        bigImage: true,
                        cardData: generateCardData(offer),
                        openOfferTab: () => {
                        }
                    },
                    detailsCardData: {
                        interactiveElements,
                        modificationDate: offer.lastUpdated,
                        additionalInfoBadge: offer.offerFrom,
                        thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                        date: offer.dateAdded!,
                        onToggleContainerDetails: () => {
                        },
                        tabsContent: {
                            toggleContainerDetails: () => {
                            },
                            interactiveElements,
                            detailsTabContent,
                            duplicatesTabContent,
                            modificationTabContent: {
                                modifications: offer.detailedContent?.modifications || []
                            },
                            notesTabContent: {
                                notes: saleOfferState.notes,
                                handleDelete: (noteId: string) => handleDelete(Number(noteId), offer.advertisementId, offer.module),
                                handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.advertisementId, offer.module, Number(id))
                            },
                            opinionTabContent: {
                                handleSaveOpinion: (rating: number, content: string, id?: number) => updateOpinion(offer.module, content, rating, offer.advertisementId, id || offer.myOpinion?.id),
                                comments: saleOfferState.scoreAndOpinions?.opinions?.map((opinion) => ({
                                    id: opinion.opinionId,
                                    date: opinion.dateAdded,
                                    content: opinion.content || '',
                                    rating: opinion.numberOfStars,
                                    isUserComment: opinion.loggedUserOpinion
                                })),
                                applicationScore: offer.score,
                                applicationScorePrecision: offer.scorePrecision,
                                commentsCount: offer.numberOfCommunityComments,
                                opinionsNumber: offer.numberOfCommunityOpinions,
                                opinionsRating: saleOfferState.scoreAndOpinions?.communityScore || 0,
                                handleDeleteOpinion: (id: number) => deleteOpinion(offer.advertisementId, offer.module, id)
                            },
                            numberOfModifications: offer.numberOfModifications || 0,
                            numberOfDuplicates: offer.numberOfDuplicates || 0,
                            numberOfNotes: offer.numberOfNotes || 0,
                            handleLoadDuplicates: () => handleDuplicates(offer),
                            handleLoadNotes: () => handleNotes(offer),
                            handleLoadComments: () => loadOpinionsAsync(offer.advertisementId, offer.module)
                        }
                    }
                };
            })
            : [];

        setRenderList(offersList);

        return () => {
            if (location.pathname !== '/favorites') {
                clearOffers();
            }
        };
    }, [
        saleOfferState.saleOfferList?.content,
        saleOfferState.notes,
        saleOfferState.scoreAndOpinions?.opinions
    ]);

    const handleFavoriteSearch = (modules: Module[], page?: number, size?: number) => {
        clearOffers();
        loadFavoriteOffers(modules, page ?? 1, size ?? 25, filters);
    };

    return {
        saleOfferState,
        handleChangePage,
        handleFavoriteSearch,
        renderList
    };
};

