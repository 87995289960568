import React, { createContext, useCallback, useEffect, useState } from 'react';
import { FavoritePageFiltersType } from 'pages/FavoritesPage';
import { commonApiRequests } from 'utils/api-requests/common';
import { cooperativeOffersApiRequests } from 'utils/api-requests/cooperative';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { offersApiRequests } from 'utils/api-requests/offers';
import {
    getCooperativeValueFromState,
    initialState,
    setCooperativeStateValues
} from 'utils/state-managment/cooperative/cooperativeFilter';
import {
    cooperativeInitialState,
    CooperativeOffersResponse,
    CooperativeOfferStateType
} from 'utils/state-managment/cooperative/cooperativeOffer';
import { CooperativeFilterReturnData, CooperativeFiltersStateType } from 'utils/types/Cooperative';
import { CooperativeSearchingRequestSortTypeEnum, NoteResponse } from 'utils/types/CooperativeModels';
import { ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { UserReactions } from 'utils/types/OfferData';

interface LoadCooperativeOffersType {
    requestData?: CooperativeFilterReturnData,
    page?: number,
    rows?: number
}

export interface CooperativeContextType {
    cooperativeFiltersState: CooperativeFiltersStateType,
    cooperativeOfferState: CooperativeOfferStateType,
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void,
    handleSetFiltersValues: (id: number) => void,
    handleDeleteFilter: (id: number) => void,
    handleChangeDateList: (fieldName: string, value: number) => void,
    getSavedFilters: (id?: number) => void,
    clearFilters: () => void,
    loadCooperativeOffers: (cooperativeOffersOptions?: LoadCooperativeOffersType) => void;
    deleteNote: (id: number, noteId: number) => void,
    updateNote: (noteContent: string, advertisementId: number, noteId: number) => void,
    reactionUpdate: (id: number, city: string, reaction: UserReactions) => void,
    loadOfferDetailsAsync: (id: number) => void;
    loadNotesAsync: (id: string) => void;
    loadFavoriteOffers: (page?: number, size?: number, filters?: FavoritePageFiltersType) => void,
    clearOffers: () => void,
    getPortalList: () => void;
}

const values: CooperativeContextType = {
    cooperativeFiltersState: {} as CooperativeFiltersStateType,
    cooperativeOfferState: {} as CooperativeOfferStateType,
    handleChange: () => {},
    handleSetFiltersValues: () => {},
    handleDeleteFilter: () => {},
    handleChangeDateList: () => {},
    getSavedFilters: () => {},
    clearFilters: () => {},
    loadCooperativeOffers: () => {},
    deleteNote: () => {},
    updateNote: () => {},
    reactionUpdate: () => {},
    loadOfferDetailsAsync: () => {},
    loadNotesAsync: () => {},
    loadFavoriteOffers: () => {},
    clearOffers: () => {},
    getPortalList: () => {}
};

const COOPERATIVE_MODULE = 'COOPERATIVE';

export const CooperativeContext = createContext(values);

export const CooperativeProvider = ({ children }: { children: React.ReactNode }) => {
    const [cooperativeFiltersState, setCooperativeFiltersState] = useState(initialState.cooperativeFilterInitialState());
    const [cooperativeOfferState, setCooperativeOfferState] = useState(cooperativeInitialState);

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setCooperativeFiltersState((filters: CooperativeFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));

        if (fieldName === 'sortType') {
            setCooperativeOfferState((prevState) => ({
                ...prevState,
                sortType: value as CooperativeSearchingRequestSortTypeEnum
            }));
        }
    }, [cooperativeFiltersState]);

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await cooperativeOffersApiRequests.getSavedFilters();
        setCooperativeFiltersState((filters: CooperativeFiltersStateType) => ({
            ...filters,
            savedFilter: savedFilters
        }));

        if (id) {
            await handleSetFiltersValues(id);
        }
    }, []);

    const clearFilters = useCallback((reset?: boolean) => {
        setCooperativeFiltersState((prev) => ({
            ...initialState.cooperativeFilterInitialState(),
            locations: prev.locations,
            city: reset ? [] : prev.city,
            savedLocations: reset ? {} : prev.savedLocations
        }));
        getSavedFilters();
    }, [getSavedFilters]);

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        await cooperativeOffersApiRequests.removeSavedFilter(filterId);

        setCooperativeFiltersState(initialState.cooperativeFilterInitialState());
        getSavedFilters();
    }, [
        cooperativeFiltersState.filterId,
        cooperativeFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleSetFiltersValues = async (id: number) => {
        const filterData = await cooperativeOffersApiRequests.getFilterDetailsCooperative(id) as CooperativeFilterReturnData;

        if (filterData) {
            setCooperativeFiltersState((filters: CooperativeFiltersStateType) => ({
                ...setCooperativeStateValues({ ...filterData, locations: cooperativeFiltersState.locations }),
                savedFilter: filters.savedFilter,
                filterId: id
            }));
            getVoivodeships();
        } else {
            setCooperativeFiltersState((filters: CooperativeFiltersStateType) => ({
                ...filters,
                name: null,
                filterId: null
            }));
        }
    };

    const handleChangeDateList = (fieldName: string, value: number) => {
        setCooperativeFiltersState((filters: CooperativeFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1 ? null : new Date(new Date().setDate(new Date().getDate() - value)).toISOString().split('T')[0],
                to: new Date().toISOString().split('T')[0]
            },
            [fieldName]: value
        }));
    };

    const setLoadingPage = () => {
        setCooperativeOfferState((prev) => ({
            ...prev,
            isLoading: true
        }));
    };

    const loadCooperativeOffers = async (cooperativeOffersOptions?: LoadCooperativeOffersType) => {
        const { requestData, page, rows } = cooperativeOffersOptions || {};

        setLoadingPage();

        const response = await cooperativeOffersApiRequests.getCooperativeOffers(requestData ? requestData : getCooperativeValueFromState(cooperativeFiltersState), page ?? 0, rows ?? 25);
        getPortalList();

        if (response) {
            setCooperativeOfferState((prev) => ({
                ...prev,
                cooperativeOfferList: response,
                isLoading: false
            }));
        }
    };

    const loadFavoriteOffers = async (page?: number, size?: number, filters?: FavoritePageFiltersType) => {
        setLoadingPage();

        const response = await offersApiRequests.getUsersFavoriteOffers(COOPERATIVE_MODULE, page ?? 1, size ?? 25, filters) as unknown as CooperativeOffersResponse;
        setCooperativeOfferState((prev) => {
            const newState = {
                ...prev,
                cooperativeOfferList: response,
                isLoading: false
            };

            return newState;
        });
    };

    const deleteNote = async (id: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.deleteNote(COOPERATIVE_MODULE, noteId);

        if (validChangeStatus) {
            setCooperativeOfferState((prev) => {
                return {
                    ...prev,
                    notes: prev.notes!.filter((item) => item.noteId !== noteId),
                    cooperativeOfferList: {
                        ...prev.cooperativeOfferList,
                        content: prev.cooperativeOfferList.content.map((offer) => {
                            if (offer.cooperativeId === id) {
                                return {
                                    ...offer,
                                    numberOfNotes: (offer.numberOfNotes ?? 0) - 1
                                };
                            }

                            return offer;
                        })
                    },
                    isLoading: false
                };
            });
        }
    };

    const updateNote = async (noteContent: string, advertisementId: number, noteId: number) => {
        setLoadingPage();

        const validChangeStatus = await commonApiRequests.updateNote(COOPERATIVE_MODULE, noteContent, advertisementId, noteId) as NoteResponse;

        if (validChangeStatus) {
            if (noteId) {
                setCooperativeOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes!.map((item) => {
                            if (item.noteId === noteId) {
                                return {
                                    ...item,
                                    date: validChangeStatus.date,
                                    content: validChangeStatus.content
                                };
                            }

                            return item;
                        }),
                        cooperativeOfferList: {
                            ...prev.cooperativeOfferList,
                            content: prev.cooperativeOfferList.content.map((x) => {
                                if (x.cooperativeId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus,
                                            noteId: noteId
                                        }
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            } else {
                setCooperativeOfferState((prev) => {
                    return {
                        ...prev,
                        notes: prev.notes ? [validChangeStatus].concat(prev.notes) : [validChangeStatus],
                        cooperativeOfferList: {
                            ...prev.cooperativeOfferList,
                            content: prev.cooperativeOfferList.content.map((x) => {
                                if (x.cooperativeId === advertisementId) {
                                    return {
                                        ...x,
                                        note: {
                                            ...validChangeStatus
                                        },
                                        numberOfNotes: (x.numberOfNotes ?? 0) + 1
                                    };
                                }

                                return x;
                            })
                        },
                        isLoading: false
                    };
                });
            }
        }
    };

    const reactionUpdate = async (id: number, city: string, reaction: UserReactions) => {
        const response = await commonApiRequests.setOfferReaction(id, city, reaction, COOPERATIVE_MODULE);

        setCooperativeOfferState((prev) => ({
            ...prev,
            cooperativeOfferList: {
                ...prev.cooperativeOfferList,
                content: prev.cooperativeOfferList.content.map((el) => {
                    if (el.cooperativeId === id) {
                        return {
                            ...el,
                            reaction: response
                        };
                    }

                    return el;
                })
            }
        }));
    };

    const loadOfferDetailsAsync = async (id: number) => {
        setLoadingPage();

        const response = await cooperativeOffersApiRequests.getCooperativeOfferDetails(id);

        setCooperativeOfferState((prevState) => ({
            ...prevState,
            cooperativeOfferList: {
                ...prevState.cooperativeOfferList,
                content: prevState.cooperativeOfferList.content.map((el) => {
                    if (el.cooperativeId === response.cooperativeId) {
                        return {
                            ...el,
                            detailedContent: response
                        };
                    }

                    return el;
                })
            },
            isLoading: false
        }));
    };

    const loadNotesAsync = async (id: string) => {
        const notes = await commonApiRequests.getNotes(COOPERATIVE_MODULE, id);

        setCooperativeOfferState((prev) => ({
            ...prev,
            notes
        }));
    };

    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setCooperativeFiltersState((filters: CooperativeFiltersStateType) => ({
            ...filters,
            locations,
            officeLocations: locations
        }));
    }, []);

    const clearOffers = () => {
        setCooperativeOfferState(cooperativeInitialState);
    };

    const getPortalList = useCallback(async () => {
        const portalList = await cooperativeOffersApiRequests.getPortalList(cooperativeFiltersState.savedLocations);

        setCooperativeOfferState((prev) => ({
            ...prev,
            cooperativeOfferList: {
                ...prev.cooperativeOfferList,
                portalList: portalList
            }
        }));
    }, [cooperativeFiltersState.savedLocations]);

    useEffect(() => {
        if (cooperativeFiltersState.locations.length === 0) {
            getVoivodeships();
        }
    }, [cooperativeFiltersState.locations]);

    const providerValues = {
        cooperativeOfferState,
        cooperativeFiltersState,
        handleChange,
        handleSetFiltersValues,
        handleDeleteFilter,
        handleChangeDateList,
        getSavedFilters,
        clearFilters,
        loadCooperativeOffers,
        deleteNote,
        updateNote,
        reactionUpdate,
        loadOfferDetailsAsync,
        loadNotesAsync,
        loadFavoriteOffers,
        clearOffers,
        getPortalList
    };

    return (
        <CooperativeContext.Provider value={providerValues}>
            {children}
        </CooperativeContext.Provider>
    );
};

export default CooperativeContext;

