import React, { useCallback, useContext } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { saleOffersApiRequests } from 'utils/api-requests/sale';
import SaleContext from 'utils/context/SaleContext';
import { getNormalizedSaleModuleName } from 'utils/formatters/getNormalizedSaleModuleName';
import { saleSortOptions } from 'utils/options';
import { getSaleValueFromState } from 'utils/state-managment/sale/saleFilter';
import { SortType } from 'utils/types/SortType';

import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import FiltersSale from 'components/sales/FiltersSale';
import SaleOffers from 'components/sales/SaleOffers';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const SalesPage = () => {
    const { saleOfferState, saleFiltersState, handleChange, loadSaleOffers, clearOffers } = useContext(SaleContext);

    const handleChangeSort = (sortType: string) => {
        handleChange('sortType', sortType);
        loadSaleOffers({
            requestData: {
                ...getSaleValueFromState(saleFiltersState),
                sortType: sortType as SortType
            },
            page: 1,
            rows: 25
        });
    };

    const handleCSVFile = useCallback(() => {
        saleOffersApiRequests.getSaleOffersCSVFile(getSaleValueFromState(saleFiltersState), getNormalizedSaleModuleName(saleFiltersState.module));
    }, [saleFiltersState]);

    const handleShowLowerOriginalPriceOffers = () => {
        handleChange('lowerOriginalPrice', !saleFiltersState.lowerOriginalPrice);
        loadSaleOffers({
            requestData: {
                ...getSaleValueFromState(saleFiltersState),
                lowerOriginalPrice: !saleFiltersState.lowerOriginalPrice
            },
            page: 1,
            rows: 25
        });
    };

    return (
        <div>
            <V2HeaderSection
                title={'Ogłoszenia sprzedaży'}
                offersCount={saleOfferState.saleOfferList?.totalElements}
                sortType={saleOfferState.saleOfferList.content?.length ? saleFiltersState.sortType : undefined}
                sortOptions={saleSortOptions}
                handleChangeSort={handleChangeSort}
                handleBackToFilters={saleOfferState.saleOfferList.content?.length || saleOfferState.saleOfferList.empty ? clearOffers : undefined}
                handleCSVClick={handleCSVFile}
                handleShowLowerOriginalPriceOffers={handleShowLowerOriginalPriceOffers}
                showLowerOriginalPriceOffers={saleFiltersState.lowerOriginalPrice}
                showLowerPriceCheckInput={true}

            />
            <FiltersContentWrapper
                className={clsx('offers-list', { active: saleOfferState.saleOfferList?.content && saleOfferState.saleOfferList?.content.length > 0 || saleOfferState.saleOfferList?.empty })}>
                <Container id="filters">
                    <FiltersSale/>
                </Container>
                <SaleOffers/>
                {saleOfferState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </div>
    );
};

export default SalesPage;
