import React, { useContext } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import CooperativeContext from 'utils/context/CooperativeContext';
import { cooperativeSortOptions } from 'utils/options';
import { getCooperativeValueFromState } from 'utils/state-managment/cooperative/cooperativeFilter';
import { CooperativeSearchingRequestSortTypeEnum } from 'utils/types/CooperativeModels';

import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import CooperativeOffers from 'components/cooperative/CooperativeOffers';
import FiltersCooperative from 'components/cooperative/FiltersCooperative';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const PageContainer = styled.div`
    height: 100%;
`;

const CooperativePage = () => {
    const { cooperativeOfferState, handleChange, loadCooperativeOffers, cooperativeFiltersState, clearOffers } = useContext(CooperativeContext);

    const handleChangeSort = (sortType: string) => {
        handleChange('sortType', sortType);
        loadCooperativeOffers({
            requestData: {
                ...getCooperativeValueFromState(cooperativeFiltersState),
                sortType: sortType as CooperativeSearchingRequestSortTypeEnum
            },
            page: 1,
            rows: 25
        });
    };

    // const handleCSVFile = useCallback(() => {
    //     cooperativeOffersApiRequests.getCooperativeOffersCSVFile(getCooperativeValueFromState(cooperativeFiltersState), cooperativeFiltersState.module);
    // }, [cooperativeFiltersState]);

    return (
        <PageContainer>
            <V2HeaderSection
                title={'Przetargi lokalne'}
                offersCount={cooperativeOfferState.cooperativeOfferList?.totalElements}
                sortType={cooperativeOfferState.cooperativeOfferList.content?.length ? cooperativeOfferState.sortType : undefined}
                sortOptions={cooperativeSortOptions}
                handleChangeSort={handleChangeSort}
                handleBackToFilters={cooperativeOfferState.cooperativeOfferList.content?.length || cooperativeOfferState.cooperativeOfferList.empty ? clearOffers : undefined}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: cooperativeOfferState.cooperativeOfferList?.content && cooperativeOfferState.cooperativeOfferList?.content.length > 0 || cooperativeOfferState.cooperativeOfferList?.empty })}>
                <Container id="filters">
                    <FiltersCooperative/>
                </Container>
                <CooperativeOffers/>
                {cooperativeOfferState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </PageContainer>
    );
};

export default CooperativePage;
