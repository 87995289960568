import React, { useCallback, useContext, useEffect, useState } from 'react';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import { ReactionType, RentOfferResponseContent } from 'store/Offers/State';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import RentContext from 'utils/context/RentContext';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { generateSingleOfferLink } from 'utils/generateSingleOfferLink';
import { getStringFromDate } from 'utils/getStringFromDate';
import { getRentFiltersFromState, getValueFromState } from 'utils/state-managment/rent/rentFilter';
import { MultipleFilterType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { RentFiltersStateType, RentModuleType } from 'utils/types/Rent';

import OfferActions from 'components/common/Card/common/OfferActions/OfferActions';
import { InteractiveElements } from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { SliderImages } from 'components/common/Slider/Slider';
import { V2DuplicatesTabProps } from 'components/common/Tabs/tabs/V2DuplicatesTab';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import { saleAndRentOfferListFunctions } from 'components/functions/offerListFunctions/saleAndRentOfferListFunctions/saleAndRentOfferListFunctions';
import { generateOfferActionsElements } from 'components/functions/shared/generateOfferActionsElements';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';

const RentOffers = () => {
    const {
        rentFiltersState,
        handleChange
    } = useContext(RentContext);
    const [moreButtonActiveId, setMoreButtonActiveId] = useState<string | null>(null);
    const [detailsMoreButtonActiveId, setDetailsMoreButtonActiveId] = useState<string | null>(null);

    const { offers, dispatch } = useOffers();
    const [renderList, setRenderList] = useState<RenderList[]>([]);

    const handleChangePage = useCallback((currentPage: number, rows = 25) => {
        OffersActions.loadOffersAsync(dispatch, 'rent', rentFiltersState.city, getValueFromState(rentFiltersState), getNormalizedPropertyType(rentFiltersState.module), currentPage, rows);
    }, [rentFiltersState]);

    const handleActiveFiltersChange = () => {
        OffersActions.loadOffersAsync(dispatch, 'rent', rentFiltersState.city, getValueFromState(rentFiltersState), getNormalizedPropertyType(rentFiltersState.module), 1, 25);
    };

    const handleOlderAdverts = () => {
        const date = rentFiltersState.offerAdded.from ? new Date(new Date(rentFiltersState.offerAdded.from).setDate(new Date(rentFiltersState.offerAdded.from).getDate() - 6)) : null;
        const dateString = date && getStringFromDate(date.toDateString());
        const rowsCount = offers.rentList && offers.rentList.size ? offers.rentList.size : 25;

        OffersActions.loadOffersAsync(dispatch, 'rent', rentFiltersState.city, {
            ...getValueFromState(rentFiltersState),
            offerAddedFrom: dateString
        }, getNormalizedPropertyType(rentFiltersState.module), offers.rentList?.pageable?.pageNumber, rowsCount);
    };

    const onMessageClick = (offer: RentOfferResponseContent) => {
        toggleContainerDetails(false, offer);
    };

    const handleDelete = (noteId: number, id: string, type: RentModuleType) => {
        OffersActions.deleteNote(dispatch, id, 'rent', noteId, type);
    };

    const handleSaveNote = async (value: string, advertId: string, location: string, type: RentModuleType, id?: number) => {
        await OffersActions.updateNote(dispatch, advertId, location || '', 'rent', value, id || 0, type);
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: RentOfferResponseContent) => {
        if (!detailsOpen) {
            !offer.reaction.viewedStatus
                // @ts-expect-error after method refactor error will disappear
                ? OffersActions.reactionUpdate(dispatch, offer.advertisementId, offer.location, 'rent', {
                    ...offer.reaction,
                    openedStatus: true,
                    viewedStatus: true
                }, offer.module)
                : null;
            OffersActions.loadOfferDetailsAsync(dispatch, offer.module, offer.advertisementId);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: RentOfferResponseContent) => {
        const userReaction = Object.assign({}, offer.reaction);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location;

        // @ts-expect-error after method refactor error will disappear
        OffersActions.reactionUpdate(dispatch, offer.advertisementId, city, 'rent', userReaction, offer.module as RentModuleType);
    };

    const handleDeleteFilterValue = (filterName: string, value?: string | number | null | string[], index?: number) => {
        const newState = { ...rentFiltersState };

        if (index !== undefined && Array.isArray(newState[filterName as keyof RentFiltersStateType])) {
            const array = newState[filterName as keyof RentFiltersStateType] as (string | number | null)[];
            array.splice(index, 1);
            (newState[filterName as keyof RentFiltersStateType] as (string | number | null)[]) = array;
        } else if (
            typeof newState[filterName as keyof RentFiltersStateType] === 'object' &&
            newState[filterName as keyof RentFiltersStateType] !== null &&
            'from' in (newState[filterName as keyof RentFiltersStateType] as object) &&
            'to' in (newState[filterName as keyof RentFiltersStateType] as object)
        ) {
            const rangeFilter = newState[filterName as keyof RentFiltersStateType] as MultipleFilterType;

            if (value === rangeFilter.from) {
                rangeFilter.from = null;
            } else if (value === rangeFilter.to) {
                rangeFilter.to = null;
            }
        } else {
            (newState[filterName as keyof RentFiltersStateType] as string | number | null | undefined) = null;
        }
        handleChange(filterName, newState[filterName as keyof RentFiltersStateType] as ObjectAndSingleValueChangeType);
    };

    const generateInteractiveElements = (offer: RentOfferResponseContent): InteractiveElements[] => {
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'rent');
        const isMoreButtonActive = moreButtonActiveId === offer.advertisementId.toString();
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return [
            {
                tooltipText: 'Wyświetlono',
                icon: IconEnum.EYE,
                active: offer.reaction.viewedStatus,
                disabled: offer.reaction.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reaction.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reaction.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reaction.likeStatus, offer)
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reaction.unlikeStatus,
                disabled: offer.reaction.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reaction.unlikeStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes || 1 : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: () => onMessageClick(offer)
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reaction.hideStatus,
                disabled: offer.reaction.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reaction.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reaction.phoneStatus,
                disabled: offer.reaction.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reaction.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reaction.arrangedStatus,
                disabled: offer.reaction.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reaction.arrangedStatus, offer)
            },
            ...offer.phoneNumber
                ? [
                    {
                        icon: IconEnum.PHONE,
                        isLink: true,
                        active: false,
                        isPhoneBtn: false,
                        text: <a href={`tel:${offer.phoneNumber}`}>{offer.phoneNumber}</a>
                    }
                ]
                : [],
            ...offer.photos && offer.photos.length > 0
                ? [
                    {
                        icon: IconEnum.PHOTOS,
                        isModalOpen: true,
                        isLink: true,
                        text: <p>Zobacz zdjęcia</p>,
                        isPhotoBtn: true,
                        visibleDuringModal: true,
                        sliderElements: {
                            photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                            title: offer.title
                        }
                    }
                ]
                : [],
            {
                icon: 'squares' as IconEnum,
                tooltipText: isTouchDevice() ? undefined : 'Więcej',
                onClick: () => isMoreButtonActive ? setMoreButtonActiveId(null) : setMoreButtonActiveId(offer.advertisementId.toString()),
                additionalJSXSibling: isMoreButtonActive ? <OfferActions onClickOutside={() => setMoreButtonActiveId(null)} elements={offerActionsElements} /> : undefined
            }
        ];
    };

    const generateSquareIcon = (offerId: string, isDetail: boolean, offer: RentOfferResponseContent) => {
        const buttonId = isDetail ? `${offerId}-detail` : offerId;
        const isMoreButtonActive = isDetail ? detailsMoreButtonActiveId === buttonId : moreButtonActiveId === buttonId;
        const offerLink = generateSingleOfferLink(offer.encryptedId, 'rent');
        const offerActionsElements = generateOfferActionsElements(offerLink, offer.link);

        return {
            icon: 'squares' as IconEnum,
            tooltipText: isTouchDevice() ? undefined : 'Więcej',
            onClick: () => {
                if (isDetail) {
                    isMoreButtonActive
                        ? setDetailsMoreButtonActiveId(null)
                        : setDetailsMoreButtonActiveId(buttonId);
                } else {
                    isMoreButtonActive
                        ? setMoreButtonActiveId(null)
                        : setMoreButtonActiveId(buttonId);
                }
            },
            additionalJSXSibling: isMoreButtonActive
                ? <OfferActions
                    onClickOutside={() => isDetail ? setDetailsMoreButtonActiveId(null) : setMoreButtonActiveId(null)}
                    isInModal={isDetail}
                    elements={offerActionsElements}
                />
                : undefined
        };
    };

    const handleDuplicates = async (offer: RentOfferResponseContent) => {
        // @ts-expect-error after method refactor error will disappear
        await OffersActions.loadDuplicates(dispatch, offer.module as RentModuleType, offer.advertisementId);
    };

    const handleNotes = async (offer: RentOfferResponseContent) => {
        OffersActions.loadOldNotesAsync(dispatch, 'rent', offer.advertisementId.toString(), offer.location, offer.module);
    };

    const handleOpinions = async (offer: RentOfferResponseContent) => {
        OffersActions.loadOpinionsAsync(dispatch, offer.advertisementId, offer.module);
    };

    const generateCardData = (offer: RentOfferResponseContent) => {
        const subMainValues: React.ReactNode[] = [];

        if (offer.pricePerSquareMeter) {
            subMainValues.push(
                <><b>{offer.pricePerSquareMeter.toString()}</b> zł / m<sup>2</sup></>
            );
        }

        if (offer.depositPrice) {
            subMainValues.push(
                <><b>Kaucja: </b>{offer.depositPrice} zł</>
            );
        }

        return {
            preTitle: offer.location,
            lowestPriceLabel: offer.duplicateWithLowestPrice,
            privateOfferLabel: offer.duplicateWithPrivateOffer,
            oldPrice: offer.priceBeforeModification?.toString(),
            date: offer.dateAdded,
            title: offer.title,
            infoElements: saleAndRentOfferListFunctions.generateInfoElements(offer),
            interactiveElements: generateInteractiveElements(offer),
            modifications: {
                duplicationsNumber: offer.numberOfDuplicates ?? 0,
                modificationsNumber: offer.numberOfModifications ?? 0,
                boostsNumber: offer.numberOfRaises ?? 0
            },
            note: offer.note,
            modificationDate: offer.lastUpdated,
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            ratingScore: offer.score ?? 0,
            scorePrecision: offer.scorePrecision ?? 0,
            additionalInfoBadge: offer.offerFrom,
            images: createImagesForSlider(offer.photos, offer.module),
            mainValue: offer.price ? `${formatFilterPrice(offer.price)} zł` : '- zł',
            subMainValues,
            link: {
                url: offer.link,
                name: offer.portal
            },
            ratingContainer: {
                opinionsNumber: offer.numberOfCommunityOpinions,
                opinionsRating: offer.communityScore,
                commentsCount: offer.numberOfCommunityComments,
                title: offer.title,
                isMyOpinion: !!offer.myOpinion,
                userRatingValue: offer.myOpinion?.numberOfStars,
                userRatingComment: offer.myOpinion?.content || undefined,
                handleGetComments: () => handleNotes(offer),
                handleSendRating: (rating: number, description: string) => offer.myOpinion ? OffersActions.updateOpinion(dispatch, offer.module, description, rating, offer.advertisementId, offer.myOpinion?.id) : OffersActions.updateOpinion(dispatch, offer.module, description, rating, offer.advertisementId)
            }
        };
    };

    useEffect(() => {
        const offersList = offers.rentList?.content.map((offer): RenderList => {
            const interactiveElements = generateInteractiveElements(offer);
            interactiveElements.splice(-1);
            const detailsTabContent = saleAndRentOfferListFunctions.generateOfferDetailsTabContent(offer);

            const duplicatesTabContent: V2DuplicatesTabProps = {
                duplicates: offer.duplicates?.map((duplicate) => {
                    return {
                        id: duplicate.advertisementId,
                        bigImage: true,
                        cardData: generateCardData(duplicate),
                        onClick: () => {},
                        openOfferTab: () => {}
                    };
                })
            };

            return {
                id: offer.advertisementId,
                onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                baseCardContent: {
                    bigImage: true,
                    cardData: {
                        ...generateCardData(offer),
                        interactiveElements: [
                            ...interactiveElements,
                            generateSquareIcon(offer.advertisementId.toString(), false, offer)
                        ]
                    },
                    openOfferTab: () => {} // openoffertab
                },
                detailsCardData: {
                    interactiveElements: [
                        ...interactiveElements,
                        generateSquareIcon(offer.advertisementId.toString(), true, offer)
                    ],
                    modificationDate: offer.lastUpdated,
                    additionalInfoBadge: offer.offerFrom,
                    thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                    date: offer.dateAdded,
                    onToggleContainerDetails: () => {},
                    tabsContent: {
                        toggleContainerDetails: () => {},
                        interactiveElements: [
                            ...interactiveElements,
                            generateSquareIcon(offer.advertisementId.toString(), true, offer)
                        ],
                        detailsTabContent,
                        duplicatesTabContent,
                        modificationTabContent: {
                            modifications: offer.detailedContent?.modifications || []
                        },
                        notesTabContent: {
                            notes: offers.notes,
                            // @ts-expect-error after method refactor error will disappear
                            handleDelete: (noteId: number) => handleDelete(noteId, offer.advertisementId, offer.module),
                            // @ts-expect-error after method refactor error will disappear
                            handleSaveNote: (value: string, id?: number) => handleSaveNote(value, offer.advertisementId, offer.location, offer.module, id)
                        },
                        opinionTabContent: {
                            handleSaveOpinion: (rating: number, content: string, id?: number) => OffersActions.updateOpinion(dispatch, offer.module, content, rating, offer.advertisementId, id || offer.myOpinion?.id),
                            comments: offers.scoreAndOpinions?.opinions?.map((opinion) => ({
                                id: opinion.opinionId,
                                date: opinion.dateAdded,
                                content: opinion.content || '',
                                rating: opinion.numberOfStars,
                                isUserComment: opinion.loggedUserOpinion
                            })),
                            applicationScore: offer.score,
                            applicationScorePrecision: offer.scorePrecision,
                            commentsCount: offer.numberOfCommunityComments,
                            opinionsNumber: offer.numberOfCommunityOpinions,
                            opinionsRating: offers.scoreAndOpinions?.communityScore || 0,
                            handleDeleteOpinion: (id: number) => OffersActions.deleteOpinion(dispatch, offer.advertisementId, offer.module, id)
                        },
                        numberOfModifications: offer.numberOfModifications || 0,
                        numberOfDuplicates: offer.numberOfDuplicates || 0,
                        numberOfNotes: offer.numberOfNotes || 0,
                        handleLoadDuplicates: () => handleDuplicates(offer),
                        handleLoadNotes: () => handleNotes(offer),
                        handleLoadComments: () => handleOpinions(offer)
                    }
                }
            };
        });

        setRenderList(offersList);
    }, [
        offers.rentList?.content,
        offers.notes,
        offers.scoreAndOpinions,
        moreButtonActiveId,
        detailsMoreButtonActiveId
    ]);

    return (
        <div>
            <V2OfferList totalPages={offers.rentList?.totalPages} handleChangePage={handleChangePage}
                pageNumber={offers.rentList?.pageable?.pageNumber || 1}
                lastPage={offers.rentList?.last}
                handleOlderAdverts={handleOlderAdverts}
                handleActiveFiltersChange={handleActiveFiltersChange}
                showList={!offers.rentList?.empty && offers.rentList?.content?.length > 0}
                activeFiltersState={rentFiltersState}
                filters={getRentFiltersFromState(rentFiltersState)}
                showNoItemsText={offers.rentList && offers.rentList.empty && offers.rentList.content.length === 0 && offers.rentList.afterSearch}
                renderList={renderList}
                onDeleteFilter={handleDeleteFilterValue}
            />
        </div>
    );
};

export default RentOffers;

