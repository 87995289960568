import React from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const PortalsListCard = styled.div`
    padding: 25px 15px;
    background-color: var(--color-white);
    border-radius: 10px;

    & > span {
        display: flex;
        align-items: center;

        p {
            --font-size-body: var(--font-size-body-2);
            line-height: 30px;
        }

        span {
            margin-right: 8px;
        }
    }
`;

const PortalsListCardWrapper = styled.div`
    padding: 0 20px;
    grid-column: 2;
    margin-bottom: 20px;
`;

const Portals = styled.ul`
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    li {
        --font-size-body: var(--font-size-body-3);
    }

    li::after {
        content: "\\2022";
        margin: 0 5px;
    }
`;

export const generatePortalsListInfoBox = (portalList: string[]) => {
    return (
        <PortalsListCardWrapper>
            <PortalsListCard>
                <span><Icon icon={IconEnum.QUESTION}/><p>Monitorowane strony w tym mieście:</p></span>
                <Portals>
                    {portalList.map((el) => <li key={el}>{el}</li>)}
                </Portals>
            </PortalsListCard>
        </PortalsListCardWrapper>
    );
};
