import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import styled from 'styled-components';
import AnalysisSaleContext from 'utils/context/AnalysisSaleContext';

import AnalysisSaleList, { AnalysisElements } from 'components/analysis/AnalysisSaleList';
import FiltersAnalysisSale from 'components/analysis/FiltersAnalysisSale';
import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import { PdfFooter, PdfHeader, PdfWrapper } from 'components/common/PdfRenderer/StaticElements';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const PageContainer = styled.div`
    height: 100%;
`;

const AnalysisPage = () => {
    const { analysisSaleState, analysisSaleFiltersState } = useContext(AnalysisSaleContext);
    const [data, setData] = useState<AnalysisElements>({ areaAnalysisList: [], timeAnalysisList: [], trendResult: [] });
    const [isPrintMode, setIsPrintMode] = useState(false);
    const { chartData, afterSearch } = analysisSaleState;
    const analysisPrepared = data.areaAnalysisList && data.areaAnalysisList.length > 0 ||
        data.timeAnalysisList && data.timeAnalysisList.length > 0 ||
        data.trendResult && data.trendResult.length > 0;

    const pdfRef = useRef<HTMLDivElement>(null);
    const pdfHeaderRef = useRef<HTMLDivElement>(null);
    const pdfFooterRef = useRef<HTMLDivElement>(null);

    const handleDownloadPdf = async () => {
        setIsPrintMode(true);

        setTimeout(async () => {
            const maxHeight = 1300;
            let spaceLeft = 1300;
            const pdfSection = document.getElementById('pdfSection');
            const childrens = Array.from(pdfSection!.children);

            childrens.forEach((child, index) => {
                spaceLeft = spaceLeft - (child as HTMLElement).offsetHeight;

                if (spaceLeft <= 0) {
                    (childrens[index - 1] as HTMLElement).style.marginBottom = `${spaceLeft + (child as HTMLElement).offsetHeight}px`;
                    spaceLeft = maxHeight - (child as HTMLElement).offsetHeight;
                }
            });

            const pageHeight = 780;
            const pdfElement = pdfRef.current;
            const headerElement = pdfHeaderRef.current;
            const footerElement = pdfFooterRef.current;

            const canvas = await html2canvas(pdfElement!, {
                allowTaint: true,
                useCORS: true,
                scale: 1.5
            });
            const headerCanvas = await html2canvas(headerElement!, {
                allowTaint: true,
                useCORS: true,
                scale: 3.5
            });
            const footerCanvas = await html2canvas(footerElement!, {
                allowTaint: true,
                useCORS: true,
                scale: 3.5
            });

            const dataImage = canvas.toDataURL('image/webp');
            const dataHeader = headerCanvas.toDataURL('image/webp');
            const dataFooter = footerCanvas.toDataURL('image/webp');
            const imgWidth = 595;
            const imgHeight = canvas.height * imgWidth / canvas.width;
            const headerHeight = headerCanvas.height * imgWidth / headerCanvas.width;
            const footerHeight = footerCanvas.height * imgWidth / footerCanvas.width;
            let heightLeft = imgHeight;
            const pdf = new jsPDF('p', 'pt', 'a4');
            let position = 45;
            let page = 1;

            pdf.setFont('Poppins', 'sans-serif', 700);
            pdf.setFontSize(12);
            pdf.setTextColor(3, 13, 69);

            pdf.addImage(dataHeader, 'webp', 0, 0, imgWidth, headerHeight);
            pdf.addImage(dataImage, 'webp', 0, position, imgWidth, imgHeight);
            pdf.addImage(dataFooter, 'webp', 0, 806, imgWidth, footerHeight);
            pdf.text(`0${page}`, 563, 827);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight + 43;
                pdf.addPage();
                page++;
                pdf.addImage(dataImage, 'webp', 0, position, imgWidth, imgHeight);
                pdf.addImage(dataHeader, 'webp', 0, 0, imgWidth, headerHeight);
                pdf.addImage(dataFooter, 'webp', 0, 806, imgWidth, footerHeight);
                pdf.text(`0${page}`, 563, 827);
                heightLeft -= pageHeight;
            }
            pdf.save(`investoro_pl_${dayjs(new Date()).format('DD_MM_YYYY')}.pdf`);
            setIsPrintMode(false);
        }, 2000);
    };

    useEffect(() => {
        if (chartData && chartData.length > 0) {
            const [{ analysisAreaRows: areaAnalysisList, analysisTimeRows: timeAnalysisList, trendResult }] = chartData || {};

            setData({ areaAnalysisList, timeAnalysisList, trendResult });
        }
    }, [chartData]);

    return (
        <PageContainer>
            {isPrintMode &&
                <>
                    <Indicator />
                    <PdfWrapper>
                        <PdfHeader ref={pdfHeaderRef} />
                        <PdfFooter ref={pdfFooterRef} />
                    </PdfWrapper>
                </>
            }
            <V2HeaderSection
                title={'Analiza cen ofertowych'}
                handlePDFClick={analysisPrepared || afterSearch ? handleDownloadPdf : undefined}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: analysisPrepared || afterSearch })}>
                <Container id="filters">
                    <FiltersAnalysisSale />
                </Container>
                <AnalysisSaleList ref={pdfRef} showList={!!analysisPrepared} analysis={data} isPrintMode={isPrintMode}
                    showNoItemsText={!analysisPrepared && afterSearch} timeRange={analysisSaleFiltersState.timeRange}/>
                {analysisSaleState.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </PageContainer>
    );
};

export default AnalysisPage;
