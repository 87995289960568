import React, { createContext, useCallback, useEffect, useState } from 'react';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { filtersApiRequests } from 'utils/api-requests/filters';
import { createUniqueFilterName } from 'utils/createUniqueFilterName';
import { generateUniqueId } from 'utils/formatters/generateUniqueId';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { getValueFromState, initialState, setStateValues } from 'utils/state-managment/rent/rentFilter';
import { SavedFilter } from 'utils/types/Filter';
import { MultipleFilterType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import { RentFiltersStateType, RentModuleType, RentOfferRequestData } from 'utils/types/Rent';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

import { activeNotification } from 'components/functions/activeNotification';

interface RentContextType {
    handleChangePropertyType: (value: RentModuleType) => void,
    propertyType: RentModuleType,
    rentFiltersState: RentFiltersStateType
    activeFiltersState: RentFiltersStateType
    handleDeleteFilter: (id: number) => void,
    handleSetFiltersValues: (id: number) => void
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void
    validateField: (name: AdvertValidatorType, value: ObjectAndSingleValueChangeType) => boolean
    clearFilters: (reset?: boolean) => void
    validateAllFields: () => boolean
    getSavedFilters: (id?: number) => void
    handleChangeDateList: (fieldName: string, value: number) => void
    handleActiveFilterChange: (fieldName: string, value: string | number | boolean | MultipleFilterType) => void,
    handleSaveOrUpdateFilter: () => void,
    handleCreateFromExistFilter: () => void,
    handleChangeNotificationAlert: (valueName: string, hashedId: number) => void,
}

const values: RentContextType = {
    handleChangePropertyType: () => {},
    handleDeleteFilter: () => {},
    handleSetFiltersValues: () => {},
    handleChange: () => {},
    handleChangeDateList: () => {},
    clearFilters: () => {},
    validateField: () => false,
    validateAllFields: () => false,
    propertyType: RentModuleType.RENT_FLAT,
    rentFiltersState: {} as RentFiltersStateType,
    activeFiltersState: {} as RentFiltersStateType,
    getSavedFilters: () => {},
    handleActiveFilterChange: () => {},
    handleSaveOrUpdateFilter: () => {},
    handleCreateFromExistFilter: () => {},
    handleChangeNotificationAlert: () => {}
};

const RentContext = createContext(values);

type MappedIdType = Record<string, { id: number, module: RentModuleType}>;

export const RentProvider = ({ children }: { children: React.ReactNode }) => {
    const { dispatch: dispatchUser } = useUser();
    const { dispatch: dispatchOffers } = useOffers();
    const [propertyType, setPropertyType] = useState<RentModuleType>(RentModuleType.RENT_FLAT);
    const [rentFiltersState, setRentFiltersState] = useState(initialState.filterInitialState());
    const [activeFiltersState, setActiveFiltersState] = useState(initialState.filterInitialState());
    const [idMap, setIdMap] = useState<MappedIdType>({});

    const handleSetFiltersValues = async (id: number, idMapParam?: MappedIdType) => {
        const currentIdMap = idMapParam || idMap;
        const filterId = currentIdMap[id].id;
        const filterModuleType = currentIdMap[id].module;
        const filterData = await filtersApiRequests.getFilterDetails(filterId, 'rent', filterModuleType ? filterModuleType : propertyType) as RentOfferRequestData;

        if (filterData) {
            setRentFiltersState((filters: RentFiltersStateType) => ({
                ...setStateValues({ ...filterData, id, locations: rentFiltersState.locations }),
                savedFilter: filters.savedFilter
            }));
        } else {
            setRentFiltersState((filters: RentFiltersStateType) => ({
                ...filters,
                name: null,
                id: null
            }));
        }
    };

    const getSavedFilters = useCallback(async (id?: number) => {
        const savedFilters = await filtersApiRequests.getSavedListFilters('rent') as SavedFilter[];
        const newIdMap: MappedIdType = {};
        const savedFiltersHashedId = savedFilters.map((x) => {
            const hashedId = generateUniqueId(`${x.id}:${x.module}`);
            newIdMap[hashedId] = { id: x.id, module: RentModuleType[x.module] };

            return { ...x, id: hashedId };
        });

        setIdMap((prev) => ({
            ...prev,
            ...newIdMap
        }));

        setRentFiltersState((filters: RentFiltersStateType) => ({
            ...filters,
            savedFilter: savedFiltersHashedId as SavedFilter[]
        }));

        if (id) {
            const hashedId = Object.keys(newIdMap).find((x) => newIdMap[x].id === id);

            await handleSetFiltersValues(Number(hashedId), newIdMap);
        }
    }, [propertyType]);

    const handleChangeNotificationAlert = async (valueName: string, hashedId: number) => {
        const currentState = { ...rentFiltersState };
        const newValue = !currentState[valueName as keyof RentFiltersStateType];
        const realFilterId = idMap[hashedId].id;

        const updatedState = {
            ...currentState,
            [valueName]: newValue
        };

        if (valueName === 'alertSms' && newValue === true) {
            updatedState.tossedUp = false;
            handleChange('tossedUp', false);
        }

        handleChange(valueName, newValue);

        await filtersApiRequests.updateFilter(getValueFromState({ ...updatedState, id: realFilterId }), 'rent');
        getSavedFilters(realFilterId);
    };

    const handleSaveOrUpdateFilter = async () => {
        if (rentFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const existFilter = rentFiltersState.savedFilter?.find(
            (filter) => filter.id === rentFiltersState.id
        );

        const existName = rentFiltersState.savedFilter?.find(
            (filter) => filter.name === rentFiltersState.name
        );

        let id;

        if (existName && !rentFiltersState.id) {
            activeNotification('Nazwa filtra', 'Istnieje już filtr o takiej nazwie', 'warning');

            return;
        }

        if (existFilter) {
            const id = idMap[existFilter.id].id;

            await filtersApiRequests.updateFilter({
                ...getValueFromState({ ...rentFiltersState, id })
            }, 'rent');
        } else {
            const resp = await filtersApiRequests.addFilter({
                ...getValueFromState(rentFiltersState)
            }, 'rent', rentFiltersState.module);

            id = resp?.data;
        }

        getSavedFilters(id);
    };

    const handleCreateFromExistFilter = async () => {
        if (rentFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        const newName = createUniqueFilterName(rentFiltersState.name!, rentFiltersState.savedFilter as SavedFilter[]);

        const newFilterData = {
            ...getValueFromState(rentFiltersState),
            name: newName
        };

        const resp = await filtersApiRequests.addFilter(newFilterData, 'rent', rentFiltersState.module);

        const id = resp?.data;

        getSavedFilters(id);
    };

    const clearFilters = useCallback((reset?: boolean) => {
        const initialFilters = initialState.filterInitialState();

        if (reset) {
            setRentFiltersState((prev) => ({
                ...initialFilters,
                module: propertyType,
                locations: prev.locations
            }));
        } else {
            setRentFiltersState((prev) => ({
                ...prev,
                module: propertyType,
                locations: prev.locations,
                city: reset ? [] : prev.city,
                savedLocations: reset ? {} : prev.savedLocations,
                floor: initialFilters.floor,
                floors: initialFilters.floors,
                builtYear: initialFilters.builtYear,
                buildingType: initialFilters.buildingType,
                numberOfRooms: initialFilters.numberOfRooms
            }));
        }
        getSavedFilters();
        UserActions.changeErrorVisibility(dispatchUser, false);
    }, [
        dispatchUser,
        getSavedFilters,
        propertyType
    ]);

    const handleChangePropertyType = useCallback((value: RentModuleType) => {
        setPropertyType(value);
        clearFilters();
        setRentFiltersState((prev) => ({
            ...prev,
            id: null
        }));
    }, [clearFilters]);

    const handleDeleteFilter = useCallback(async (filterId: number) => {
        const realFilterId = idMap[filterId].id;
        const filterModuleType = idMap[filterId].module;
        await filtersApiRequests.removeSavedFilter(realFilterId, 'rent', filterModuleType ? filterModuleType : propertyType);
        delete idMap[filterId];

        setRentFiltersState(initialState.filterInitialState());
        getSavedFilters();
    }, [
        rentFiltersState.id,
        rentFiltersState.savedFilter,
        getSavedFilters
    ]);

    const handleChange = useCallback((fieldName: string, value: ObjectAndSingleValueChangeType) => {
        setRentFiltersState((filters: RentFiltersStateType) => {
            const updatedFilters = {
                ...filters,
                [fieldName]: value
            };

            if (fieldName === 'alertSms' && value === true) {
                updatedFilters.tossedUp = false;
            }

            if (fieldName === 'lowerOriginalPrice') {
                OffersActions.loadOffersAsync(
                    dispatchOffers, // Use dispatchOffers from the context
                    'rent',
                    updatedFilters.city,
                    { ...getValueFromState(updatedFilters) },
                    getNormalizedPropertyType(updatedFilters.module),
                    1,
                    25
                );
            }

            return updatedFilters;
        });
    }, [rentFiltersState]);

    const handleActiveFilterChange = useCallback((fieldName: string, value: string | number | boolean | MultipleFilterType) => {
        setActiveFiltersState((filters: RentFiltersStateType) => ({
            ...filters,
            [fieldName]: value
        }));
    }, []);

    // TODO: Do ogarniecia pod typ rent
    // const baseRequiredFields = ['cityName', 'title', 'area', 'price', 'typeOfMarket', 'propertyType', 'description'];
    // const flatRequiredFields = ['propertyForm'];
    // const houseRequiredFields = ['rentPrice', 'depositPrice', 'propertyForm'];
    // const plotRequiredFields = ['waivePrice'];
    // const otherRequiredFields = ['otherAdType'];
    // const validateAllFields = () => {
    //     switch (propertyType) {
    //     case PropertyType.FLAT:
    //         return [...baseRequiredFields, ...flatRequiredFields].every((item) => isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, newAdvertFields[item as keyof NewAdvertStateType] as string));
    //     case PropertyType.HOUSE:
    //         return [...baseRequiredFields, ...houseRequiredFields].every((item) => isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, newAdvertFields[item as keyof NewAdvertStateType] as string));
    //     case PropertyType.PLOT:
    //         return [...baseRequiredFields, ...plotRequiredFields].every((item) => isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, newAdvertFields[item as keyof NewAdvertStateType] as string));
    //     case PropertyType.OTHER:
    //         return [...baseRequiredFields, ...otherRequiredFields].every((item) => isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, newAdvertFields[item as keyof NewAdvertStateType] as string));
    //     default:
    //         return false;
    //     }
    // };

    // TODO: Kandydat jako funkcja wspólna dla wszystkich kontekstów
    // src/components/functions/locations.ts
    const getVoivodeships = useCallback(async () => {
        const locations = await filtersApiRequests.getVoivodeships();
        setRentFiltersState((filters: RentFiltersStateType) => ({
            ...filters,
            locations
        }));
    }, []);

    const handleChangeDateList = (fieldName: string, value: number) => {
        setRentFiltersState((filters: RentFiltersStateType) => ({
            ...filters,
            offerAdded: {
                from: value === -1 ? null : new Date(new Date().setDate(new Date().getDate() - value)).toISOString().split('T')[0],
                to: new Date().toISOString().split('T')[0]
            },
            [fieldName]: value
        }));
    };

    useEffect(() => {
        clearFilters();
    }, [propertyType]);

    useEffect(() => {
        OffersActions.clearOffers(dispatchOffers);
    }, [dispatchOffers]);

    useEffect(() => {
        if (rentFiltersState.locations.length === 0) {
            getVoivodeships();
        }
    }, [rentFiltersState.locations]);

    const providerValues = {
        handleChangePropertyType,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChange,
        clearFilters,
        validateField: isFieldValid,
        validateAllFields: () => false,
        propertyType: RentModuleType.RENT_FLAT,
        rentFiltersState,
        getSavedFilters,
        handleChangeDateList,
        handleActiveFilterChange,
        activeFiltersState,
        handleSaveOrUpdateFilter,
        handleCreateFromExistFilter,
        handleChangeNotificationAlert
    };

    return (
        <RentContext.Provider value={providerValues}>
            {children}
        </RentContext.Provider>
    );
};

export default RentContext;
