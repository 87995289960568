import React from 'react';
import { CooperativeOfferResponseContent } from 'utils/state-managment/cooperative/cooperativeOffer';

import { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import { IconEnum } from 'components/common/Icon';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';

type Offer = Omit<CooperativeOfferResponseContent, 'detailedContent' | 'encryptedId'> & {
    detailedContent?: {
        description?: string;
        meta?: {
            value?: string;
            key: string;
        }[]
    },
};

const generateOfferDetailsTabContent = (offer: Offer) => {
    const detailsElements = [
        {
            icon: IconEnum.CALENDAR,
            text: 'Data dodania:',
            value: offer.dateAdded
        },
        ...offer.portal
            ? [
                {
                    icon: IconEnum.LINK,
                    text: 'Portal:',
                    value: offer.portal
                }
            ]
            : []
    ];

    const offerDetailsTabContent: V2DetailsTabsProps = {
        title: offer.title,
        address: offer?.location,
        detailsElements,
        description: offer.detailedContent?.description || '-',
        meta: offer.detailedContent?.meta
    };

    return offerDetailsTabContent;
};

const generateInfoElements = (offer: CooperativeOfferResponseContent): InfoElementType[] => {
    return [

        ...offer.portal
            ? [
                {
                    icon: IconEnum.LINK,
                    isVisible: !!offer.portal,
                    children: <><b>{offer.portal}</b></>
                }
            ]
            : []
    ];
};

export const cooperativeOfferListFunctions = {
    generateOfferDetailsTabContent,
    generateInfoElements
};
