import React, { FC } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';
import { AuctionSearchingRequestSortTypeEnum } from 'utils/types/AuctionModels';
import { BailiffNoticeSearchingRequestSortTypeEnum } from 'utils/types/BailiffModels';
import { CooperativeSearchingRequestSortTypeEnum } from 'utils/types/CooperativeModels';
import { SortType } from 'utils/types/SaleModels';
import { TenderSearchingRequestSortTypeEnum } from 'utils/types/TenderModels';

import AutocompleteInput from './common/Inputs/AutocompleteInput/AutocompleteInput';

export const OffersHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 0 0 24px;
    border-bottom: 1px solid #030D451A;
    flex-direction: row;

    @media (max-width: 1100px) {
        padding: 0 0 10px;
    }

    &.favorites-page {
        margin-right: 45px;
    }

    & > div {
        align-items: flex-start;
        display: flex;
        gap: 40px;

        &:last-child {
            align-self: flex-end;
        }

        a {
            display: block;

            @media (max-width: 1100px) {
                display: block;
                position: absolute;
                left: 40px;
                top: 20px;
                white-space: nowrap;
            }
        }
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;

const InputWrapper = styled.div`
    max-width: 275px;
    position: relative;

    &:hover {
        cursor: pointer;
    }

    div {
        line-height: 20px;
        column-gap: 0;
    }

    input {
        --font-weight: 600;
        font-size: var(--font-size-body-1);
    }
`;

const CheckBoxWrapper = styled.label`
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
`;

const Checkbox = styled.input`
    pointer-events: none;
`;

export const Title = styled.span`
    height: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0 0 15px;
    font-weight: var(--font-weight);
    font-size: 20px;
    line-height: 30px;
    color: var(--color-primary);

    span {
        font-size: 20px;
        font-weight: 400;
        color: var(--color-primary);
    }
`;

const BaseClickableSpan = styled.span`
    margin-top: 20px;
    margin-left: 15px;
    font-weight: var(--font-weight);
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
    cursor: pointer;

    @media (max-width: 1100px) {
        margin-top: 0;

        &:first-child {
            display: none;
        }
    }

    b {
        font-weight: 600;
    }

    &:hover {
        color: var(--color-alt);
    }
`;

export const BaseClickableLink = styled.a`
    margin-top: 20px;
    font-weight: var(--font-weight);
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
    cursor: pointer;
    text-decoration-line: underline;

    &:hover {
        color: var(--color-alt);
    }
`;

export const enum HeaderTypes {
    FAVORITES = 'favorites',
}

interface V2HeaderSectionProperties {
    type?: HeaderTypes,
    title: string,
    offersCount?: number,
    handleCSVClick?: () => void,
    handlePDFClick?: () => void,
    sortType?: SortType | BailiffNoticeSearchingRequestSortTypeEnum | TenderSearchingRequestSortTypeEnum | AuctionSearchingRequestSortTypeEnum | CooperativeSearchingRequestSortTypeEnum,
    sortOptions?: {label: string, value: string}[],
    handleChangeSort?: (sort: string) => void,
    handleBackToFilters?: () => void,
    showLowerOriginalPriceOffers?: boolean,
    showLowerPriceCheckInput?: boolean,
    handleShowLowerOriginalPriceOffers?: (sortType: SortType) => void,
}

const V2HeaderSection: FC<V2HeaderSectionProperties> = ({
    type,
    handlePDFClick,
    title,
    offersCount,
    handleCSVClick,
    sortType,
    sortOptions,
    handleChangeSort,
    handleBackToFilters,
    showLowerOriginalPriceOffers,
    showLowerPriceCheckInput,
    handleShowLowerOriginalPriceOffers
}) => {
    return (
        <>
            <OffersHeader className={clsx({ 'favorites-page': type === HeaderTypes.FAVORITES })}>
                <Title>
                    {title}
                    {offersCount ? <span>({offersCount})</span> : null}
                </Title>
                <div>
                    {offersCount && offersCount > 0 && handleCSVClick
                        ? <BaseClickableSpan className={'exel'} onClick={handleCSVClick}>
                            Pobierz plik <b>EXCEL</b>
                        </BaseClickableSpan>
                        : null}
                    {offersCount && offersCount > 0 && showLowerPriceCheckInput && showLowerPriceCheckInput
                        ? <CheckBoxWrapper>
                            <div>Tylko ze zmienioną ceną</div>
                            <Checkbox
                                readOnly
                                checked={showLowerOriginalPriceOffers}
                                onChange={handleShowLowerOriginalPriceOffers}
                                type="checkbox"
                                autocomplete="off"/>
                        </CheckBoxWrapper>
                        : null
                    }
                    {sortType && sortOptions && sortOptions.length > 0 && handleChangeSort
                        ? <InputWrapper>
                            <AutocompleteInput
                                headerInput
                                oneLine
                                isInputMode={false}
                                value={sortType}
                                // @ts-expect-error TODO: INVEST-253
                                onChange={handleChangeSort}
                                placeholder="Sortuj po..."
                                prefix="Sortuj"
                                options={sortOptions}
                            />
                        </InputWrapper>
                        : null}
                    {handlePDFClick &&
                        <BaseClickableSpan onClick={handlePDFClick}>Pobierz plik <b>PDF</b></BaseClickableSpan>}
                    {handleBackToFilters
                        ? <BaseClickableLink onClick={handleBackToFilters} href={'#filters'}>Wróć do
                            wyszukiwania</BaseClickableLink>
                        : null}
                </div>
            </OffersHeader>
        </>
    );
};

export default V2HeaderSection;
