import React, { useCallback, useContext } from 'react';
import clsx from 'clsx';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import styled from 'styled-components';
import RentContext from 'utils/context/RentContext';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { sortOptions } from 'utils/options';
import { getValueFromState } from 'utils/state-managment/rent/rentFilter';
import { SortType } from 'utils/types/SortType';

import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import FiltersRent from 'components/rent/FiltersRent';
import RentOffers from 'components/rent/RentOffers';
import V2HeaderSection from 'components/V2HeaderSection';

const Container = styled.div`
    padding: 15px 20px 0;
`;

const RentPage = () => {
    const { offers, dispatch } = useOffers();
    const { handleChange, rentFiltersState } = useContext(RentContext);

    const handleChangeSort = (sortType: string) => {
        const updatedFilters = {
            ...rentFiltersState,
            sortType: sortType as SortType
        };

        handleChange('sortType', sortType as SortType);
        OffersActions.loadOffersAsync(
            dispatch,
            'rent',
            updatedFilters.city,
            { ...getValueFromState(updatedFilters) },
            getNormalizedPropertyType(updatedFilters.module),
            1,
            25
        );
    };

    const handleShowLowerOriginalPriceOffers = useCallback(() => {
        handleChange('lowerOriginalPrice', !rentFiltersState.lowerOriginalPrice);
    }, [handleChange, rentFiltersState.lowerOriginalPrice]);

    return (
        <div>
            <V2HeaderSection
                title={'Ogłoszenia wynajmu'}
                offersCount={offers.rentList?.totalElements}
                sortType={offers.rentList.content?.length ? rentFiltersState.sortType : undefined}
                sortOptions={sortOptions}
                handleChangeSort={handleChangeSort}
                handleBackToFilters={offers.rentList.content?.length > 0 || offers.rentList?.empty && offers.rentList?.afterSearch
                    ? () => OffersActions.clearOffers(dispatch)
                    : undefined}
                handleShowLowerOriginalPriceOffers={handleShowLowerOriginalPriceOffers}
                showLowerOriginalPriceOffers={rentFiltersState.lowerOriginalPrice}
                showLowerPriceCheckInput={true}
            />
            <FiltersContentWrapper className={clsx('offers-list', { active: offers.rentList?.content.length > 0 || offers.rentList?.empty && offers.rentList?.afterSearch })}>
                <Container id="filters">
                    <FiltersRent />
                </Container>
                <RentOffers />
                {offers.isLoading && <Indicator/>}
            </FiltersContentWrapper>
        </div>
    );
};

export default RentPage;
