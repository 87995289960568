import React, { FC } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';
import TrashWithModal from 'components/common/TrashWithModal';

const AutoCompleteSuggestions = styled.div`
    position: absolute;
  
    z-index: 1;
    top: 95px;
    background: var(--color-white);
    left: 0;
    width: 100%;
    min-width: fit-content;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 #0000000D;
    border: 1px solid var(--color-primary);

    &.left {
        width: 50%;
    }

    &.right {
        left: unset;
        right: 0;
        width: 50%;
    }

    &.oneLine {
        top: 50px;
    }

    &.goTop {
        box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.1);
        top: unset;
        bottom: 50px;
    }

    &.hidden {
        display: none;
    }
`;

interface SuggestionProps {
    darkMode?: boolean,
}

const Suggestion = styled.div<SuggestionProps>`
    width: 100%;
    font-weight: 500;
    font-size: 13px;
    line-height: 36px;
    color: var(--color-primary);
    background: var(--color-alt-second);
    border-radius: 10px;
    padding: 7px;
    text-align: center;
    cursor: pointer;
    border: 1px solid transparent;
    transition: border 0.1s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        margin-right: 12px;
    }

    &:hover, &:active, &:target, &.preferred {
        transition: border 0.1s ease-in-out;
        border: 1px solid var(--color-alt-hover);
    }

    &.smaller {
        line-height: 20px;
    }

    &.active {
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        color: var(--color-alt-second);

        img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }

    &.left {
        justify-content: left;
    }
`;

const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 6px;
    max-height: 330px;
`;

const AlertIcons = styled.div`
    margin-left: auto;
    display: flex;
`;

const SuggestionContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    color: inherit;
`;

interface LabelSectionProps {
    hasIcon: boolean,
}
const LabelSection = styled.div<LabelSectionProps>`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.hasIcon ? 'flex-start' : 'center'};
    color: inherit;
    text-align: left;
    white-space: normal;
    word-wrap: break-word; 
`;

const IconsSection = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    color: inherit;
`;

const TrashContainer = styled.div`
  margin-left: auto;
`;

interface AlertType {
    alertSms: boolean,
    alertEmail: boolean,
    pushAlert: boolean,
}

interface AutocompleteSuggestionsProperties {
    options: { label: string, value: string | number, alerts?: AlertType, trash?: boolean }[],
    showSuggestions: boolean,
    oneLine?: boolean,
    goTop?: boolean,
    side?: 'left' | 'right',
    autocompleteSuggestionsRef: React.RefObject<HTMLDivElement> | null,
    value?: null | string | number,
    enableCustomValue?: boolean,
    noTyping?: boolean,
    numeric?: boolean,
    preferredOptionIndex: number | null,
    optionsIcon?: IconEnum,
    handleSelectSuggestion: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string | number) => void,
    handleDeleteFilter?: (filterId: string | number) => void,
    suggestionToLeft?: boolean,
}

const AutocompleteSuggestions: FC<AutocompleteSuggestionsProperties> = ({
    options,
    showSuggestions,
    oneLine,
    goTop,
    side,
    autocompleteSuggestionsRef,
    value,
    enableCustomValue,
    numeric,
    preferredOptionIndex,
    optionsIcon,
    handleSelectSuggestion,
    suggestionToLeft,
    handleDeleteFilter
}) => {
    const { user } = useUser();
    const loweredValue = value !== null && value !== undefined ? String(value)?.toLowerCase() : '';
    const filteredOptions = loweredValue.length > 0 && enableCustomValue && !numeric ? options.filter((option) => option.label.toLowerCase().includes(loweredValue)) : options;

    return (
        <AutoCompleteSuggestions className={clsx({
            hidden: !(showSuggestions && filteredOptions.length > 0),
            oneLine: oneLine,
            goTop: goTop,
            left: side === 'left',
            right: side === 'right'
        })} ref={autocompleteSuggestionsRef}>
            <OptionsWrapper>
                {filteredOptions
                    .map((x) => {
                        const isAlert = x.alerts && (x.alerts.alertEmail || x.alerts.alertSms || x.alerts.pushAlert);

                        return (
                            <Suggestion
                                darkMode={user.isDarkMode}
                                key={x.value}
                                onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleSelectSuggestion(e, x.value)}
                                className={clsx({
                                    active: String(x.value).toLowerCase() === String(value).toLowerCase(),
                                    preferred: preferredOptionIndex === filteredOptions.indexOf(x),
                                    left: optionsIcon === 'bookmark' || suggestionToLeft,
                                    smaller: x.label.length >= (isAlert ? 23 : 29)
                                })}
                            >
                                <SuggestionContent>
                                    <LabelSection hasIcon={optionsIcon}>
                                        {optionsIcon && <Icon icon={optionsIcon}/>}
                                        {x.label}
                                    </LabelSection>

                                    <IconsSection>
                                        {x.alerts
                                            ? <AlertIcons>
                                                {x.alerts.alertSms ? <Icon icon={IconEnum.MESSAGE}/> : null}
                                                {x.alerts.pushAlert ? <Icon icon={IconEnum.AUDIO}/> : null}
                                                {x.alerts.alertEmail ? <Icon icon={IconEnum.EMAIL}/> : null}
                                            </AlertIcons>
                                            : null}
                                        {x.trash && handleDeleteFilter &&
                                    <TrashContainer>
                                        <TrashWithModal
                                            title={'Czy napewno chcesz usunąć filtr?'}
                                            description={'Pamiętaj, że nie będziesz już otrzymywać powiadomień związanych z tym filtrem.'}
                                            onChange={() => handleDeleteFilter(x.value)}
                                            filter={x.label}
                                        />

                                    </TrashContainer>}
                                    </IconsSection>
                                </SuggestionContent>
                            </Suggestion>
                        );
                    })}
            </OptionsWrapper>
        </AutoCompleteSuggestions>
    );
};

export default AutocompleteSuggestions;
